import React from "react";
import { useIntl } from "react-intl";

import * as appConfig from "appConfig";
import PlusImg from "@src/assets/images/icons/cil-plus.svg";
import { useUi } from "@jelly/lesson";
import { useSloganName, useObjectName, getThemeImgSrc } from "../helper";

import styles from "./Setup.module.scss";
import { messages } from "../Main.messages";
import { ObjectType, SloganType, ThemeType } from "@src/components/extensions/layouts/lesson-05/types";

export interface SetupProps {
  object?: ObjectType;
  theme?: ThemeType;
  slogan?: SloganType;
  onNextStepClick: () => void;
  onResetClick: () => void;
  onBackClick: () => void;
}

export const Setup: React.FC<SetupProps> = ({ object, theme, slogan, onResetClick, onBackClick, onNextStepClick }) => {
  const { formatMessage } = useIntl();
  const sloganName = useSloganName();
  const objectName = useObjectName();
  const { Button } = useUi();
  const imgUrl =
    appConfig.STATIC_ASSETS_URL +
    "/lms/lesson-05/results/" +
    [object, slogan, theme].filter((p) => !!p).join("_") +
    ".png";

  const isCompleted = ![object, theme, slogan].includes(undefined);
  let setupButtonTitle = null;
  if (!object) {
    setupButtonTitle = formatMessage(messages.chooseObject);
  } else if (!slogan) {
    setupButtonTitle = formatMessage(messages.chooseSlogan);
  } else if (!theme) {
    setupButtonTitle = formatMessage(messages.chooseTheme);
  }

  return (
    <div className={styles.root}>
      <div className={styles.image}>
        <img src={imgUrl} alt={"project"} />
      </div>
      <div className={styles.options}>
        {object && (
          <>
            <small className={styles.label}>{formatMessage(messages.object)}:</small>
            &nbsp;
            <span>{objectName(object)}</span>
            <span className={styles.plus}>
              <PlusImg />
            </span>
          </>
        )}
        {slogan && (
          <>
            <small className={styles.label}>{formatMessage(messages.slogan)}:</small>
            &nbsp;
            <span>{sloganName(slogan)}</span>
            <span className={styles.plus}>
              <PlusImg />
            </span>
          </>
        )}
        {theme && (
          <>
            <small className={styles.label}>{formatMessage(messages.theme)}:</small>
            &nbsp;
            <span className={styles.themeImg}>
              <img src={getThemeImgSrc(theme)} alt={"theme"} />
            </span>
          </>
        )}
        {setupButtonTitle && (
          <>
            <Button shape={"round"} size={"standard"} theme={"primary"} onClick={onNextStepClick}>
              {setupButtonTitle}
            </Button>
          </>
        )}
      </div>
      <div className={styles.controls}>
        <Button
          shape={"round"}
          size={"standard"}
          theme={"primary"}
          iconPlacement={"right"}
          icon={"cil-arrow-left"}
          onClick={onBackClick}
        >
          {formatMessage(messages.setupButtonBack)}
        </Button>
        {isCompleted && (
          <Button
            shape={"round"}
            size={"standard"}
            theme={"primary"}
            iconPlacement={"right"}
            icon={"cil-restore"}
            onClick={onResetClick}
          >
            {formatMessage(messages.setupButtonReset)}
          </Button>
        )}
      </div>
    </div>
  );
};
