import React from "react";
import { SlideView as CoreSlideView } from "@jelly/lesson";

import defaultStyle from "./SlideView.module.scss";

type SlideViewProps = React.ComponentProps<typeof CoreSlideView> & {
  data: React.ComponentProps<typeof CoreSlideView>["data"] & {
    noPadding?: boolean;
  };
};
export const SlideView: React.FC<SlideViewProps> = (props) => {
  const style = { ...defaultStyle };
  if (!props.data.title) {
    style["header"] = style["header"] + " " + style["noHeader"];
  }

  if (!props.data.noPadding) {
    style["layoutWrapper"] = style["layoutWrapper"] + " " + style["defaultPadding"];
  }

  return <CoreSlideView {...props} style={style} />;
};
