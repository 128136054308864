// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.M_QyFgSMKln6j6b8rOa1{color:var(--color-515-baly);border-radius:57.929px;width:48px;display:flex;padding:10px;font-size:15px;line-height:116.5%;font-weight:700;flex-direction:column;align-items:center;background:var(--color-515-szary)}.M_QyFgSMKln6j6b8rOa1 .xKXhoSlvY_MFJxsHtgXV{background:var(--color-515-granat);flex:0 0 auto;border-radius:100px;width:25px;height:25px;justify-content:center;align-items:center;display:flex;cursor:pointer}.M_QyFgSMKln6j6b8rOa1 .xKXhoSlvY_MFJxsHtgXV:not(.gs1hZB5FOqWfmY5pIVeA):hover{background:var(--color-515-niebieski)}.M_QyFgSMKln6j6b8rOa1 .xKXhoSlvY_MFJxsHtgXV.gs1hZB5FOqWfmY5pIVeA{background:var(--color-515-blekitny)}.M_QyFgSMKln6j6b8rOa1 .VdJrwuIppMNMy7tstViw{height:70px;width:1px;background:var(--color-515-granat)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary-dark": `#e73b05`,
	"primary": `#ff6040`,
	"primary-light": `#ffe4df`,
	"primary-extra-light": `#fff2f0`,
	"secondary-dark": `#8292b1`,
	"secondary": `#99a8c6`,
	"secondary-light": `#d0dae9`,
	"secondary-extra-light": `#eef4fc`,
	"default-dark": `#898ca7`,
	"default": `#b8baca`,
	"default-light": `#ecedf5`,
	"default-extra-light": `#f8f8f8`,
	"warning-dark": `#d96800`,
	"warning": `#fbab00`,
	"warning-light": `#ffeba3`,
	"warning-extra-light": `#fff5d1`,
	"info-dark": `#0a82c6`,
	"info": `#2eb6ea`,
	"info-light": `#beebfc`,
	"info-extra-light": `#dff5fe`,
	"success-dark": `#069941`,
	"success": `#3aaa35`,
	"success-light": `#c6f1c3`,
	"success-extra-light": `#e3f9e1`,
	"danger-dark": `#cd0101`,
	"danger": `#ff4141`,
	"danger-light": `#ffcece`,
	"danger-extra-light": `#ffe7e7`,
	"text-primary": `#131a4f`,
	"text-secondary": `#8292b1`,
	"shadow-25": `rgba(184,186,202,.25)`,
	"shadow-50": `rgba(184,186,202,.5)`,
	"root": `M_QyFgSMKln6j6b8rOa1`,
	"button": `xKXhoSlvY_MFJxsHtgXV`,
	"active": `gs1hZB5FOqWfmY5pIVeA`,
	"line": `VdJrwuIppMNMy7tstViw`
};
export default ___CSS_LOADER_EXPORT___;
