import { defineMessages } from "react-intl";

export const messages = defineMessages({
  header: {
    id: "lms.lesson04.iceland-postcard.header",
  },
  instruction: {
    id: "lms.lesson04.iceland-postcard.instruction",
  },
  moreButton: {
    id: "lms.lesson04.iceland-postcard.more-button",
  },
  playButton: {
    id: "lms.lesson04.iceland-postcard.play-button",
  },
  stopButton: {
    id: "lms.lesson04.iceland-postcard.stop-button",
  },
  resetButton: {
    id: "lms.lesson04.iceland-postcard.reset-button",
  },
  downloadButton: {
    id: "lms.lesson04.iceland-postcard.download-button",
  },
  sampleSea: {
    id: "lms.lesson04.iceland-postcard.sample.sea",
  },
  sampleIce: {
    id: "lms.lesson04.iceland-postcard.sample.ice",
  },
  sampleSynthBass: {
    id: "lms.lesson04.iceland-postcard.sample.synth-bass",
  },
  sampleSynth: {
    id: "lms.lesson04.iceland-postcard.sample.synth",
  },
  samplePianoLeftHand: {
    id: "lms.lesson04.iceland-postcard.sample.piano-left-hand",
  },
  samplePianoRightHand: {
    id: "lms.lesson04.iceland-postcard.sample.piano-right-hand",
  },
  sampleDrumsLow: {
    id: "lms.lesson04.iceland-postcard.sample.drums-low",
  },
  sampleDrumsHigh: {
    id: "lms.lesson04.iceland-postcard.sample.drums-high",
  },
  sampleSing1: {
    id: "lms.lesson04.iceland-postcard.sample.sing1",
  },
  sampleSing2: {
    id: "lms.lesson04.iceland-postcard.sample.sing2",
  },
  infoModalTitle: {
    id: "lms.lesson04.iceland-postcard.info-modal.title",
  },
  infoModalSubtitle: {
    id: "lms.lesson04.iceland-postcard.info-modal.subtitle",
  },
  infoModalContent: {
    id: "lms.lesson04.iceland-postcard.info-modal.content",
  },
  infoModalInfo: {
    id: "lms.lesson04.iceland-postcard.info-modal.info",
  },
  infoModalSourceWebsiteButton: {
    id: "lms.lesson04.iceland-postcard.info-modal.source-website-button",
  },
});
