import React from "react";
import { LessonContextProvider, LessonIntl } from "@jelly/lesson";

import * as ui from "@src/components/extensions/ui";
import * as components from "@src/components/extensions/components";
import * as layouts from "@src/components/extensions/layouts";
import * as blocks from "@src/components/extensions/blocks";
import { useConfig } from "@src/components/providers/ConfigProvider";

interface JellyProviderProps {
  children: React.ReactNode;
}

export const JellyProvider: React.FC<JellyProviderProps> = ({ children }) => {
  const { lang } = useConfig();

  return (
    <LessonContextProvider ui={ui} components={components} layouts={layouts} blocks={blocks}>
      <LessonIntl locale={lang}>{children}</LessonIntl>
    </LessonContextProvider>
  );
};
