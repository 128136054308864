// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mI2HCmtWqzCuZJXkHEFr{display:flex;flex-wrap:wrap;gap:10px;justify-content:center;align-content:center;height:100%;color:var(--515-granat);text-align:center;font-size:14px;line-height:116.5%}.mI2HCmtWqzCuZJXkHEFr .OnOgrYMDWHKdjJurj3hf{width:35%;height:50%;padding:20px;display:flex;flex-direction:column;border-radius:15px;background:var(--color-515-szary);box-shadow:0px 1px 2px 0px rgba(184,186,202,.5);text-align:center;cursor:pointer}.mI2HCmtWqzCuZJXkHEFr .OnOgrYMDWHKdjJurj3hf>*{flex:0 0 auto}.mI2HCmtWqzCuZJXkHEFr .OnOgrYMDWHKdjJurj3hf>*:first-child{flex:1 0 0;position:relative}.mI2HCmtWqzCuZJXkHEFr .OnOgrYMDWHKdjJurj3hf>*:first-child img:first-of-type{position:absolute;top:0;left:0;right:0;bottom:0;width:100%;height:100%;object-fit:contain;margin:auto}.mI2HCmtWqzCuZJXkHEFr .OnOgrYMDWHKdjJurj3hf:hover{background:var(--color-515-zimnyszary)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary-dark": `#e73b05`,
	"primary": `#ff6040`,
	"primary-light": `#ffe4df`,
	"primary-extra-light": `#fff2f0`,
	"secondary-dark": `#8292b1`,
	"secondary": `#99a8c6`,
	"secondary-light": `#d0dae9`,
	"secondary-extra-light": `#eef4fc`,
	"default-dark": `#898ca7`,
	"default": `#b8baca`,
	"default-light": `#ecedf5`,
	"default-extra-light": `#f8f8f8`,
	"warning-dark": `#d96800`,
	"warning": `#fbab00`,
	"warning-light": `#ffeba3`,
	"warning-extra-light": `#fff5d1`,
	"info-dark": `#0a82c6`,
	"info": `#2eb6ea`,
	"info-light": `#beebfc`,
	"info-extra-light": `#dff5fe`,
	"success-dark": `#069941`,
	"success": `#3aaa35`,
	"success-light": `#c6f1c3`,
	"success-extra-light": `#e3f9e1`,
	"danger-dark": `#cd0101`,
	"danger": `#ff4141`,
	"danger-light": `#ffcece`,
	"danger-extra-light": `#ffe7e7`,
	"text-primary": `#131a4f`,
	"text-secondary": `#8292b1`,
	"shadow-25": `rgba(184,186,202,.25)`,
	"shadow-50": `rgba(184,186,202,.5)`,
	"root": `mI2HCmtWqzCuZJXkHEFr`,
	"step": `OnOgrYMDWHKdjJurj3hf`
};
export default ___CSS_LOADER_EXPORT___;
