import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "lms.lesson04.odra-postcard.title",
  },
  instruction: {
    id: "lms.lesson04.odra-postcard.instruction",
  },
  moreButton: {
    id: "lms.lesson04.iceland-postcard.more-button",
  },
  playButton: {
    id: "lms.lesson04.iceland-postcard.play-button",
  },
  resetButton: {
    id: "lms.lesson04.iceland-postcard.reset-button",
  },
  downloadButton: {
    id: "lms.lesson04.iceland-postcard.download-button",
  },
  samplePiano: {
    id: "lms.lesson04.odra-postcard.sample.piano",
  },
  sampleSynthBass: {
    id: "lms.lesson04.odra-postcard.sample.synth-bass",
  },
  sampleSwamp: {
    id: "lms.lesson04.odra-postcard.sample.swamp",
  },
  sampleCuckoo: {
    id: "lms.lesson04.odra-postcard.sample.cuckoo",
  },
  sampleDrums: {
    id: "lms.lesson04.odra-postcard.sample.drums",
  },
  sampleOwl: {
    id: "lms.lesson04.odra-postcard.sample.owl",
  },
  sampleWater: {
    id: "lms.lesson04.odra-postcard.sample.water",
  },
  sampleInstruments: {
    id: "lms.lesson04.odra-postcard.sample.instruments",
  },
  sampleSynth: {
    id: "lms.lesson04.odra-postcard.sample.synth",
  },
  infoModalTitle: {
    id: "lms.lesson04.odra-postcard.info-modal.title",
  },
  infoModalSubtitle: {
    id: "lms.lesson04.odra-postcard.info-modal.subtitle",
  },
  infoModalContent: {
    id: "lms.lesson04.odra-postcard.info-modal.content",
  },
  infoModalInfo: {
    id: "lms.lesson04.odra-postcard.info-modal.info",
  },
  infoModalSourceWebsiteButton: {
    id: "lms.lesson04.odra-postcard.info-modal.source-website-button",
  },
});
