// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DgKMmm88Gh4Sziywg1_t{display:flex;height:100%;padding:15px 0;justify-content:stretch;align-items:stretch}.DgKMmm88Gh4Sziywg1_t h3{text-align:center;font-size:16px;margin:0 0 17px}.DgKMmm88Gh4Sziywg1_t>*{padding:0 15px;text-align:center}.DgKMmm88Gh4Sziywg1_t>*:nth-of-type(1){flex:0 0 auto;display:flex;align-items:center;justify-content:center}.DgKMmm88Gh4Sziywg1_t>*:nth-of-type(2){flex:5 1 0;border-left:1px solid var(--color-515-zimnyszary);border-right:1px solid var(--color-515-zimnyszary);display:flex;flex-direction:column}.DgKMmm88Gh4Sziywg1_t>*:nth-of-type(2) h3{flex:0 0 auto}.DgKMmm88Gh4Sziywg1_t>*:nth-of-type(2) .HZDHwJjevmSZhilukftw{flex:1 0 0;position:relative}.DgKMmm88Gh4Sziywg1_t>*:nth-of-type(2) .HZDHwJjevmSZhilukftw img{position:absolute;top:0;left:0;right:0;bottom:0;width:100%;height:100%;object-fit:contain}.DgKMmm88Gh4Sziywg1_t>*:nth-of-type(3){flex:1 0 185px}.DgKMmm88Gh4Sziywg1_t>*:nth-of-type(3) p{text-align:left}.DgKMmm88Gh4Sziywg1_t .cf1f6c3_umhdvphl_NQm{display:flex;width:100%;justify-content:center;justify-items:center;gap:10px;flex-wrap:wrap;margin:17px 0;flex:0 0 auto}.DgKMmm88Gh4Sziywg1_t .CiUrCcqD4tTfCACAUZ9r{display:flex;width:100%;justify-content:center;justify-items:center;gap:10px;flex:0 0 auto}.DgKMmm88Gh4Sziywg1_t .WRSbuJMVU4Va9vchhHfN{display:flex;flex-direction:column}.DgKMmm88Gh4Sziywg1_t .WRSbuJMVU4Va9vchhHfN>*{flex:0 0 auto}.DgKMmm88Gh4Sziywg1_t .WRSbuJMVU4Va9vchhHfN .ZIJdyDeg5NDb9VWNemJj{flex:1 0 0;position:relative;border-radius:10px;overflow:hidden}.DgKMmm88Gh4Sziywg1_t .WRSbuJMVU4Va9vchhHfN .ZIJdyDeg5NDb9VWNemJj img{position:absolute;width:100%;height:100%;object-fit:cover;top:0;left:0;right:0;bottom:0}.DgKMmm88Gh4Sziywg1_t .WRSbuJMVU4Va9vchhHfN p{font-size:14px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary-dark": `#e73b05`,
	"primary": `#ff6040`,
	"primary-light": `#ffe4df`,
	"primary-extra-light": `#fff2f0`,
	"secondary-dark": `#8292b1`,
	"secondary": `#99a8c6`,
	"secondary-light": `#d0dae9`,
	"secondary-extra-light": `#eef4fc`,
	"default-dark": `#898ca7`,
	"default": `#b8baca`,
	"default-light": `#ecedf5`,
	"default-extra-light": `#f8f8f8`,
	"warning-dark": `#d96800`,
	"warning": `#fbab00`,
	"warning-light": `#ffeba3`,
	"warning-extra-light": `#fff5d1`,
	"info-dark": `#0a82c6`,
	"info": `#2eb6ea`,
	"info-light": `#beebfc`,
	"info-extra-light": `#dff5fe`,
	"success-dark": `#069941`,
	"success": `#3aaa35`,
	"success-light": `#c6f1c3`,
	"success-extra-light": `#e3f9e1`,
	"danger-dark": `#cd0101`,
	"danger": `#ff4141`,
	"danger-light": `#ffcece`,
	"danger-extra-light": `#ffe7e7`,
	"text-primary": `#131a4f`,
	"text-secondary": `#8292b1`,
	"shadow-25": `rgba(184,186,202,.25)`,
	"shadow-50": `rgba(184,186,202,.5)`,
	"root": `DgKMmm88Gh4Sziywg1_t`,
	"imgWrapper": `HZDHwJjevmSZhilukftw`,
	"waterParams": `cf1f6c3_umhdvphl_NQm`,
	"audioRow": `CiUrCcqD4tTfCACAUZ9r`,
	"explanation": `WRSbuJMVU4Va9vchhHfN`,
	"riverImgWrapper": `ZIJdyDeg5NDb9VWNemJj`
};
export default ___CSS_LOADER_EXPORT___;
