import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { availableSamples } from "@src/components/extensions/layouts/lesson-04/iceland-postcard/samples";
import { usePostcardModel } from "@src/components/extensions/layouts/lesson-04/iceland-postcard/PostcardModel";
import { TACT_LENGTH, TRACK_WIDTH } from "@src/components/extensions/layouts/lesson-04/iceland-postcard/helper";
import * as Tone from "tone";
import toWav from "audiobuffer-to-wav";
import { SampleOnTrack } from "@src/components/extensions/layouts/lesson-04/iceland-postcard/types";
import { Player } from "@src/components/extensions/layouts/lesson-04/types";

const sampleId = (sample: SampleOnTrack): string => {
  return [sample.sample.src, sample.pos.x, sample.pos.y].join(":");
};

export type IcelandPostcardPlayer = Player & {
  activeTact?: number;
};

export function usePostcardPlayer(): IcelandPostcardPlayer {
  const [loaded, setLoaded] = useState(false);
  const [playing, setPlaying] = useState(false);
  const players = useRef<Record<string, Tone.Player>>({});
  const buffers = useRef<Tone.ToneAudioBuffers | undefined>(undefined);
  const { samples } = usePostcardModel();
  const [activeTact, setActiveTact] = useState<number | undefined>(undefined);

  const load = useCallback(() => {
    if (buffers.current) {
      return;
    }

    buffers.current = new Tone.ToneAudioBuffers({
      urls: Object.fromEntries(availableSamples.map((s) => [s.src, s.src])),
    });

    Tone.loaded().then(() => setLoaded(true));
  }, []);

  const playerForSample = (sample: SampleOnTrack): Tone.Player => {
    const id = sampleId(sample);
    let player = players.current[sampleId(sample)];
    if (!player) {
      player = new Tone.Player().toDestination();
      player.buffer = buffers.current!.get(sample.sample.src)!;
      players.current[id] = player;
    }

    return player;
  };

  const playersPerTact = useMemo(
    () =>
      samples.reduce(
        (arr, sample) => {
          if (!loaded) {
            return [];
          }

          arr[sample.pos.x].push(playerForSample(sample));
          return arr;
        },
        new Array(TRACK_WIDTH).fill(null).map(() => []) as Tone.Player[][]
      ),
    [loaded, samples]
  );

  const sequence = useMemo(() => {
    if (!loaded) {
      return null;
    }

    return new Tone.Sequence(
      (time, tactNr) => {
        setActiveTact(tactNr);
        playersPerTact[tactNr].forEach((p) => p.start());
      },
      new Array(TRACK_WIDTH).fill(null).map((_, index) => index)
    );
  }, [loaded, playersPerTact]);

  const play = useCallback(() => {
    Tone.Transport.bpm.value = 60 / TACT_LENGTH / 2; //No idea why it's divided by 2
    Tone.loaded().then(() => {
      Tone.start();
      Tone.Transport.start();
    });
    setPlaying(true);
  }, []);

  const stop = useCallback(() => {
    Object.values(players.current).forEach((p) => p.stop());
    Tone.Transport.stop();
    setPlaying(false);
    setActiveTact(undefined);
  }, [players]);

  useEffect(() => {
    sequence?.start(0);

    return () => {
      stop();
      sequence?.dispose();
    };
  }, [sequence, stop]);

  const exportToWav = useCallback(async () => {
    const render = Tone.Offline(({ transport }) => {
      playersPerTact.forEach((players, tact) => {
        players.map((p) => {
          const delayedPlayer = new Tone.Player().toDestination();
          delayedPlayer.buffer = p.buffer;
          delayedPlayer.start(TACT_LENGTH * tact);
        });
      });

      transport.start();
    }, TACT_LENGTH * TRACK_WIDTH);

    const audioBuffer = await render;
    const data = toWav(audioBuffer as unknown as AudioBuffer);
    const blob = new Blob([data], { type: "audio/wav" });

    // Fire file download
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "iceland-postcard.wav");
    document.body.appendChild(link);
    link.click();
  }, [playersPerTact]);

  return {
    play,
    loaded,
    empty: samples.length === 0,
    load,
    stop,
    playing,
    download: exportToWav,
    activeTact,
  };
}
