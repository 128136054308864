import React, { Fragment } from "react";

export function nl2br(str: string): React.JSX.Element {
  const arr = str
    .split("\n")
    .flatMap((line, index) => [<Fragment key={index}>{line}</Fragment>, <br key={`${index}br`} />]);
  arr.pop(); //remove last <br />

  return <>{arr}</>;
}

export function nl2brToString(str: string): string {
  return str.split("\n").join("<br />");
}
