import React, { LegacyRef, useCallback, useState } from "react";

export interface IcelandPostcardProps {}

import styles from "./IcelandPostcard.module.scss";
import { Board } from "@src/components/extensions/layouts/lesson-04/iceland-postcard/components/Board";
import { TilesLibrary } from "@src/components/extensions/layouts/lesson-04/iceland-postcard/components/TilesLibrary";
import { useUi } from "@jelly/lesson";
import { useIntl } from "react-intl";

import { messages } from "./IcelandPostcard.messages";
import { DndProvider } from "react-dnd-multi-backend";
import { HTML5toTouch } from "rdndmb-html5-to-touch";

import {
  PostcardModelProvider,
  usePostcardModel,
} from "@src/components/extensions/layouts/lesson-04/iceland-postcard/PostcardModel";
import {
  IcelandPostcardPlayer,
  usePostcardPlayer,
} from "@src/components/extensions/layouts/lesson-04/iceland-postcard/PostcardPlayer";
import { InfoModal } from "@src/components/extensions/layouts/lesson-04/iceland-postcard/components/InfoModal";
import { PlayerControls } from "@src/components/extensions/layouts/lesson-04/components/PlayerControls";
import { Player } from "@src/components/extensions/layouts/lesson-04/types";
import { useMeasure } from "react-use";
import classnames from "classnames";

export const IcelandPostcard: React.FC<IcelandPostcardProps> = () => {
  return (
    <DndProvider options={HTML5toTouch}>
      <PostcardModelProvider>
        <IcelandPostcardContent />
      </PostcardModelProvider>
    </DndProvider>
  );
};

export const IcelandPostcardContent: React.FC = () => {
  const ui = useUi();
  const { formatMessage } = useIntl();
  const [infoOpen, setInfoOpen] = useState(false);
  const handleInfoOpen = useCallback(() => setInfoOpen(true), []);
  const handleInfoClose = useCallback(() => setInfoOpen(false), []);
  const player = usePostcardPlayer();

  const [ref, boundingBox] = useMeasure();

  return (
    <div
      className={classnames(styles.root, {
        [styles.small]: boundingBox.height < 590,
      })}
      ref={ref as LegacyRef<HTMLDivElement>}
    >
      <InfoModal visible={infoOpen} onClose={handleInfoClose} />
      <h3>{formatMessage(messages.header)}</h3>
      <div className={styles.board}>
        <BoardWrapper player={player} />
      </div>
      <div className={styles.hr}>
        <ui.Icon className={"cil-chevron-double-up-alt"} />
      </div>
      <div className={styles.tilesLibrary}>
        <TilesLibrary />
      </div>
      <div className={styles.controls}>
        <ControlsWrapper onInfoOpen={handleInfoOpen} player={player} />
      </div>
    </div>
  );
};

const BoardWrapper: React.FC<{ player: IcelandPostcardPlayer }> = ({ player }) => {
  const { samples } = usePostcardModel();
  return <Board samples={samples} highlightTact={player.activeTact} />;
};

interface ControlsWrapperProps {
  onInfoOpen: () => void;
  player: Player;
}

const ControlsWrapper: React.FC<ControlsWrapperProps> = ({ onInfoOpen, player }) => {
  const { formatMessage } = useIntl();
  const { reset } = usePostcardModel();

  return (
    <PlayerControls
      instruction={formatMessage(messages.instruction)}
      player={player}
      onReset={reset}
      onInfoOpen={onInfoOpen}
    />
  );
};
