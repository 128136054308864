// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eS9EEZheFZ_R_ibOZnIp{display:grid;column-gap:5px;row-gap:5px;width:100%;height:100%;position:relative}.eS9EEZheFZ_R_ibOZnIp .vO9Ie4OwDQ08XW5rk9lp>*{width:100%;height:100%}.eS9EEZheFZ_R_ibOZnIp .vO9Ie4OwDQ08XW5rk9lp>*>*>*{box-shadow:0px 1px 3px 0px rgba(0,0,0,.25) inset;width:100%;height:100%}.eS9EEZheFZ_R_ibOZnIp .f5EbO9f410j4V07FDZ_1{background:rgba(7,123,191,.3) !important;position:absolute;width:calc((100% - 25px)/6 + 4px);height:calc(100% + 10px);z-index:100;margin:-5px -2px;border-radius:10px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary-dark": `#e73b05`,
	"primary": `#ff6040`,
	"primary-light": `#ffe4df`,
	"primary-extra-light": `#fff2f0`,
	"secondary-dark": `#8292b1`,
	"secondary": `#99a8c6`,
	"secondary-light": `#d0dae9`,
	"secondary-extra-light": `#eef4fc`,
	"default-dark": `#898ca7`,
	"default": `#b8baca`,
	"default-light": `#ecedf5`,
	"default-extra-light": `#f8f8f8`,
	"warning-dark": `#d96800`,
	"warning": `#fbab00`,
	"warning-light": `#ffeba3`,
	"warning-extra-light": `#fff5d1`,
	"info-dark": `#0a82c6`,
	"info": `#2eb6ea`,
	"info-light": `#beebfc`,
	"info-extra-light": `#dff5fe`,
	"success-dark": `#069941`,
	"success": `#3aaa35`,
	"success-light": `#c6f1c3`,
	"success-extra-light": `#e3f9e1`,
	"danger-dark": `#cd0101`,
	"danger": `#ff4141`,
	"danger-light": `#ffcece`,
	"danger-extra-light": `#ffe7e7`,
	"text-primary": `#131a4f`,
	"text-secondary": `#8292b1`,
	"shadow-25": `rgba(184,186,202,.25)`,
	"shadow-50": `rgba(184,186,202,.5)`,
	"root": `eS9EEZheFZ_R_ibOZnIp`,
	"boardItem": `vO9Ie4OwDQ08XW5rk9lp`,
	"tactHighlight": `f5EbO9f410j4V07FDZ_1`
};
export default ___CSS_LOADER_EXPORT___;
