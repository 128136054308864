import React, { useMemo } from "react";
import ReactDOMServer from "react-dom/server";

import { AnnotatedImage } from "../components/AnnotatedImage";
import { useBlocks, useUi } from "@jelly/lesson";
import { Annotation } from "@src/components/extensions/layouts/lesson-04/components/Annotation";
import VideoBlockView from "@jelly/lesson/dist/@types/components/Block/Video/VideoBlockView";
import { messages } from "./Place.messages";

import styles from "./Place.module.scss";
import { useIntl } from "react-intl";
import { nl2br, nl2brToString } from "@src/utils/nl2br";

export interface PlaceProps {
  title: string;
  description?: string;
  imgData: React.ComponentProps<typeof AnnotatedImage>;
  mapImgSrc: string;
  imgAudioSamples?: string[];
  audioSamples?: string[];
  videoSrc?: string;
  videoProvider?: React.ComponentProps<typeof VideoBlockView>["provider"];
  headphonesInfoExtra?: string;
}

export const Place: React.FC<PlaceProps> = ({
  title,
  description,
  imgData,
  mapImgSrc,
  imgAudioSamples,
  audioSamples,
  videoSrc,
  videoProvider,
  headphonesInfoExtra,
}) => {
  const ui = useUi();
  const blocks = useBlocks();
  const { formatMessage } = useIntl();

  const allAudioSamples = useMemo(
    () => [
      ...(imgAudioSamples ?? []).map((src, index) => ({
        src,
        text: `${ReactDOMServer.renderToString(<Annotation text={index + 1 + ""} />)} ${formatMessage(
          messages.listenTo
        )}`,
      })),
      ...(audioSamples ?? []).map((src) => ({ src, text: formatMessage(messages.listenTo) })),
    ],
    [imgAudioSamples, audioSamples, formatMessage]
  );

  return (
    <div className={styles.root}>
      <div className={styles.imageWrapper}>
        <div className={styles.image}>
          <AnnotatedImage {...imgData} />
        </div>
      </div>
      <div className={styles.infoWrapper}>
        <h3>{nl2br(title)}</h3>
        <div className={styles.map}>
          <img src={mapImgSrc} alt={"map"} />
        </div>
        <p className={styles.description}>
          {description && <ui.MathSvg formatter={"html"}>{nl2brToString(description)}</ui.MathSvg>}
        </p>
        <div className={styles.buttonsWrapper}>
          {allAudioSamples.map(({ src, text }, index) => (
            <blocks.audioButton
              key={index}
              url={src}
              shape="round"
              theme="secondary"
              size="standard"
              icon="cil-headphones"
              iconPlacement={"right"}
              text={text}
            />
          ))}
          {videoSrc && (
            <blocks.videoButton
              videoBlock={{ src: videoSrc, provider: videoProvider }}
              text={formatMessage(messages.watch)}
              theme={"secondary"}
              shape={"round"}
              icon={"cil-video"}
              iconPlacement={"right"}
            />
          )}
        </div>
        <div className={styles.explanation}>
          <div>
            <ui.Icon className={"cil-info-circle"} theme={"secondary"} />
          </div>
          <div>
            <b>{formatMessage(messages.headphonesInfo1)}</b>
            <br />
            <span>{formatMessage(messages.headphonesInfo2)}</span>
            {headphonesInfoExtra && (
              <>
                <br />
                <span>{headphonesInfoExtra}</span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
