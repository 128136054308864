import React from "react";
import { SampleOnTrack } from "@src/components/extensions/layouts/lesson-04/iceland-postcard/types";

import styles from "./FilledSlot.module.scss";
import { DraggableTile } from "@src/components/extensions/layouts/lesson-04/iceland-postcard/components/DraggableTile";

interface FilledSlotProps {
  sample: SampleOnTrack;
}

export const FilledSlot: React.FC<FilledSlotProps> = ({ sample }) => {
  return (
    <div className={styles.root}>
      <DraggableTile sample={sample.sample} currentPosition={sample.pos} />
    </div>
  );
};
