// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ejiZWjd11ckx7vRnSlik{height:100%;padding:10px}.whrNRk8DhttWI1ilkAdX{display:flex;flex-direction:column;position:relative;height:calc(100% - 68px)}.vKRsXn3BuWXHj3_e19Ig{width:100%;height:100%;display:flex;border:1px solid var(--color-515-zimnyszary);border-radius:20px;max-width:1600px;margin:0 auto;flex-direction:column}.vKRsXn3BuWXHj3_e19Ig.nljdF_KGIPo676mnmBSc{padding:15px}.qlkQtMPdEA8VCRXhbFzf{height:68px}.H4QNb5sAcawCn2A0lctN h2{text-align:center;color:var(--color-515-granat);font-size:16px;font-style:normal;font-weight:700;line-height:116.5%;margin:0 17px}.tt6h3yVjcMIHPKTQwHNV{flex:0 0 0;overflow:hidden}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary-dark": `#e73b05`,
	"primary": `#ff6040`,
	"primary-light": `#ffe4df`,
	"primary-extra-light": `#fff2f0`,
	"secondary-dark": `#8292b1`,
	"secondary": `#99a8c6`,
	"secondary-light": `#d0dae9`,
	"secondary-extra-light": `#eef4fc`,
	"default-dark": `#898ca7`,
	"default": `#b8baca`,
	"default-light": `#ecedf5`,
	"default-extra-light": `#f8f8f8`,
	"warning-dark": `#d96800`,
	"warning": `#fbab00`,
	"warning-light": `#ffeba3`,
	"warning-extra-light": `#fff5d1`,
	"info-dark": `#0a82c6`,
	"info": `#2eb6ea`,
	"info-light": `#beebfc`,
	"info-extra-light": `#dff5fe`,
	"success-dark": `#069941`,
	"success": `#3aaa35`,
	"success-light": `#c6f1c3`,
	"success-extra-light": `#e3f9e1`,
	"danger-dark": `#cd0101`,
	"danger": `#ff4141`,
	"danger-light": `#ffcece`,
	"danger-extra-light": `#ffe7e7`,
	"text-primary": `#131a4f`,
	"text-secondary": `#8292b1`,
	"shadow-25": `rgba(184,186,202,.25)`,
	"shadow-50": `rgba(184,186,202,.5)`,
	"slideView": `ejiZWjd11ckx7vRnSlik`,
	"slideWrapper": `whrNRk8DhttWI1ilkAdX`,
	"layoutWrapper": `vKRsXn3BuWXHj3_e19Ig`,
	"defaultPadding": `nljdF_KGIPo676mnmBSc`,
	"nav": `qlkQtMPdEA8VCRXhbFzf`,
	"header": `H4QNb5sAcawCn2A0lctN`,
	"noHeader": `tt6h3yVjcMIHPKTQwHNV`
};
export default ___CSS_LOADER_EXPORT___;
