import * as React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { Theme } from "@jelly/ui";

import { useUi } from "@jelly/lesson";
import { Slide } from "@jelly/lesson";

import defaultStyle from "./LessonItemNavigator.module.scss";
import { messages } from "./LessonItemNavigator.messages";

export interface LessonItemNavigatorProps {
  items: Slide[];
  active: number;
  nextLessonUrl: string | null;
  prevLessonUrl: string | null;
  onChangeActive: (newSlideIndex: number) => void;
  onCheckAnswer?: () => Promise<void>;
  onRestoreTask?: () => void;
  style?: Record<string, string>;
}

interface ButtonTemplateConfig {
  text?: string;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  url?: string | null;
  theme?: Theme;
  icon?: string;
  iconPlacement?: "left" | "right";
}

const MAIN_PAGE_PATH = "/";

export const LessonItemNavigator: React.FC<LessonItemNavigatorProps> = (props) => {
  const { Button, Popover } = useUi();

  const { items, active, onChangeActive, style = defaultStyle } = props;
  const intl = useIntl();
  const nextItem = items[active + 1];

  const isNextBonusPage = active === items.length - 2; // second last;

  const defaultOnNext = (): void => {
    onChangeActive(active + 1);
  };

  function getSubText(index: number): string {
    return `${index + 1} / ${items.length}`;
  }

  function renderPrevButtonTemplate(data: ButtonTemplateConfig): React.ReactNode {
    const { icon = "cil-arrow-left", iconPlacement = "left", theme = "info" } = data;

    const button = (
      <Button
        shape="round"
        theme={theme}
        icon={icon}
        iconPlacement={iconPlacement}
        onClick={data.onClick}
        disabled={data.disabled}
        className={data.className}
      >
        {data.text}
      </Button>
    );

    return data.url ? <Link to={data.url}>{button}</Link> : button;
  }

  function renderNextButtonTemplate(data: ButtonTemplateConfig): React.ReactNode {
    const { icon = "cil-arrow-right", iconPlacement = "right", theme = "primary" } = data;

    const button = (
      <Button
        shape="round"
        theme={theme}
        icon={icon}
        iconPlacement={iconPlacement}
        disabled={data.disabled}
        className={data.className}
        onClick={data.onClick}
      >
        {data.text}
      </Button>
    );

    return <>{data.url ? <Link to={data.url}>{button}</Link> : button}</>;
  }

  function renderNextButton(): React.ReactNode {
    if (!nextItem) {
      return null;
    }

    if (isNextBonusPage) {
      return renderNextButtonTemplate({
        onClick: defaultOnNext,
        text: intl.formatMessage(messages.bonusButton),
      });
    }

    return renderNextButtonTemplate({
      onClick: defaultOnNext,
      text: intl.formatMessage(messages.experimentButton),
    });
  }

  function renderPrevButton(): React.ReactNode {
    return renderPrevButtonTemplate({
      text: intl.formatMessage(messages.mainPageButton),
      className: "prev-lesson",
      disabled: props.prevLessonUrl === null,
      url: MAIN_PAGE_PATH,
    });
  }

  return (
    <div className={style.navbar}>
      <div className={classnames(style.buttonContainer, style.left)}>{renderPrevButton()}</div>
      <div className={style.breadcrumbs}>
        {items.map((_, index) => (
          <Popover key={index} subTxt={getSubText(index)}>
            <div
              className={classnames(style.dot, { [style.active]: index === active })}
              onClick={() => onChangeActive(index)}
            />
          </Popover>
        ))}
      </div>
      <div className={classnames(style.buttonContainer, style.right)}>{renderNextButton()}</div>
    </div>
  );
};
