import React from "react";

import styles from "./WrappedContent.module.scss";
import { MathSvg } from "@jelly/ui";

export interface WrappedContentProps {
  description?: string;
  title?: string;
  children: React.ReactNode;
}

export const WrappedContent: React.FC<WrappedContentProps> = ({ description, children, title }) => {
  return (
    <div className={styles.root}>
      {title && <div className={styles.header}>{title}</div>}
      <div className={styles.contentWrapper}>
        <div className={styles.content}>{children}</div>
      </div>
      <div className={styles.description}>{description && <MathSvg formatter="html">{description}</MathSvg>}</div>
    </div>
  );
};
