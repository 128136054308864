// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Cl3FF9YHgKwXgDz9_NNw{height:100%;width:100%;display:flex;align-items:center;justify-content:center;position:relative}.LU9kXkua6eOOV9LuVqag.b8YORwMN3xDHJABkLbxy{width:100%}.LU9kXkua6eOOV9LuVqag.b8YORwMN3xDHJABkLbxy button{width:100%}.LU9kXkua6eOOV9LuVqag>div{position:relative;display:flex;box-shadow:0px 1px 2px 0px rgba(184,186,202,.5);background:var(--color-515-szary);border-radius:22px;padding:10px;margin:0 5px;gap:12px}.LU9kXkua6eOOV9LuVqag>div:before{z-index:0;content:"";position:absolute;top:50%;left:10px;border-top:1px solid var(--color-515-granat);background:#000;width:calc(100% - 20px)}.LU9kXkua6eOOV9LuVqag>div button{z-index:1;margin:0;box-shadow:none;padding:5px 10px;max-width:100%;font-size:14px;font-weight:700;line-height:16px;border:1px solid rgba(0,0,0,0);color:var(--color-515-baly);background:var(--color-515-granat)}.LU9kXkua6eOOV9LuVqag>div button>span{white-space:nowrap}.LU9kXkua6eOOV9LuVqag>div button.BUsng2rJFqCR1DdHvi2t{background:var(--color-515-blekitny)}.LU9kXkua6eOOV9LuVqag>div button.XWVye16r0PMDeojageYm{background:var(--color-515-szary)}.LU9kXkua6eOOV9LuVqag>div button.XWVye16r0PMDeojageYm:hover,.LU9kXkua6eOOV9LuVqag>div button.XWVye16r0PMDeojageYm:focus{border-color:rgba(0,0,0,0)}.LU9kXkua6eOOV9LuVqag>span{font-size:12px;line-height:16px;font-weight:700}.LU9kXkua6eOOV9LuVqag>span div{margin-left:5px}.LU9kXkua6eOOV9LuVqag.y4JXqoMGLd_k44kSPcKa>div{margin:7px 5px 0}.LU9kXkua6eOOV9LuVqag._q8PpVQqD3fdjDu59Kkc{display:flex;align-items:center;margin-left:25px}.LU9kXkua6eOOV9LuVqag._q8PpVQqD3fdjDu59Kkc>span{margin-right:17px}.LU9kXkua6eOOV9LuVqag.GIviUPt9lZNVVBQn7JHG>div{padding:3px 3px}.LU9kXkua6eOOV9LuVqag.GIviUPt9lZNVVBQn7JHG button{padding:0 5px;line-height:12px}.LU9kXkua6eOOV9LuVqag button:nth-of-type(7):not(.BUsng2rJFqCR1DdHvi2t){background:var(--color-515-zolty);color:var(--color-515-granat)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary-dark": `#e73b05`,
	"primary": `#ff6040`,
	"primary-light": `#ffe4df`,
	"primary-extra-light": `#fff2f0`,
	"secondary-dark": `#8292b1`,
	"secondary": `#99a8c6`,
	"secondary-light": `#d0dae9`,
	"secondary-extra-light": `#eef4fc`,
	"default-dark": `#898ca7`,
	"default": `#b8baca`,
	"default-light": `#ecedf5`,
	"default-extra-light": `#f8f8f8`,
	"warning-dark": `#d96800`,
	"warning": `#fbab00`,
	"warning-light": `#ffeba3`,
	"warning-extra-light": `#fff5d1`,
	"info-dark": `#0a82c6`,
	"info": `#2eb6ea`,
	"info-light": `#beebfc`,
	"info-extra-light": `#dff5fe`,
	"success-dark": `#069941`,
	"success": `#3aaa35`,
	"success-light": `#c6f1c3`,
	"success-extra-light": `#e3f9e1`,
	"danger-dark": `#cd0101`,
	"danger": `#ff4141`,
	"danger-light": `#ffcece`,
	"danger-extra-light": `#ffe7e7`,
	"text-primary": `#131a4f`,
	"text-secondary": `#8292b1`,
	"shadow-25": `rgba(184,186,202,.25)`,
	"shadow-50": `rgba(184,186,202,.5)`,
	"root": `Cl3FF9YHgKwXgDz9_NNw`,
	"buttonsGroup": `LU9kXkua6eOOV9LuVqag`,
	"fullWidth": `b8YORwMN3xDHJABkLbxy`,
	"active": `BUsng2rJFqCR1DdHvi2t`,
	"disabled": `XWVye16r0PMDeojageYm`,
	"top": `y4JXqoMGLd_k44kSPcKa`,
	"left": `_q8PpVQqD3fdjDu59Kkc`,
	"extraSmall": `GIviUPt9lZNVVBQn7JHG`
};
export default ___CSS_LOADER_EXPORT___;
