// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yD9vnvAPgfpwIYCgbrvz{display:none}.Grrzvz8YHajlGuMmIVMp{display:flex;height:100%;position:relative;width:100%}.YiKjy71OoirC6lK_MKVI{width:100%}.Grrzvz8YHajlGuMmIVMp{display:flex;height:100%;position:relative}.Grrzvz8YHajlGuMmIVMp .b59MmMPEAaO4CxaLBtyK{display:none}.r_Dgj__S_U5tVfLih5BO{height:100%;width:100%}.dCr8vsa_F_9jBsE6119p{height:100%}.gAgFh6xVd9ySQhibRDl3{width:30%;min-width:300px;text-align:justify;overflow-y:scroll;padding-right:2px;box-sizing:content-box;position:relative}.gAgFh6xVd9ySQhibRDl3 .WfYqdjUxzKmXjR9jfNrv{position:relative;background:#fff;width:100%}.gAgFh6xVd9ySQhibRDl3 .WfYqdjUxzKmXjR9jfNrv .jFNI1g4TTxwMx9Zm_yoG{font-family:"Noto Sans Display",sans-serif;color:#1e3470;line-height:20px;height:2.3em;font-size:1.125em;padding:0 1.75em;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical}.gAgFh6xVd9ySQhibRDl3 .WfYqdjUxzKmXjR9jfNrv .sh5AinIW5s_LMrHJZZow{display:none;position:absolute;width:100%;z-index:1;top:2.5em;box-shadow:0 5px 4px rgba(153,168,198,.25)}.gAgFh6xVd9ySQhibRDl3 .WfYqdjUxzKmXjR9jfNrv .GHymEjWH6qH4tnEOLMKu{display:block}.gAgFh6xVd9ySQhibRDl3 .WfYqdjUxzKmXjR9jfNrv .b59MmMPEAaO4CxaLBtyK{position:absolute;color:#d0dae9;top:0;right:0}.gAgFh6xVd9ySQhibRDl3 .WfYqdjUxzKmXjR9jfNrv .b59MmMPEAaO4CxaLBtyK:hover{cursor:pointer;color:#1e3470}.gAgFh6xVd9ySQhibRDl3 .H20p4IIfveSCpaUSEvcw{padding:0 1.9em}.YMcmNMY12246dxJxbUCA{position:absolute;height:100%;background:#fff}.CdHRMfiiCrtVRB0GOAHm{flex:1}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary-dark": `#e73b05`,
	"primary": `#ff6040`,
	"primary-light": `#ffe4df`,
	"primary-extra-light": `#fff2f0`,
	"secondary-dark": `#8292b1`,
	"secondary": `#99a8c6`,
	"secondary-light": `#d0dae9`,
	"secondary-extra-light": `#eef4fc`,
	"default-dark": `#898ca7`,
	"default": `#b8baca`,
	"default-light": `#ecedf5`,
	"default-extra-light": `#f8f8f8`,
	"warning-dark": `#d96800`,
	"warning": `#fbab00`,
	"warning-light": `#ffeba3`,
	"warning-extra-light": `#fff5d1`,
	"info-dark": `#0a82c6`,
	"info": `#2eb6ea`,
	"info-light": `#beebfc`,
	"info-extra-light": `#dff5fe`,
	"success-dark": `#069941`,
	"success": `#3aaa35`,
	"success-light": `#c6f1c3`,
	"success-extra-light": `#e3f9e1`,
	"danger-dark": `#cd0101`,
	"danger": `#ff4141`,
	"danger-light": `#ffcece`,
	"danger-extra-light": `#ffe7e7`,
	"text-primary": `#131a4f`,
	"text-secondary": `#8292b1`,
	"shadow-25": `rgba(184,186,202,.25)`,
	"shadow-50": `rgba(184,186,202,.5)`,
	"fluidTocToggleButton": `yD9vnvAPgfpwIYCgbrvz`,
	"lessonWrapper": `Grrzvz8YHajlGuMmIVMp`,
	"navigator": `YiKjy71OoirC6lK_MKVI`,
	"navToggler": `b59MmMPEAaO4CxaLBtyK`,
	"slideWrapper": `r_Dgj__S_U5tVfLih5BO`,
	"slideContainer": `dCr8vsa_F_9jBsE6119p`,
	"sidebarContainer": `gAgFh6xVd9ySQhibRDl3`,
	"navContainer": `WfYqdjUxzKmXjR9jfNrv`,
	"lessonName": `jFNI1g4TTxwMx9Zm_yoG`,
	"nav": `sh5AinIW5s_LMrHJZZow`,
	"activeNav": `GHymEjWH6qH4tnEOLMKu`,
	"theory": `H20p4IIfveSCpaUSEvcw`,
	"floatingSidebar": `YMcmNMY12246dxJxbUCA`,
	"slide": `CdHRMfiiCrtVRB0GOAHm`
};
export default ___CSS_LOADER_EXPORT___;
