// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kHRtjuCMhGp3zWpUPbpq{display:flex;justify-content:stretch;gap:25px;padding:15px;height:100%;position:relative;align-items:stretch}.kHRtjuCMhGp3zWpUPbpq .kISJ4IJeLhxxGnPbppd9{flex:0 0 auto;width:250px;display:flex;flex-direction:column;justify-content:stretch}.kHRtjuCMhGp3zWpUPbpq .EqkBKZRgOeTHKcmOIzq2{flex:1 2 auto;width:100%;overflow:hidden;border-radius:10px;height:100%;display:flex;flex-direction:column;justify-content:stretch;align-content:stretch}.kHRtjuCMhGp3zWpUPbpq .EqkBKZRgOeTHKcmOIzq2 .ODQI1qf1tPYSYEwFphUS{flex:1 0 0;overflow:hidden;position:relative}.kHRtjuCMhGp3zWpUPbpq .EqkBKZRgOeTHKcmOIzq2 .ODQI1qf1tPYSYEwFphUS img{width:100%;height:100%;position:absolute;top:0;left:0;bottom:0;right:0;object-fit:cover;object-position:left;border-radius:10px}.kHRtjuCMhGp3zWpUPbpq .NpvzXN25z9N3rxd0RZNQ{font-size:25px;font-style:normal;font-weight:700;line-height:100%;letter-spacing:-1px;margin:0 0 17px}.kHRtjuCMhGp3zWpUPbpq .fo1l6w33Gkbz7QI0kUVf{flex:1 0 auto;font-size:12px;font-style:normal;font-weight:400;line-height:135%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary-dark": `#e73b05`,
	"primary": `#ff6040`,
	"primary-light": `#ffe4df`,
	"primary-extra-light": `#fff2f0`,
	"secondary-dark": `#8292b1`,
	"secondary": `#99a8c6`,
	"secondary-light": `#d0dae9`,
	"secondary-extra-light": `#eef4fc`,
	"default-dark": `#898ca7`,
	"default": `#b8baca`,
	"default-light": `#ecedf5`,
	"default-extra-light": `#f8f8f8`,
	"warning-dark": `#d96800`,
	"warning": `#fbab00`,
	"warning-light": `#ffeba3`,
	"warning-extra-light": `#fff5d1`,
	"info-dark": `#0a82c6`,
	"info": `#2eb6ea`,
	"info-light": `#beebfc`,
	"info-extra-light": `#dff5fe`,
	"success-dark": `#069941`,
	"success": `#3aaa35`,
	"success-light": `#c6f1c3`,
	"success-extra-light": `#e3f9e1`,
	"danger-dark": `#cd0101`,
	"danger": `#ff4141`,
	"danger-light": `#ffcece`,
	"danger-extra-light": `#ffe7e7`,
	"text-primary": `#131a4f`,
	"text-secondary": `#8292b1`,
	"shadow-25": `rgba(184,186,202,.25)`,
	"shadow-50": `rgba(184,186,202,.5)`,
	"root": `kHRtjuCMhGp3zWpUPbpq`,
	"leftBlock": `kISJ4IJeLhxxGnPbppd9`,
	"rightBlock": `EqkBKZRgOeTHKcmOIzq2`,
	"imgWrapper": `ODQI1qf1tPYSYEwFphUS`,
	"title": `NpvzXN25z9N3rxd0RZNQ`,
	"description": `fo1l6w33Gkbz7QI0kUVf`
};
export default ___CSS_LOADER_EXPORT___;
