import { defineMessages } from "react-intl";

export const messages = defineMessages({
  listenTo: {
    id: "lms.lesson04.place.listen-to",
  },
  watch: {
    id: "lms.lesson04.place.watch",
  },
  headphonesInfo1: {
    id: "lms.lesson04.place.headphones-info1",
  },
  headphonesInfo2: {
    id: "lms.lesson04.place.headphones-info2",
  },
});
