import React, { useEffect, useState } from "react";

import styles from "./Main.module.scss";
import { messages } from "./Main.messages";
import { useIntl } from "react-intl";
import { useBlocks, useUi } from "@jelly/lesson";
import { SwitchOptionProps } from "@src/components/extensions/ui/switch-option/SwitchOption";
import { AirLegend, SwampLegend } from "@src/components/extensions/layouts/lesson-01/legend/Legend";
import * as appConfig from "appConfig";
import { SwitchOption } from "@src/components/extensions/layouts/lesson-01/components/SwitchOption";
export interface MainProps {
  hideAudio?: boolean;
}

enum Screen {
  m4000,
  m3000,
  m2000,
  m1000,
  m100,
  m90,
  today,
  p100,
  p100a,
  p100b,
}

export const Main: React.FC<MainProps> = ({ hideAudio }) => {
  const { formatMessage, locale } = useIntl();
  const ui = useUi();
  const blocks = useBlocks();
  const [activeSlide, setActiveSlide] = useState(Screen.m4000);
  const [decision, setDecision] = useState<"a" | "b" | undefined>(undefined);

  const AUDIO_PER_SCREEN: Record<Screen, string | null> = {
    [Screen.m4000]: `${appConfig.STATIC_ASSETS_URL}/lms/lesson-01/audio/${locale}/plansza-4000.mp3`,
    [Screen.m3000]: `${appConfig.STATIC_ASSETS_URL}/lms/lesson-01/audio/${locale}/plansza-3000.mp3`,
    [Screen.m2000]: `${appConfig.STATIC_ASSETS_URL}/lms/lesson-01/audio/${locale}/plansza-2000.mp3`,
    [Screen.m1000]: `${appConfig.STATIC_ASSETS_URL}/lms/lesson-01/audio/${locale}/plansza-1000.mp3`,
    [Screen.m100]: `${appConfig.STATIC_ASSETS_URL}/lms/lesson-01/audio/${locale}/plansza-100.mp3`,
    [Screen.m90]: `${appConfig.STATIC_ASSETS_URL}/lms/lesson-01/audio/${locale}/plansza-90.mp3`,
    [Screen.today]: `${appConfig.STATIC_ASSETS_URL}/lms/lesson-01/audio/${locale}/plansza-today.mp3`,
    [Screen.p100]: null,
    [Screen.p100a]: `${appConfig.STATIC_ASSETS_URL}/lms/lesson-01/audio/${locale}/plansza-wet.mp3`,
    [Screen.p100b]: `${appConfig.STATIC_ASSETS_URL}/lms/lesson-01/audio/${locale}/plansza-dry.mp3`,
  };

  const SWAMP_LEGEND_PER_SCREEN: Record<Screen, React.ComponentProps<typeof SwampLegend>["only"]> = {
    [Screen.m4000]: ["water", "vegetation", "peat", "mineral"],
    [Screen.m3000]: ["water", "vegetation", "peat", "mineral"],
    [Screen.m2000]: ["water", "vegetation", "peat", "mineral"],
    [Screen.m1000]: ["water", "vegetation", "peat", "mineral"],
    [Screen.m100]: ["water", "vegetation", "peat", "mineral"],
    [Screen.m90]: ["water", "vegetationTop", "rot", "peat", "mineral"],
    [Screen.today]: ["water", "vegetationTop", "rot", "peat", "mineral", "emission"],
    [Screen.p100]: [],
    [Screen.p100a]: ["water", "vegetation", "rot", "peat", "mineral"],
    [Screen.p100b]: ["vegetationTop", "mineral"],
  };

  useEffect(() => {
    // reset decision when visiting the decision slide
    if (activeSlide === Screen.p100) {
      setDecision(undefined);
    }
  }, [activeSlide]);

  const switchItems: SwitchOptionProps["items"] = [
    {
      name: formatMessage(messages.switchM4000),
    },
    {
      name: formatMessage(messages.switchM3000),
    },
    {
      name: formatMessage(messages.switchM2000),
    },
    {
      name: formatMessage(messages.switchM1000),
    },
    {
      name: formatMessage(messages.switchM100),
    },
    {
      name: formatMessage(messages.switchM90),
    },
    {
      name: formatMessage(messages.switchToday),
    },
    {
      name: formatMessage(messages.switchP100),
    },
  ];

  const slideAssetId = activeSlide === Screen.p100 ? activeSlide + 1 + (decision ?? "") : activeSlide + 1;

  const swampImg = `${appConfig.STATIC_ASSETS_URL}/lms/lesson-01/swamp/${slideAssetId}.png`;
  let sideImg: string | null = `${appConfig.STATIC_ASSETS_URL}/lms/lesson-01/side/${slideAssetId}.svg`;
  let expertAudioSrc: string | null = AUDIO_PER_SCREEN[activeSlide];
  let swampLegendItems = SWAMP_LEGEND_PER_SCREEN[activeSlide];

  let showDecisionSlide = false;
  if (activeSlide === Screen.p100) {
    if (!decision) {
      sideImg = null;
      showDecisionSlide = true;
    } else {
      if (decision === "a") {
        expertAudioSrc = AUDIO_PER_SCREEN[Screen.p100a];
        swampLegendItems = SWAMP_LEGEND_PER_SCREEN[Screen.p100a];
      } else {
        expertAudioSrc = AUDIO_PER_SCREEN[Screen.p100b];
        swampLegendItems = SWAMP_LEGEND_PER_SCREEN[Screen.p100b];
      }
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.mainWrapper}>
        {showDecisionSlide ? (
          <div className={styles.decision}>
            <h1>{formatMessage(messages.decisionHeader)}</h1>
            <h3>{formatMessage(messages.decisionSubHeader)}</h3>
            <hr />
            <div className={styles.options}>
              <div>
                <p>{formatMessage(messages.decisionADescription)}</p>
                <ui.Button shape={"round"} theme={"primary"} size={"standard"} onClick={() => setDecision("b")}>
                  {formatMessage(messages.decisionAButton)}
                </ui.Button>
              </div>
              <div>
                <p>{formatMessage(messages.decisionBDescription)}</p>
                <ui.Button shape={"round"} theme={"primary"} size={"standard"} onClick={() => setDecision("a")}>
                  {formatMessage(messages.decisionBButton)}
                </ui.Button>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className={styles.mainContent}>
              <img className={styles.mainImage} src={swampImg} />
            </div>
            <div className={styles.legendWrapper}>
              <AirLegend />
              <div className={styles.swampLegend}>
                <h4>{formatMessage(messages.swampLegendTitle)}</h4>
                <SwampLegend only={swampLegendItems} />
              </div>
            </div>
          </>
        )}
        <div className={styles.controlsWrapper}>
          <SwitchOption items={switchItems} onClick={(index) => setActiveSlide(index)}></SwitchOption>
          {!hideAudio && !showDecisionSlide && (
            <blocks.audioButton
              url={expertAudioSrc ?? ""}
              text={formatMessage(messages.listenToExpert)}
              shape="round"
              theme="primary"
              size="standard"
              icon="cil-sound"
              iconPlacement={"right"}
              disabled={!expertAudioSrc}
            />
          )}
        </div>
      </div>
      {sideImg && (
        <div className={styles.sideWrapper}>
          <h3>{formatMessage(messages.sideHeader)}</h3>
          <div className={styles.sideImage}>
            <img src={sideImg} alt={formatMessage(messages.sideHeader)} />
          </div>
        </div>
      )}
    </div>
  );
};
