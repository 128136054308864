import React, { useCallback, useContext, useState } from "react";

import { AvailableLanguages } from "@src/types";
import { STORAGE_AVAILABLE } from "@src/utils/browser";
import { SESSION_STORAGE_KEY_LANGUAGE } from "@src/constants";

interface ConfigContextPayload {
  lang: AvailableLanguages;
  setLang: (lang: AvailableLanguages) => void;
}

const ConfigContext = React.createContext<ConfigContextPayload>({
  lang: "pl",
  setLang: () => {},
});

export function useConfig(): ConfigContextPayload {
  return useContext(ConfigContext);
}

interface ConfigProviderProps {
  children: React.ReactNode;
}

export const ConfigProvider: React.FC<ConfigProviderProps> = ({ children }) => {
  const [lang, setLang] = useState<AvailableLanguages>(
    (STORAGE_AVAILABLE ? (window.sessionStorage.getItem(SESSION_STORAGE_KEY_LANGUAGE) as AvailableLanguages) : null) ??
      "pl"
  );

  const saveLanguage = useCallback((lang: AvailableLanguages) => {
    setLang(lang);
    if (STORAGE_AVAILABLE) {
      window.sessionStorage.setItem(SESSION_STORAGE_KEY_LANGUAGE, lang);
    }
  }, []);

  const value: ConfigContextPayload = {
    lang,
    setLang: saveLanguage,
  };

  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
};
