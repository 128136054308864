import React from "react";

import styles from "./PhotoView.module.scss";
import classNames from "classnames";
import { WrappedContent } from "@src/components/extensions/layouts/wrapped-content/WrappedContent";

export interface PhotoViewProps {
  imgSrc: string;
  description?: string;
  title?: string;
  imgMode?: "cover" | "contain";
}

export const PhotoView: React.FC<PhotoViewProps> = ({ imgSrc, description, imgMode, title }) => {
  return (
    <WrappedContent title={title} description={description}>
      {
        <div className={classNames(styles.imgWrapper, { [styles.modeCover]: imgMode === "cover" })}>
          <img src={imgSrc} alt={description} />
        </div>
      }
    </WrappedContent>
  );
};
