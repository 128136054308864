import React, { useState } from "react";

import styles from "./Main.module.scss";
import { useIntl } from "react-intl";

import { messages } from "./Main.messages";
import { SelectObject } from "@src/components/extensions/layouts/lesson-05/select-object/SelectObject";
import { SelectSlogan } from "@src/components/extensions/layouts/lesson-05/select-slogan/SelectSlogan";
import { SelectTheme } from "@src/components/extensions/layouts/lesson-05/select-theme/SelectTheme";
import { Setup } from "@src/components/extensions/layouts/lesson-05/setup/Setup";
import { ObjectType, SloganType, ThemeType } from "@src/components/extensions/layouts/lesson-05/types";

const TOTAL_STEP_NR = 3;

enum Screen {
  SelectObject = 0,
  SelectSlogan,
  SelectTheme,
  CurrentSetup,
  Summary,
}

interface Config {
  object: ObjectType;
  theme: ThemeType;
  slogan: SloganType;
}

export const Main: React.FC = () => {
  const { formatMessage } = useIntl();

  const [screen, setScreen] = useState<Screen>(Screen.SelectObject);
  const [config, setConfig] = useState<Partial<Config>>({});
  const stepHeader = (step: number): string =>
    formatMessage(messages.stepHeader, { current: step, total: TOTAL_STEP_NR });

  let header,
    subheader: string | null = null;
  let content: React.ReactNode;

  const toNextScreen = (): void => {
    let next: Screen;
    if (screen === Screen.CurrentSetup || screen === Screen.Summary) {
      if (!config.object) {
        next = Screen.SelectObject;
      } else if (!config.slogan) {
        next = Screen.SelectSlogan;
      } else {
        next = Screen.SelectTheme;
      }
    } else if (screen === Screen.SelectTheme) {
      next = Screen.Summary;
    } else if (config.object && config.theme && config.slogan) {
      next = Screen.Summary;
    } else {
      next = Screen.CurrentSetup;
    }

    setScreen(next);
  };

  const toPreviousStep = (): void => {
    // reset last set config property and jump to the proper setup screen
    const reverseOrder = ["theme", "slogan", "object"] as Array<keyof typeof config>;
    for (const key of reverseOrder) {
      if (config[key]) {
        config[key] = undefined;
        break;
      }
    }

    setConfig({ ...config });
    setTimeout(toNextScreen);
  };

  const handleReset = (): void => {
    setConfig({});
    setScreen(Screen.SelectObject);
  };

  if (screen === Screen.SelectObject) {
    header = stepHeader(1);
    subheader = formatMessage(messages.step1Description);
    content = (
      <SelectObject
        onSelect={(object) => {
          setConfig((config) => ({ ...config, object }));
          toNextScreen();
        }}
      />
    );
  } else if (screen === Screen.SelectSlogan) {
    header = formatMessage(messages.selectSloganHeader);
    subheader = null;
    content = (
      <SelectSlogan
        type={config.object as ObjectType}
        onSelect={(slogan) => {
          setConfig((config) => ({ ...config, slogan }));
          toNextScreen();
        }}
      />
    );
  } else if (screen === Screen.CurrentSetup) {
    header = stepHeader(!config.slogan ? 2 : 3);
    subheader = formatMessage(!config.slogan ? messages.step2Description : messages.step3Description);
    content = (
      <Setup
        object={config.object}
        slogan={config.slogan}
        theme={config.theme}
        onNextStepClick={toNextScreen}
        onResetClick={handleReset}
        onBackClick={toPreviousStep}
      />
    );
  } else if (screen === Screen.SelectTheme) {
    header = formatMessage(messages.selectThemeHeader);
    subheader = null;
    content = (
      <SelectTheme
        type={config.object as ObjectType}
        onSelect={(theme) => {
          setConfig((config) => ({ ...config, theme }));
          toNextScreen();
        }}
      />
    );
  } else if (screen === Screen.Summary) {
    header = formatMessage(messages.summaryHeader);
    subheader = formatMessage(messages.summaryDescription);
    content = (
      <Setup
        object={config.object}
        slogan={config.slogan}
        theme={config.theme}
        onNextStepClick={toNextScreen}
        onResetClick={handleReset}
        onBackClick={toPreviousStep}
      />
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>{header}</div>
      <div className={styles.subheader}>
        {subheader &&
          subheader.split("\n").map((line) => (
            <>
              {line}
              <br />
            </>
          ))}
      </div>
      <div className={styles.contentWrapper}>{content}</div>
    </div>
  );
};
