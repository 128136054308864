import { defineMessages } from "react-intl";

export const messages = defineMessages({
  sideHeader: {
    id: "lms.lesson01.side-header",
  },
  switchM4000: {
    id: "lms.lesson01.switch.m-4000",
  },
  switchM3000: {
    id: "lms.lesson01.switch.m-3000",
  },
  switchM2000: {
    id: "lms.lesson01.switch.m-2000",
  },
  switchM1000: {
    id: "lms.lesson01.switch.m-1000",
  },
  switchM100: {
    id: "lms.lesson01.switch.m-100",
  },
  switchM90: {
    id: "lms.lesson01.switch.m-90",
  },
  switchToday: {
    id: "lms.lesson01.switch.today",
  },
  switchP100: {
    id: "lms.lesson01.switch.p-100",
  },
  swampLegendTitle: {
    id: "lms.lesson01.swamp-legend-title",
  },
  listenToExpert: {
    id: "lms.listen-to-expert",
  },
  decisionHeader: {
    id: "lms.lesson01.decision.header",
  },
  decisionSubHeader: {
    id: "lms.lesson01.decision.subheader",
  },
  decisionADescription: {
    id: "lms.lesson01.decision.a-description",
  },
  decisionAButton: {
    id: "lms.lesson01.decision.a-button",
  },
  decisionBDescription: {
    id: "lms.lesson01.decision.b-description",
  },
  decisionBButton: {
    id: "lms.lesson01.decision.b-button",
  },
});
