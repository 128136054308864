import React, { LegacyRef } from "react";
import { useMeasure } from "react-use";

import styles from "./AnnotatedImage.module.scss";
import { Annotation } from "@src/components/extensions/layouts/lesson-04/components/Annotation";

export interface AnnotatedImageProps {
  imgSrc: string;
  annotations: AnnotationData[];
  imagePositionX?: "left" | "right" | "center";
  imagePositionY?: "top" | "bottom" | "center";
  imageSize: [number, number];
}

interface AnnotationData {
  position: [number, number];
  overrideText?: string;
}

const ANNOTATION_MARGIN_TO_BORDER = 25; //px

export const AnnotatedImage: React.FC<AnnotatedImageProps> = ({
  annotations,
  imgSrc,
  imageSize,
  imagePositionX = "center",
  imagePositionY = "center",
}) => {
  const [ref, boundingBox] = useMeasure();

  // by default we want image to fill full height
  let visibleImageHeight = imageSize[1];
  let scale = boundingBox.height / visibleImageHeight;
  let visibleImageWidth = boundingBox.width / scale;

  const annotationsPositionsInPixels = annotations.map((a) => {
    return [a.position[0] * imageSize[0], a.position[1] * imageSize[1]];
  });

  const scaledMargin = ANNOTATION_MARGIN_TO_BORDER / scale;

  let minX = Math.min(...annotationsPositionsInPixels.map((p) => p[0])) - scaledMargin;
  let maxX = Math.max(...annotationsPositionsInPixels.map((p) => p[0])) + scaledMargin;
  let minY = Math.min(...annotationsPositionsInPixels.map((p) => p[1])) - scaledMargin;
  let maxY = Math.max(...annotationsPositionsInPixels.map((p) => p[1])) + scaledMargin;

  if (annotations.length === 0) {
    //fallback when no annotation are given - point to center of the image
    maxX = minX = 0.5 * imageSize[0];
    maxY = minY = 0.5 * imageSize[1];
  }

  const annotationBoundingBox = {
    top: minY,
    left: minX,
    width: maxX - minX,
    height: maxY - minY,
  };

  if (visibleImageWidth < annotationBoundingBox.width) {
    scale = boundingBox.width / annotationBoundingBox.width;
    visibleImageWidth = annotationBoundingBox.width;
  } else if (visibleImageWidth > imageSize[0]) {
    //attempt scale up to fill width
    scale = boundingBox.width / imageSize[0];
    visibleImageWidth = imageSize[0];
    visibleImageHeight = boundingBox.height / scale;

    //further enlarging exceedes annotations bounding box vertically - no more increasing width
    if (visibleImageHeight < annotationBoundingBox.height) {
      visibleImageHeight = annotationBoundingBox.height;
      scale = boundingBox.height / annotationBoundingBox.height;
    }
  }

  const imageHeight = scale * imageSize[1];
  const imageWidth = scale * imageSize[0];

  let deltaX = 0;
  let deltaY = 0;
  if (imagePositionX === "left") {
    deltaX = Math.max(0, maxX - visibleImageWidth) * scale;
  } else if (imagePositionX === "right") {
    deltaX = Math.min(minX, imageSize[0] - visibleImageWidth) * scale;
  } else if (imagePositionX === "center") {
    deltaX = (minX / (imageSize[0] - annotationBoundingBox.width)) * (imageSize[0] - visibleImageWidth) * scale;
  }

  if (imagePositionY === "top") {
    deltaY = Math.max(0, maxY - visibleImageHeight) * scale;
  } else if (imagePositionY === "bottom") {
    deltaY = Math.min(minY, imageSize[1] - visibleImageHeight) * scale;
  } else if (imagePositionY === "center") {
    deltaY = (minY / (imageSize[1] - annotationBoundingBox.height)) * (imageSize[1] - visibleImageHeight) * scale;
  }

  return (
    <div className={styles.root} ref={ref as LegacyRef<HTMLDivElement>}>
      <div className={styles.imageWrapper} style={{ height: imageHeight + "px", width: "100%" }}>
        <img src={imgSrc} alt={"image"} style={{ marginLeft: -deltaX + "px", marginTop: -deltaY + "px" }} />
        {annotations.map((a, index) => {
          const top = a.position[1] * imageHeight - deltaY + "px";
          const left = a.position[0] * imageWidth - deltaX + "px";

          return (
            <div className={styles.annotationWrapper} key={index} style={{ top, left }}>
              <Annotation text={a.overrideText ?? index + 1 + ""} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
