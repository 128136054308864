import React, { useState } from "react";
import styles from "./Main.module.scss";
import { Slider } from "@src/components/extensions/layouts/lesson-02/slider/Slider";
import { useIntl } from "react-intl";
import { messages } from "./Main.messages";

import area1 from "@src/assets/lms/lesson-02/area1.jpg";
import area2 from "@src/assets/lms/lesson-02/area2.jpg";
import area3 from "@src/assets/lms/lesson-02/area3.jpg";
import area4 from "@src/assets/lms/lesson-02/area4.jpg";

import main1 from "@src/assets/lms/lesson-02/main1.png";
import main2 from "@src/assets/lms/lesson-02/main2.png";
import main3 from "@src/assets/lms/lesson-02/main3.png";
import main4 from "@src/assets/lms/lesson-02/main4.png";
import { WaterParams } from "@src/components/extensions/layouts/lesson-02/water-params/WaterParams";
import { useBlocks } from "@jelly/lesson";

const areaImgSrcPerStep = [area1, area2, area3, area4];
const mainImgSrcPerStep = [main1, main2, main3, main4];
// on the current designs all the water params at given stage have the same value,
// so as of now we keep is as single scalar
const waterParamsPerStep = [1, 2, 3, 5];

export interface MainProps {
  stepRiverSoundSrc: [string, string, string, string];
  stepExpertSoundSrc: [string, string, string, string];
}
export const Main: React.FC<MainProps> = ({ stepRiverSoundSrc, stepExpertSoundSrc }) => {
  const [step, setStep] = useState<number>(0);

  const { formatMessage } = useIntl();

  const descriptionImgProps = {
    src: areaImgSrcPerStep[step],
  };

  const mainImgProps = {
    src: mainImgSrcPerStep[step],
  };

  const presentedStepNr = step + 1;

  const Blocks = useBlocks();

  return (
    <div className={styles.root}>
      <div className={styles.navigate}>
        <Slider activeStep={presentedStepNr} onStepClick={(nextStep) => setStep(nextStep - 1)} />
      </div>
      <div className={styles.main}>
        <h3>{formatMessage(messages[`headerStep${presentedStepNr}` as keyof typeof messages])}</h3>
        <div className={styles.imgWrapper}>
          <img
            {...mainImgProps}
            alt={formatMessage(messages[`headerStep${presentedStepNr}` as keyof typeof messages])}
          />
        </div>
        <div className={styles.waterParams}>
          <WaterParams
            type={"drop"}
            filledSteps={waterParamsPerStep[step]}
            title={formatMessage(messages.parameterPurity)}
          />
          <WaterParams
            type={"moth"}
            filledSteps={waterParamsPerStep[step]}
            title={formatMessage(messages.parameterBio)}
          />
          <WaterParams
            type={"pill"}
            filledSteps={waterParamsPerStep[step]}
            title={formatMessage(messages.parameterRetention)}
          />
        </div>
        <div className={styles.audioRow}>
          {stepRiverSoundSrc[step] && (
            <Blocks.audioButton
              url={stepRiverSoundSrc[step]}
              text={formatMessage(messages.listenToRiver)}
              shape="round"
              theme="primary"
              size="standard"
              icon="cil-sound"
              iconPlacement={"right"}
            />
          )}

          {stepExpertSoundSrc[step] && (
            <Blocks.audioButton
              url={stepExpertSoundSrc[step]}
              text={formatMessage(messages.listenToExpert)}
              shape="round"
              theme="primary"
              size="standard"
              icon="cil-sound"
              iconPlacement={"right"}
            />
          )}
        </div>
      </div>
      <div className={styles.explanation}>
        <h3>{formatMessage(messages.sidebarHeader)}</h3>
        <div className={styles.riverImgWrapper}>
          <img {...descriptionImgProps} alt={formatMessage(messages.sidebarHeader)} />
        </div>
        <p>{formatMessage(messages[`descriptionStep${presentedStepNr}` as keyof typeof messages])}</p>
      </div>
    </div>
  );
};
