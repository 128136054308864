import React from "react";

import { useUi } from "@jelly/lesson";
import { useIntl } from "react-intl";
import { messages } from "@src/components/extensions/layouts/lesson-04/iceland-postcard/IcelandPostcard.messages";

import styles from "./InfoModal.module.scss";

export interface InfoModalProps {
  visible: boolean;
  onClose: () => void;
}

export const InfoModal: React.FC<InfoModalProps> = ({ visible, onClose }) => {
  const ui = useUi();

  return (
    <ui.Modal visible={visible} onClose={onClose} className={styles.root}>
      <InfoModalContent />
    </ui.Modal>
  );
};

const InfoModalContent: React.FC = () => {
  const ui = useUi();
  const { formatMessage } = useIntl();

  return (
    <div className={styles.rootContent}>
      <h1 className={styles.title}>{formatMessage(messages.infoModalTitle)}</h1>
      <p className={styles.subtitle}>{formatMessage(messages.infoModalSubtitle)}</p>

      <ul className={styles.list}>
        {formatMessage(messages.infoModalContent)
          .split("\n")
          .map((line, index) => (
            <li key={index}>{line}</li>
          ))}
      </ul>
      <p className={styles.info}>{formatMessage(messages.infoModalInfo)}</p>

      <div className={styles.buttonWrapper}>
        <ui.Button
          shape={"round"}
          size={"standard"}
          theme={"info"}
          onClick={() => window.open("https://noisefromiceland.com/")}
        >
          {formatMessage(messages.infoModalSourceWebsiteButton)}
        </ui.Button>
      </div>
    </div>
  );
};
