import { defineMessages } from "react-intl";

export const messages = defineMessages({
  introTitle: {
    id: "lms.lesson04.intro.title",
  },
  introLeftTitle: {
    id: "lms.lesson04.intro.title-left",
  },
  introLeftDescription: {
    id: "lms.lesson04.intro.description-left",
  },
  introRightTitle: {
    id: "lms.lesson04.intro.title-right",
  },
  introRightDescription: {
    id: "lms.lesson04.intro.description-right",
  },
});
