import * as appConfig from "appConfig";

interface GenericSample {
  color: "blue" | "red" | "yellow" | "navy" | "green" | "orange";
  nameMessage: { id: string };
  src: string;
}

import { messages } from "./OdraPostcard.messages";

const BASE_URL = `${appConfig.STATIC_ASSETS_URL}/lms/lesson-04/odra/postcard/audio`;
export const availableSamples = [
  {
    color: "navy",
    nameMessage: messages.samplePiano,
    src: `${BASE_URL}/piano.mp3`,
  },
  {
    color: "blue",
    nameMessage: messages.sampleSynthBass,
    src: `${BASE_URL}/bass.mp3`,
  },
  {
    color: "green",
    nameMessage: messages.sampleSwamp,
    src: `${BASE_URL}/komary-zaby.mp3`,
  },
  {
    color: "green",
    nameMessage: messages.sampleCuckoo,
    src: `${BASE_URL}/kukulka-zuraw-kaczka.mp3`,
  },
  {
    color: "red",
    nameMessage: messages.sampleDrums,
    src: `${BASE_URL}/perkusjonalia-szum.mp3`,
  },
  {
    color: "green",
    nameMessage: messages.sampleOwl,
    src: `${BASE_URL}/puszczyk-dzieciol-bielik.mp3`,
  },
  {
    color: "yellow",
    nameMessage: messages.sampleWater,
    src: `${BASE_URL}/rytm-woda-i-liny-stoczniowe.mp3`,
  },
  {
    color: "blue",
    nameMessage: messages.sampleInstruments,
    src: `${BASE_URL}/smyczkowe.mp3`,
  },
  {
    color: "orange",
    nameMessage: messages.sampleSynth,
    src: `${BASE_URL}/syntezator.mp3`,
  },
] as const satisfies readonly GenericSample[];

export type Sample = (typeof availableSamples)[number];
