import React from "react";

import styles from "./TilesLibrary.module.scss";

import { availableSamples, Sample } from "../samples";
import { DraggableTile } from "./DraggableTile";

export interface TilesLibraryProps {}

const GRID_WIDTH = 6;
export const TilesLibrary: React.FC<TilesLibraryProps> = () => {
  const groups = availableSamples
    .reduce((previousValue, currentValue: Sample) => {
      let collection = previousValue.get(currentValue.group);
      if (!collection) {
        collection = [];
        previousValue.set(currentValue.group, collection);
      }

      collection.push(currentValue);

      return previousValue;
    }, new Map<string, Sample[]>())
    .values();

  return (
    <div className={styles.root} style={{ gridTemplateColumns: `repeat(${GRID_WIDTH}, 1fr` }}>
      {Array.from(groups).map((group) => {
        return group.map((sample, index) => {
          const start = 1 + (-1 + index) * sample.length + GRID_WIDTH / 2;
          const end = start + sample.length;
          return (
            <div className={styles.tile} style={{ gridColumnStart: start, gridColumnEnd: end }} key={index}>
              <DraggableTile sample={sample} />
            </div>
          );
        });
      })}
    </div>
  );
};
