import { messages } from "./Main.messages";
import { useIntl } from "react-intl";

import colorBar1 from "@src/assets/lms/lesson-05/theme/color1.png";
import colorBar2 from "@src/assets/lms/lesson-05/theme/color2.png";
import colorBar3 from "@src/assets/lms/lesson-05/theme/color3.png";
import colorBar4 from "@src/assets/lms/lesson-05/theme/color4.png";

export function useSloganName(): (id: string) => string {
  const { formatMessage } = useIntl();

  return (id: string) => {
    return formatMessage(messages[`slogan${firstLetterUp(id)}` as keyof typeof messages]);
  };
}

export function useObjectName(): (id: string) => string {
  const { formatMessage } = useIntl();

  return (id: string) => {
    return formatMessage(messages[`object${firstLetterUp(id)}` as keyof typeof messages]);
  };
}

export function getThemeImgSrc(id: string): string {
  const srcArr = [colorBar1, colorBar2, colorBar3, colorBar4];

  const nr = parseInt(id);
  return srcArr[nr] ?? "";
}

function firstLetterUp(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
