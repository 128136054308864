import React, { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { useUi } from "@jelly/lesson";
import { messages } from "@src/components/extensions/layouts/lesson-04/iceland-postcard/IcelandPostcard.messages";
import { Player } from "@src/components/extensions/layouts/lesson-04/types";

import styles from "./PlayerControls.module.scss";

interface ControlsWrapperProps {
  onInfoOpen: () => void;
  onReset: () => void;
  player: Player;
  instruction: string;
}

export const PlayerControls: React.FC<ControlsWrapperProps> = ({ onInfoOpen, player, onReset, instruction }) => {
  const { formatMessage } = useIntl();
  const ui = useUi();

  const handleReset = useCallback(() => {
    onReset();
    player.stop();
  }, [onReset, player]);

  useEffect(() => {
    player.load();

    return () => {
      player.stop();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.root}>
      <div>
        <p>
          <ui.MathSvg formatter="html">{instruction}</ui.MathSvg>
        </p>
        <ui.Button
          theme={"secondary"}
          shape={"round"}
          size={"standard"}
          icon={"cil-info-circle"}
          iconPlacement={"right"}
          onClick={onInfoOpen}
        >
          {formatMessage(messages.moreButton)}
        </ui.Button>
      </div>
      <div>
        <ui.Button
          theme={"primary"}
          shape={"round"}
          size={"standard"}
          icon={player.playing ? "cil-media-stop" : "cil-media-play"}
          iconPlacement={"right"}
          disabled={!player.playing && (!player.loaded || player.empty)}
          active={player.playing}
          onClick={player.playing ? player.stop : player.play}
        >
          {player.playing ? formatMessage(messages.stopButton) : formatMessage(messages.playButton)}
        </ui.Button>
        <ui.Button
          theme={"secondary"}
          shape={"round"}
          size={"standard"}
          icon={"cil-restore"}
          onClick={handleReset}
          iconPlacement={"right"}
        >
          {formatMessage(messages.resetButton)}
        </ui.Button>
      </div>
      <div>
        <ui.Button
          theme={"primary"}
          shape={"round"}
          size={"standard"}
          icon={"cil-arrow-bottom"}
          iconPlacement={"right"}
          onClick={player.download}
          disabled={player.empty}
        >
          {formatMessage(messages.downloadButton)}
        </ui.Button>
      </div>
    </div>
  );
};
