import React from "react";
import { useDrop } from "react-dnd";
import {
  DND_TYPE_SAMPLE,
  usePostcardModel,
} from "@src/components/extensions/layouts/lesson-04/iceland-postcard/PostcardModel";
import { SampleTile } from "@src/components/extensions/layouts/lesson-04/components/SampleTile";

import styles from "./EmptySlot.module.scss";
import classnames from "classnames";
import { Sample } from "@src/components/extensions/layouts/lesson-04/iceland-postcard/samples";

export interface EmptySlotProps {
  x: number;
  y: number;
}

export const EmptySlot: React.FC<EmptySlotProps> = ({ x, y }) => {
  const { canAddToXY, addToXY } = usePostcardModel();

  const [{ canDrop, isOver, isDragging, sample }, drop] = useDrop(
    {
      accept: DND_TYPE_SAMPLE,
      canDrop: (item: Sample) => canAddToXY(item, { x, y }),
      drop: (item) => {
        addToXY(item, { x, y });
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        isDragging: !!monitor.getItem(),
        sample: monitor.getItem(),
      }),
    },
    [canAddToXY, addToXY]
  );

  return (
    <div
      className={classnames(styles.root, {
        [styles.disabled]: !canDrop && isDragging,
        [styles.over]: isOver,
      })}
      ref={drop}
    >
      <div>
        <SampleTile theme={"empty"} />
        {isOver && (
          <div
            className={styles.overlay}
            style={{ width: `calc(${sample.length} * 100% + (${sample.length - 1} * 5px` }}
          >
            <SampleTile theme={sample.color} />
          </div>
        )}
      </div>
    </div>
  );
};
