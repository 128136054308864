import React from "react";

export interface Option {
  id: string;
  text?: string;
  img?: string;
  component?: React.ReactNode;
}

import styles from "./Options.module.scss";
import classnames from "classnames";

interface OptionsProps {
  options: Option[];
  itemWidth?: string;
  onSelect: (id: string) => void;
  additionalClassName?: string;
}
export const Options: React.FC<OptionsProps> = ({ options, itemWidth = "33%", onSelect, additionalClassName }) => {
  return (
    <div
      className={classnames(styles.root, {
        [additionalClassName ?? ""]: additionalClassName != undefined,
      })}
    >
      {options.map((option) => (
        <div key={option.id} className={styles.step} style={{ width: itemWidth }} onClick={() => onSelect(option.id)}>
          {option.img && (
            <div>
              <img src={option.img} alt={option.id} />
            </div>
          )}
          {option.component ? option.component : null}
          {option.text && <p>{option.text}</p>}
        </div>
      ))}
    </div>
  );
};
