// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PiDbALdR6TJqCMiBxmTe{width:100%;height:100%;position:relative}.PiDbALdR6TJqCMiBxmTe>*{width:100%;height:100%;border:1px dashed rgba(0,0,0,0)}.PiDbALdR6TJqCMiBxmTe.QiXYTH90G8Is7l5C77UF .QAFDviG1byItpz_vubWP{opacity:.2}.PiDbALdR6TJqCMiBxmTe.vLT0k6FR3lJJw9KczFhh .QAFDviG1byItpz_vubWP>*:first-child{border:1px dashed var(--color-515-blekitny)}.PiDbALdR6TJqCMiBxmTe .QAFDviG1byItpz_vubWP{position:absolute;top:0;left:0;z-index:100;box-shadow:none}.PiDbALdR6TJqCMiBxmTe .QAFDviG1byItpz_vubWP>*{width:100%;height:100%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary-dark": `#e73b05`,
	"primary": `#ff6040`,
	"primary-light": `#ffe4df`,
	"primary-extra-light": `#fff2f0`,
	"secondary-dark": `#8292b1`,
	"secondary": `#99a8c6`,
	"secondary-light": `#d0dae9`,
	"secondary-extra-light": `#eef4fc`,
	"default-dark": `#898ca7`,
	"default": `#b8baca`,
	"default-light": `#ecedf5`,
	"default-extra-light": `#f8f8f8`,
	"warning-dark": `#d96800`,
	"warning": `#fbab00`,
	"warning-light": `#ffeba3`,
	"warning-extra-light": `#fff5d1`,
	"info-dark": `#0a82c6`,
	"info": `#2eb6ea`,
	"info-light": `#beebfc`,
	"info-extra-light": `#dff5fe`,
	"success-dark": `#069941`,
	"success": `#3aaa35`,
	"success-light": `#c6f1c3`,
	"success-extra-light": `#e3f9e1`,
	"danger-dark": `#cd0101`,
	"danger": `#ff4141`,
	"danger-light": `#ffcece`,
	"danger-extra-light": `#ffe7e7`,
	"text-primary": `#131a4f`,
	"text-secondary": `#8292b1`,
	"shadow-25": `rgba(184,186,202,.25)`,
	"shadow-50": `rgba(184,186,202,.5)`,
	"root": `PiDbALdR6TJqCMiBxmTe`,
	"disabled": `QiXYTH90G8Is7l5C77UF`,
	"overlay": `QAFDviG1byItpz_vubWP`,
	"over": `vLT0k6FR3lJJw9KczFhh`
};
export default ___CSS_LOADER_EXPORT___;
