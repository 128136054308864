import React from "react";
import { IntlProvider as ReactIntlProvider } from "react-intl";
import { AvailableLanguages } from "@src/types";
import { plMessages } from "@src/intl/pl";
import { enMessages } from "@src/intl/en";

interface IntlProviderProps {
  locale: AvailableLanguages;
  children: React.ReactNode;
}

export const IntlProvider: React.FC<IntlProviderProps> = ({ locale, children }) => {
  const i18nMessages: Record<AvailableLanguages, Record<string, string>> = {
    pl: plMessages,
    en: enMessages,
  };

  return (
    <ReactIntlProvider locale={locale} messages={i18nMessages[locale]}>
      {children}
    </ReactIntlProvider>
  );
};
