// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TmaoVnWxqP7V9DSQCQbA{display:flex;flex-direction:column;height:100%}.TmaoVnWxqP7V9DSQCQbA h3{flex:0 0 auto;margin:0 0 15px;text-align:center;font-size:16px;font-style:normal;font-weight:700;line-height:116.5%}.TmaoVnWxqP7V9DSQCQbA .juOjypKin8q5kmRDlbtA{flex:1 1 auto;align-content:center;display:grid;width:100%;height:100%;grid-gap:10px 14px;grid-template-columns:max-content auto;margin-bottom:15px}.TmaoVnWxqP7V9DSQCQbA .juOjypKin8q5kmRDlbtA .dw3fbV7cLaW4q3SGV8Bq{cursor:pointer}.TmaoVnWxqP7V9DSQCQbA .juOjypKin8q5kmRDlbtA .dw3fbV7cLaW4q3SGV8Bq:hover{opacity:.5}.TmaoVnWxqP7V9DSQCQbA .juOjypKin8q5kmRDlbtA .s4mFDccN7h9wnQHeJ5Kg i{color:var(--color-515-granat)}.TmaoVnWxqP7V9DSQCQbA .juOjypKin8q5kmRDlbtA .Q0R8ntjgoB3usD7swQya{position:relative}.TmaoVnWxqP7V9DSQCQbA .juOjypKin8q5kmRDlbtA .Q0R8ntjgoB3usD7swQya>*{width:100%;transition:background-color .2s,color .2s}.TmaoVnWxqP7V9DSQCQbA .RyFRJ4lZPH7P8_JTyDlZ{display:grid;grid-template-columns:max-content auto;grid-gap:14px;width:115px;justify-content:stretch;align-items:center}.TmaoVnWxqP7V9DSQCQbA .SzipO_TDsxOvhCJVYT0p{flex:0 0 auto;padding:15px 0 0;border-top:1px solid var(--color-515-zimnyszary)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary-dark": `#e73b05`,
	"primary": `#ff6040`,
	"primary-light": `#ffe4df`,
	"primary-extra-light": `#fff2f0`,
	"secondary-dark": `#8292b1`,
	"secondary": `#99a8c6`,
	"secondary-light": `#d0dae9`,
	"secondary-extra-light": `#eef4fc`,
	"default-dark": `#898ca7`,
	"default": `#b8baca`,
	"default-light": `#ecedf5`,
	"default-extra-light": `#f8f8f8`,
	"warning-dark": `#d96800`,
	"warning": `#fbab00`,
	"warning-light": `#ffeba3`,
	"warning-extra-light": `#fff5d1`,
	"info-dark": `#0a82c6`,
	"info": `#2eb6ea`,
	"info-light": `#beebfc`,
	"info-extra-light": `#dff5fe`,
	"success-dark": `#069941`,
	"success": `#3aaa35`,
	"success-light": `#c6f1c3`,
	"success-extra-light": `#e3f9e1`,
	"danger-dark": `#cd0101`,
	"danger": `#ff4141`,
	"danger-light": `#ffcece`,
	"danger-extra-light": `#ffe7e7`,
	"text-primary": `#131a4f`,
	"text-secondary": `#8292b1`,
	"shadow-25": `rgba(184,186,202,.25)`,
	"shadow-50": `rgba(184,186,202,.5)`,
	"root": `TmaoVnWxqP7V9DSQCQbA`,
	"samples": `juOjypKin8q5kmRDlbtA`,
	"muteButton": `dw3fbV7cLaW4q3SGV8Bq`,
	"active": `s4mFDccN7h9wnQHeJ5Kg`,
	"sampleWrapper": `Q0R8ntjgoB3usD7swQya`,
	"switchWrapper": `RyFRJ4lZPH7P8_JTyDlZ`,
	"controls": `SzipO_TDsxOvhCJVYT0p`
};
export default ___CSS_LOADER_EXPORT___;
