import { defineMessages } from "react-intl";

export const messages = defineMessages({
  headerStep1: {
    id: "lms.lesson02.step1.header",
  },
  headerStep2: {
    id: "lms.lesson02.step2.header",
  },
  headerStep3: {
    id: "lms.lesson02.step3.header",
  },
  headerStep4: {
    id: "lms.lesson02.step4.header",
  },
  descriptionStep1: {
    id: "lms.lesson02.step1.description",
  },
  descriptionStep2: {
    id: "lms.lesson02.step2.description",
  },
  descriptionStep3: {
    id: "lms.lesson02.step3.description",
  },
  descriptionStep4: {
    id: "lms.lesson02.step4.description",
  },
  sidebarHeader: {
    id: "lms.lesson02.sidebar-header",
  },
  listenToExpert: {
    id: "lms.listen-to-expert",
  },
  listenToRiver: {
    id: "lms.listen-to-river",
  },
  parameterPurity: {
    id: "lms.lesson02.parameter-purity",
  },
  parameterBio: {
    id: "lms.lesson02.parameter-bio",
  },
  parameterRetention: {
    id: "lms.lesson02.parameter-retention",
  },
});
