// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TPcyy3fPpN2dPANbCF6W{width:100%;height:100%;display:flex;flex-direction:column}.TPcyy3fPpN2dPANbCF6W .UziwgO_E7wCmtdoJcXPX{color:var(--color-515-granat);text-align:center;font-size:16px;font-style:normal;font-weight:700;line-height:116.5%;margin:0 0 10px}.TPcyy3fPpN2dPANbCF6W .ELVMZfUUwvdd9MR88g8Y{border-radius:10px;overflow:hidden;position:relative;flex:1 0 0;background-repeat:no-repeat;background-position:center}.TPcyy3fPpN2dPANbCF6W .ELVMZfUUwvdd9MR88g8Y .yAV9fkRL4S8sVakHHIKn{position:absolute;max-width:100%;max-height:100%;top:0;left:0;right:0;bottom:0;margin:auto}.TPcyy3fPpN2dPANbCF6W .Wri9ya4OE9teLFE_ntxd{text-align:center;flex:0 0 auto;padding:10px;font-size:14px;max-width:1100px;margin:0 auto}.TPcyy3fPpN2dPANbCF6W .Wri9ya4OE9teLFE_ntxd a{color:inherit;font-weight:bold}.TPcyy3fPpN2dPANbCF6W .Wri9ya4OE9teLFE_ntxd a:hover{color:var(--color-515-blekitny)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary-dark": `#e73b05`,
	"primary": `#ff6040`,
	"primary-light": `#ffe4df`,
	"primary-extra-light": `#fff2f0`,
	"secondary-dark": `#8292b1`,
	"secondary": `#99a8c6`,
	"secondary-light": `#d0dae9`,
	"secondary-extra-light": `#eef4fc`,
	"default-dark": `#898ca7`,
	"default": `#b8baca`,
	"default-light": `#ecedf5`,
	"default-extra-light": `#f8f8f8`,
	"warning-dark": `#d96800`,
	"warning": `#fbab00`,
	"warning-light": `#ffeba3`,
	"warning-extra-light": `#fff5d1`,
	"info-dark": `#0a82c6`,
	"info": `#2eb6ea`,
	"info-light": `#beebfc`,
	"info-extra-light": `#dff5fe`,
	"success-dark": `#069941`,
	"success": `#3aaa35`,
	"success-light": `#c6f1c3`,
	"success-extra-light": `#e3f9e1`,
	"danger-dark": `#cd0101`,
	"danger": `#ff4141`,
	"danger-light": `#ffcece`,
	"danger-extra-light": `#ffe7e7`,
	"text-primary": `#131a4f`,
	"text-secondary": `#8292b1`,
	"shadow-25": `rgba(184,186,202,.25)`,
	"shadow-50": `rgba(184,186,202,.5)`,
	"root": `TPcyy3fPpN2dPANbCF6W`,
	"header": `UziwgO_E7wCmtdoJcXPX`,
	"contentWrapper": `ELVMZfUUwvdd9MR88g8Y`,
	"content": `yAV9fkRL4S8sVakHHIKn`,
	"description": `Wri9ya4OE9teLFE_ntxd`
};
export default ___CSS_LOADER_EXPORT___;
