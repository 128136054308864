import { MathSvg as CoreMathSvg } from "@jelly/ui";
import React from "react";

import styles from "./MathSvg.module.scss";
export const MathSvg: React.FC<React.ComponentProps<typeof CoreMathSvg>> = (props) => {
  return (
    <div className={styles.root}>
      <CoreMathSvg {...props} />
    </div>
  );
};
