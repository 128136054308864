import React from "react";

import styles from "./Board.module.scss";
import { EmptySlot } from "@src/components/extensions/layouts/lesson-04/iceland-postcard/components/EmptySlot";
import {
  sampleOverlappingWith,
  TRACK_WIDTH,
  TRACKS_NR,
} from "@src/components/extensions/layouts/lesson-04/iceland-postcard/helper";
import { SampleOnTrack } from "../types";
import { FilledSlot } from "@src/components/extensions/layouts/lesson-04/iceland-postcard/components/FilledSlot";
import classnames from "classnames";

const BOARD_SIZE = [TRACK_WIDTH, TRACKS_NR];

export interface BoardProps {
  samples: SampleOnTrack[];
  highlightTact?: number;
}

export const Board: React.FC<BoardProps> = ({ samples, highlightTact }) => {
  return (
    <div className={styles.root} style={{ gridTemplateColumns: `repeat(${BOARD_SIZE[0]}, 1fr)` }}>
      {highlightTact !== undefined && (
        <div
          className={styles.tactHighlight}
          style={{ left: `calc((100% - 25px) / 6 * ${highlightTact} + 5px * ${highlightTact})` }}
        />
      )}
      {Array(BOARD_SIZE[1])
        .fill(null)
        .flatMap((_, y) => {
          const samplesInRow = samples.filter((entry) => entry.pos.y === y);
          return Array(BOARD_SIZE[0])
            .fill(null)
            .map((_, x) => {
              const sample = samplesInRow.find(sampleOverlappingWith({ sample: { length: 1 }, pos: { x, y } }));
              const sampleStartsHere = sample?.pos.x === x;

              const className = classnames(styles.boardItem, {
                [styles.highlight]: highlightTact === x,
              });

              if (!sample) {
                return (
                  <div className={className} key={x + ":" + y} style={{ gridColumn: `${x + 1} / ${x + 1}` }}>
                    <EmptySlot x={x} y={y} />
                  </div>
                );
              }

              if (sampleStartsHere) {
                return (
                  <div
                    className={className}
                    key={x + ":" + y}
                    style={{ gridColumn: `${x + 1} / ${x + sample.sample.length + 1}` }}
                  >
                    <FilledSlot sample={sample} />
                  </div>
                );
              }

              return null;
            })
            .filter((e) => !!e);
        })}
    </div>
  );
};
