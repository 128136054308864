import { defineMessages } from "react-intl";

export const messages = defineMessages({
  o2: {
    id: "lms.lesson01.legend.o2",
  },
  co2: {
    id: "lms.lesson01.legend.co2",
  },
  peat: {
    id: "lms.lesson01.legend.peat",
  },
  vegetation: {
    id: "lms.lesson01.legend.vegetation",
  },
  mineral: {
    id: "lms.lesson01.legend.mineral",
  },
  water: {
    id: "lms.lesson01.legend.water",
  },
  rot: {
    id: "lms.lesson01.legend.rot",
  },
  vegetationTop: {
    id: "lms.lesson01.legend.vegetation-top",
  },
  emission: {
    id: "lms.lesson01.legend.emission",
  },
});
