import React from "react";
import { useIntl } from "react-intl";
import { useBlocks, useUi } from "@jelly/lesson";
import { messages } from "./ExperimentWelcome.messages";

interface ExperimentWelcomeProps {
  title: string;
  description: string;
  expertAudioSrc: string;
  imgSrc: string;
  leftBlockWidth?: string;
}

import styles from "./ExperimentWelcome.module.scss";

export const ExperimentWelcome: React.FC<ExperimentWelcomeProps> = (props) => {
  const { title, description, expertAudioSrc, imgSrc, leftBlockWidth } = props;

  const Blocks = useBlocks();
  const Ui = useUi();
  const { formatMessage } = useIntl();

  return (
    <div className={styles.root}>
      <div className={styles.leftBlock} style={{ width: leftBlockWidth ? leftBlockWidth : undefined }}>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.description}>
          <Ui.Scrollbars>
            {description.split("\n").map((line) => (
              <>
                {line}
                <br />
              </>
            ))}
          </Ui.Scrollbars>
        </p>
        {expertAudioSrc && (
          <Blocks.audioButton
            url={expertAudioSrc}
            text={formatMessage(messages.listenToExpert)}
            shape="round"
            theme="primary"
            size="standard"
            icon="cil-sound"
            iconPlacement={"right"}
          />
        )}
      </div>
      <div className={styles.rightBlock}>
        <div className={styles.imgWrapper}>
          <img src={imgSrc} alt={"visualisation"} />
        </div>
      </div>
    </div>
  );
};
