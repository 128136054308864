import React from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import { Helmet } from "react-helmet";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useUi } from "@jelly/lesson";
import { JellyProvider } from "@src/components/JellyProvider";
import { ConfigProvider, useConfig } from "@src/components/providers/ConfigProvider";
import { IntlProvider } from "@src/components/providers/IntlProvider";

import appConfig from "appConfig";

// import NotFoundPage from "../pages/NotFoundPage";
// import usePageTracking from "../hooks/usePageTracking";
// import AudioPermission from "./AudioPermission/AudioPermission";

import "@src/assets/styles/index.scss";

const LessonPage = React.lazy(() => import("../pages/lesson/Lesson"));
const LandingPage = React.lazy(() => import("../pages/landing/Main"));
const TeamPage = React.lazy(() => import("../pages/team/Team"));

const queryClient = new QueryClient();

const App: React.FC = () => {
  /* eslint-disable no-console */
  console.log("BUILD_METADATA", BUILD_METADATA);
  console.log("DEPLOYMENT_ENV", appConfig.DEPLOYMENT_ENV);

  return (
    <ConfigProvider>
      <JellyProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Helmet defaultTitle={"Pod prąd"} titleTemplate={`%s - ${"Pod prąd"}`} />
            {/*<AudioPermission />*/}
            <AppContent />
          </BrowserRouter>
        </QueryClientProvider>
      </JellyProvider>
    </ConfigProvider>
  );
};

const AppContent: React.FC = () => {
  const config = useConfig();

  return (
    <IntlProvider locale={config.lang}>
      <React.Suspense fallback={<Fallback />}>
        <Routes>
          <Route path="/lesson/:lessonId" element={<LessonPage />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/team" element={<TeamPage />} />
          {/*<Route component={NotFoundPage} />*/}
        </Routes>
      </React.Suspense>
    </IntlProvider>
  );
};

const Fallback = (): JSX.Element => {
  const { Spinner } = useUi();

  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}>
      <Spinner />
    </div>
  );
};

export default App;
