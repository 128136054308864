import * as appConfig from "appConfig";

interface GenericSample {
  group: string;
  color: "blue" | "red" | "yellow" | "navy" | "green";
  nameMessage: { id: string };
  nr: number;
  length: number;
  src: string;
}

import { messages } from "./IcelandPostcard.messages";

const BASE_URL = `${appConfig.STATIC_ASSETS_URL}/lms/lesson-04/iceland/postcard/audio`;
export const availableSamples = [
  {
    color: "yellow",
    group: "background",
    nameMessage: messages.sampleSea,
    nr: 1,
    length: 3,
    src: `${BASE_URL}/ambient-1.mp3`,
  },
  {
    color: "yellow",
    group: "background",
    nameMessage: messages.sampleIce,
    nr: 2,
    length: 3,
    src: `${BASE_URL}/ambient-2.mp3`,
  },
  {
    color: "blue",
    group: "synth",
    nameMessage: messages.sampleSynthBass,
    nr: 1,
    length: 2,
    src: `${BASE_URL}/synth-bass.mp3`,
  },
  {
    color: "blue",
    group: "synth",
    nameMessage: messages.sampleSynth,
    nr: 2,
    length: 2,
    src: `${BASE_URL}/synth-piano.mp3`,
  },
  {
    color: "navy",
    group: "piano",
    nameMessage: messages.samplePianoLeftHand,
    nr: 1,
    length: 2,
    src: `${BASE_URL}/piano-low.mp3`,
  },
  {
    color: "navy",
    group: "piano",
    nameMessage: messages.samplePianoRightHand,
    nr: 2,
    length: 2,
    src: `${BASE_URL}/piano-hi.mp3`,
  },
  {
    color: "red",
    group: "drums",
    nameMessage: messages.sampleDrumsLow,
    nr: 1,
    length: 2,
    src: `${BASE_URL}/drums-1.mp3`,
  },
  {
    color: "red",
    group: "drums",
    nameMessage: messages.sampleDrumsHigh,
    nr: 2,
    length: 2,
    src: `${BASE_URL}/drums-2.mp3`,
  },
  {
    color: "green",
    group: "sing",
    nameMessage: messages.sampleSing1,
    nr: 1,
    length: 2,
    src: `${BASE_URL}/voc-1.mp3`,
  },
  {
    color: "green",
    group: "sing",
    nameMessage: messages.sampleSing2,
    nr: 2,
    length: 2,
    src: `${BASE_URL}/voc-2.mp3`,
  },
] as const satisfies readonly GenericSample[];

export type Sample = (typeof availableSamples)[number];
