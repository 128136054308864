// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RHeYo0vRM2BnZvwROejy{display:flex;justify-content:space-between;align-items:center;height:68px}.SzETGWzyFP1BXLbv8Af2{display:flex;align-items:center}.QhLDV5PVD0xmuYnc6Ndk{display:flex;width:16em}.QhLDV5PVD0xmuYnc6Ndk a{text-decoration:none}.QhLDV5PVD0xmuYnc6Ndk.zaImM7tp8TWaYRGwA9Cj{justify-content:flex-end}.QhLDV5PVD0xmuYnc6Ndk.z1jZpqS29TrLn7Ep3pA7{justify-content:flex-start}.y7uIz6W4f1lhzRSXUH3Q{width:.5rem;height:.5rem;margin:0 .25rem;border-radius:50%;background:#c8d9e0;cursor:pointer}.y7uIz6W4f1lhzRSXUH3Q:hover{background:#24a8df}.y7uIz6W4f1lhzRSXUH3Q.BggDrgHPJmys_xBI3Env{background:#24a8df}.gNK5LC5IhHHX6FlANHWQ i{color:#c8d9e0 !important}.gNK5LC5IhHHX6FlANHWQ:hover{border-color:#ff8d68 !important}.gNK5LC5IhHHX6FlANHWQ:hover i{color:#ff8d68 !important}.gNK5LC5IhHHX6FlANHWQ:active{border-color:#ff8d68 !important;background-color:#ff8d68 !important}.gNK5LC5IhHHX6FlANHWQ:active i{color:#fff !important}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary-dark": `#e73b05`,
	"primary": `#ff6040`,
	"primary-light": `#ffe4df`,
	"primary-extra-light": `#fff2f0`,
	"secondary-dark": `#8292b1`,
	"secondary": `#99a8c6`,
	"secondary-light": `#d0dae9`,
	"secondary-extra-light": `#eef4fc`,
	"default-dark": `#898ca7`,
	"default": `#b8baca`,
	"default-light": `#ecedf5`,
	"default-extra-light": `#f8f8f8`,
	"warning-dark": `#d96800`,
	"warning": `#fbab00`,
	"warning-light": `#ffeba3`,
	"warning-extra-light": `#fff5d1`,
	"info-dark": `#0a82c6`,
	"info": `#2eb6ea`,
	"info-light": `#beebfc`,
	"info-extra-light": `#dff5fe`,
	"success-dark": `#069941`,
	"success": `#3aaa35`,
	"success-light": `#c6f1c3`,
	"success-extra-light": `#e3f9e1`,
	"danger-dark": `#cd0101`,
	"danger": `#ff4141`,
	"danger-light": `#ffcece`,
	"danger-extra-light": `#ffe7e7`,
	"text-primary": `#131a4f`,
	"text-secondary": `#8292b1`,
	"shadow-25": `rgba(184,186,202,.25)`,
	"shadow-50": `rgba(184,186,202,.5)`,
	"navbar": `RHeYo0vRM2BnZvwROejy`,
	"breadcrumbs": `SzETGWzyFP1BXLbv8Af2`,
	"buttonContainer": `QhLDV5PVD0xmuYnc6Ndk`,
	"right": `zaImM7tp8TWaYRGwA9Cj`,
	"left": `z1jZpqS29TrLn7Ep3pA7`,
	"dot": `y7uIz6W4f1lhzRSXUH3Q`,
	"active": `BggDrgHPJmys_xBI3Env`,
	"restore": `gNK5LC5IhHHX6FlANHWQ`
};
export default ___CSS_LOADER_EXPORT___;
