import { defineMessages } from "react-intl";

export const messages = defineMessages({
  bonusTitle: {
    id: "lms.lesson05.bonus.title",
  },
  bonusDescription: {
    id: "lms.lesson05.bonus.description",
  },
});
