// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.V_7ZE0qg4TuhyMCx19Ta{height:100%;width:100%;display:flex;align-items:center;justify-content:center}.JXNHPKezwb1W4fQyantc.BOm5dNhinbn_xsFuRSYY{width:100%}.JXNHPKezwb1W4fQyantc.BOm5dNhinbn_xsFuRSYY button{width:100%}.JXNHPKezwb1W4fQyantc>div{display:flex;border-radius:57.929px;background:var(--color-515-szary);box-shadow:0px 1px 2px 0px rgba(184,186,202,.5);padding:10px;margin:0 5px;gap:5px}.JXNHPKezwb1W4fQyantc>div button{margin:0;box-shadow:none;padding:5px 10px;font-size:14px;line-height:116.5%;font-weight:700;border:0;background:rgba(0,0,0,0);color:var(--color-515-granat)}.JXNHPKezwb1W4fQyantc>div button:hover{background:var(--color-515-zimnyszary)}.JXNHPKezwb1W4fQyantc>div button>span{white-space:nowrap}.JXNHPKezwb1W4fQyantc>div button.jzQeRW2lwe3PPll9Oiz9{background:var(--color-515-blekitny);box-shadow:0px 1px 2px 0px rgba(184,186,202,.5);color:var(--color-515-baly)}.JXNHPKezwb1W4fQyantc>div button.AnOtp1mPZr_Hqjf6gfkb{background:rgba(0,0,0,0)}.JXNHPKezwb1W4fQyantc>div button.AnOtp1mPZr_Hqjf6gfkb:hover,.JXNHPKezwb1W4fQyantc>div button.AnOtp1mPZr_Hqjf6gfkb:focus{background:rgba(0,0,0,0)}.JXNHPKezwb1W4fQyantc>span{font-size:12px;line-height:16px;font-weight:700}.JXNHPKezwb1W4fQyantc>span div{margin-left:5px}.JXNHPKezwb1W4fQyantc.Ja1is0y2S20Sb98ZoeZM>div{margin:7px 5px 0}.JXNHPKezwb1W4fQyantc.U4RVChAVpRUUFoBfSI2M{display:flex;align-items:center;margin-left:25px}.JXNHPKezwb1W4fQyantc.U4RVChAVpRUUFoBfSI2M>span{margin-right:17px}.JXNHPKezwb1W4fQyantc.BfMGNWJ13c5rUuI0Li4H>div{padding:3px 3px}.JXNHPKezwb1W4fQyantc.BfMGNWJ13c5rUuI0Li4H button{padding:0 5px;line-height:12px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary-dark": `#e73b05`,
	"primary": `#ff6040`,
	"primary-light": `#ffe4df`,
	"primary-extra-light": `#fff2f0`,
	"secondary-dark": `#8292b1`,
	"secondary": `#99a8c6`,
	"secondary-light": `#d0dae9`,
	"secondary-extra-light": `#eef4fc`,
	"default-dark": `#898ca7`,
	"default": `#b8baca`,
	"default-light": `#ecedf5`,
	"default-extra-light": `#f8f8f8`,
	"warning-dark": `#d96800`,
	"warning": `#fbab00`,
	"warning-light": `#ffeba3`,
	"warning-extra-light": `#fff5d1`,
	"info-dark": `#0a82c6`,
	"info": `#2eb6ea`,
	"info-light": `#beebfc`,
	"info-extra-light": `#dff5fe`,
	"success-dark": `#069941`,
	"success": `#3aaa35`,
	"success-light": `#c6f1c3`,
	"success-extra-light": `#e3f9e1`,
	"danger-dark": `#cd0101`,
	"danger": `#ff4141`,
	"danger-light": `#ffcece`,
	"danger-extra-light": `#ffe7e7`,
	"text-primary": `#131a4f`,
	"text-secondary": `#8292b1`,
	"shadow-25": `rgba(184,186,202,.25)`,
	"shadow-50": `rgba(184,186,202,.5)`,
	"root": `V_7ZE0qg4TuhyMCx19Ta`,
	"buttonsGroup": `JXNHPKezwb1W4fQyantc`,
	"fullWidth": `BOm5dNhinbn_xsFuRSYY`,
	"active": `jzQeRW2lwe3PPll9Oiz9`,
	"disabled": `AnOtp1mPZr_Hqjf6gfkb`,
	"top": `Ja1is0y2S20Sb98ZoeZM`,
	"left": `U4RVChAVpRUUFoBfSI2M`,
	"extraSmall": `BfMGNWJ13c5rUuI0Li4H`
};
export default ___CSS_LOADER_EXPORT___;
