// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NILjjAfpeCTXTGCSAYA1{padding:15px;display:flex;flex-direction:column;gap:17px;height:100%}.NILjjAfpeCTXTGCSAYA1 .i1kUL0pTEagS9H60QvNw{text-align:center;font-size:20px;font-weight:700;line-height:100%;letter-spacing:-1px;flex:0 0 auto}.NILjjAfpeCTXTGCSAYA1 .i1kUL0pTEagS9H60QvNw h1{text-align:center}.NILjjAfpeCTXTGCSAYA1 .i34Qhzu1Kn87TdFjTAi2{flex:1 0 0;overflow:hidden;position:relative}.NILjjAfpeCTXTGCSAYA1 .i34Qhzu1Kn87TdFjTAi2 img{width:100%;height:100%;max-width:100%;max-height:100%;object-fit:contain;position:absolute;top:0;left:0;right:0;bottom:0;margin:auto}.NILjjAfpeCTXTGCSAYA1 .OBMlPfTFUGqTJHEfiTsw{flex:0 0 auto;display:flex;flex-direction:row;gap:21px;justify-content:center;justify-items:center}.NILjjAfpeCTXTGCSAYA1 .OBMlPfTFUGqTJHEfiTsw>*{width:50%;max-width:500px}.NILjjAfpeCTXTGCSAYA1 .OBMlPfTFUGqTJHEfiTsw h3{font-size:16px;margin:0 0 17px}.NILjjAfpeCTXTGCSAYA1 .OBMlPfTFUGqTJHEfiTsw p{font-size:14px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary-dark": `#e73b05`,
	"primary": `#ff6040`,
	"primary-light": `#ffe4df`,
	"primary-extra-light": `#fff2f0`,
	"secondary-dark": `#8292b1`,
	"secondary": `#99a8c6`,
	"secondary-light": `#d0dae9`,
	"secondary-extra-light": `#eef4fc`,
	"default-dark": `#898ca7`,
	"default": `#b8baca`,
	"default-light": `#ecedf5`,
	"default-extra-light": `#f8f8f8`,
	"warning-dark": `#d96800`,
	"warning": `#fbab00`,
	"warning-light": `#ffeba3`,
	"warning-extra-light": `#fff5d1`,
	"info-dark": `#0a82c6`,
	"info": `#2eb6ea`,
	"info-light": `#beebfc`,
	"info-extra-light": `#dff5fe`,
	"success-dark": `#069941`,
	"success": `#3aaa35`,
	"success-light": `#c6f1c3`,
	"success-extra-light": `#e3f9e1`,
	"danger-dark": `#cd0101`,
	"danger": `#ff4141`,
	"danger-light": `#ffcece`,
	"danger-extra-light": `#ffe7e7`,
	"text-primary": `#131a4f`,
	"text-secondary": `#8292b1`,
	"shadow-25": `rgba(184,186,202,.25)`,
	"shadow-50": `rgba(184,186,202,.5)`,
	"root": `NILjjAfpeCTXTGCSAYA1`,
	"title": `i1kUL0pTEagS9H60QvNw`,
	"imageWrapper": `i34Qhzu1Kn87TdFjTAi2`,
	"descriptionWrapper": `OBMlPfTFUGqTJHEfiTsw`
};
export default ___CSS_LOADER_EXPORT___;
