import React, { useMemo } from "react";

import { Option, Options } from "../options/Options";

import dishWavy from "@src/assets/lms/lesson-05/slogan/dish/falujaca.png";
import dishFrozen from "@src/assets/lms/lesson-05/slogan/dish/zamarznieta.png";
import dishLifegiving from "@src/assets/lms/lesson-05/slogan/dish/zyciodajna.png";
import dishMeandering from "@src/assets/lms/lesson-05/slogan/dish/meandrujaca.png";
import dishLazy from "@src/assets/lms/lesson-05/slogan/dish/leniwa.png";

import fabricDynamic from "@src/assets/lms/lesson-05/slogan/fabric/dynamiczna.png";
import fabricWavy from "@src/assets/lms/lesson-05/slogan/fabric/falujaca.png";
import fabricLifegiving from "@src/assets/lms/lesson-05/slogan/fabric/zyciodajna.png";
import fabricCalm from "@src/assets/lms/lesson-05/slogan/fabric/spokojna.png";
import fabricAlive from "@src/assets/lms/lesson-05/slogan/fabric/zywa.png";

import architectureFastpaced from "@src/assets/lms/lesson-05/slogan/architecture/wartka.png";
import architectureRefreshing from "@src/assets/lms/lesson-05/slogan/architecture/orzezwiajaca.png";
import architectureFlowing from "@src/assets/lms/lesson-05/slogan/architecture/rozlana.png";

import packageFrozen from "@src/assets/lms/lesson-05/slogan/package/zamarznieta.png";
import packageWavy from "@src/assets/lms/lesson-05/slogan/package/falujaca.png";
import packageLazy from "@src/assets/lms/lesson-05/slogan/package/leniwa.png";
import packageRushing from "@src/assets/lms/lesson-05/slogan/package/rwaca.png";

import { useSloganName } from "@src/components/extensions/layouts/lesson-05/helper";
import { ObjectType, SloganType } from "@src/components/extensions/layouts/lesson-05/types";

interface SelectSloganProps {
  type: ObjectType;
  onSelect: (id: SloganType) => void;
}

const OPTIONS_PER_OBJECT: Record<ObjectType, Option[]> = {
  dish: [
    {
      img: dishFrozen,
      id: "frozen",
    },
    {
      img: dishLifegiving,
      id: "lifegiving",
    },
    {
      img: dishWavy,
      id: "wavy",
    },
    {
      img: dishLazy,
      id: "lazy",
    },
    {
      img: dishMeandering,
      id: "meandering",
    },
  ],
  fabric: [
    {
      img: fabricDynamic,
      id: "dynamic",
    },
    {
      img: fabricLifegiving,
      id: "lifegiving",
    },
    {
      img: fabricWavy,
      id: "wavy",
    },
    {
      img: fabricCalm,
      id: "calm",
    },
    {
      img: fabricAlive,
      id: "alive",
    },
  ],
  architecture: [
    {
      img: architectureFastpaced,
      id: "fastpaced",
    },
    {
      img: architectureFlowing,
      id: "flowing",
    },
    {
      img: architectureRefreshing,
      id: "refreshing",
    },
  ],
  package: [
    {
      img: packageFrozen,
      id: "frozen",
    },
    {
      img: packageRushing,
      id: "rushing",
    },
    {
      img: packageLazy,
      id: "lazy",
    },
    {
      img: packageWavy,
      id: "wavy",
    },
  ],
};
export const SelectSlogan: React.FC<SelectSloganProps> = ({ type, onSelect }) => {
  const slogan = useSloganName();

  const options = useMemo(
    () =>
      OPTIONS_PER_OBJECT[type].map((v) => {
        return {
          ...v,
          text: slogan(v.id),
        };
      }),
    [type, slogan]
  );

  return (
    <Options
      options={options}
      itemWidth={type === "package" ? "35%" : "30%"}
      onSelect={onSelect as (id: string) => void}
    />
  );
};
