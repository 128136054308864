import React from "react";

import styles from "./Intro.module.scss";

import { messages } from "./Intro.messages";
import { useIntl } from "react-intl";
import * as appConfig from "appConfig";
export interface IntroProps {}

export const Intro: React.FC<IntroProps> = () => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <h1>{formatMessage(messages.introTitle)}</h1>
      </div>
      <div className={styles.imageWrapper}>
        <img
          src={`${appConfig.STATIC_ASSETS_URL}/lms/lesson-04/welcome.png`}
          alt={formatMessage(messages.introTitle)}
        />
      </div>
      <div className={styles.descriptionWrapper}>
        <div>
          <h3>{formatMessage(messages.introLeftTitle)}</h3>
          <p>{formatMessage(messages.introLeftDescription)}</p>
        </div>
        <div>
          <h3>{formatMessage(messages.introRightTitle)}</h3>
          <p>{formatMessage(messages.introRightDescription)}</p>
        </div>
      </div>
    </div>
  );
};
