// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YIS2hdOZmqtmeii7XR6A{border-radius:100px;font-size:16px;font-weight:700;line-height:16px;background:var(--color-515-szary);padding:10px 15px;display:inline-flex;align-items:center;height:38px;justify-content:stretch;width:200px}.YIS2hdOZmqtmeii7XR6A .vLtMk3FSWJikoMexhbwN{flex:0 0 auto;margin:0 5px}.YIS2hdOZmqtmeii7XR6A .RAMoxP15FQvlIzNLN3tA{flex:0 0 auto;margin:0 5px}.YIS2hdOZmqtmeii7XR6A .p22OJXNxttRqgZaWmHJX{flex:1 1 auto;text-align:right}.YIS2hdOZmqtmeii7XR6A.bHHV0lG47oh2OxxWNvaL{justify-content:center;background:#f8f8f8;color:rgba(30,52,112,.1882352941)}.YIS2hdOZmqtmeii7XR6A.bHHV0lG47oh2OxxWNvaL i{color:rgba(30,52,112,.1882352941)}.YIS2hdOZmqtmeii7XR6A.qJHGPeouhDw7k2lPLB2R{background:#05a595;color:#fff}.YIS2hdOZmqtmeii7XR6A.qJHGPeouhDw7k2lPLB2R i{color:#fff}.YIS2hdOZmqtmeii7XR6A.jb9KbViUa3inNcJk3T38{background:#1e3470;color:#fff}.YIS2hdOZmqtmeii7XR6A.jb9KbViUa3inNcJk3T38 i{color:#fff}.YIS2hdOZmqtmeii7XR6A.DU_LTPqCc7DVW_3DJTCw{background:#24a8df;color:#fff}.YIS2hdOZmqtmeii7XR6A.DU_LTPqCc7DVW_3DJTCw i{color:#fff}.YIS2hdOZmqtmeii7XR6A.Fa5sKM4Rop0zJ7FxNsAg{background:#ef4b23;color:#fff}.YIS2hdOZmqtmeii7XR6A.Fa5sKM4Rop0zJ7FxNsAg i{color:#fff}.YIS2hdOZmqtmeii7XR6A.V3iTz5Fwe2RK5BvyFaBw{background:#fed18d;color:#1e3470}.YIS2hdOZmqtmeii7XR6A.V3iTz5Fwe2RK5BvyFaBw i{color:#1e3470}.YIS2hdOZmqtmeii7XR6A.qz7KokOkneFXY4kQgTg2{background:#ff8d68;color:#fff}.YIS2hdOZmqtmeii7XR6A.qz7KokOkneFXY4kQgTg2 i{color:#fff}.YIS2hdOZmqtmeii7XR6A.EXWGCS7ZK5bWSmVoxt_x{background:#c8d9e0;color:#fff}.YIS2hdOZmqtmeii7XR6A.EXWGCS7ZK5bWSmVoxt_x i{color:#fff}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary-dark": `#e73b05`,
	"primary": `#ff6040`,
	"primary-light": `#ffe4df`,
	"primary-extra-light": `#fff2f0`,
	"secondary-dark": `#8292b1`,
	"secondary": `#99a8c6`,
	"secondary-light": `#d0dae9`,
	"secondary-extra-light": `#eef4fc`,
	"default-dark": `#898ca7`,
	"default": `#b8baca`,
	"default-light": `#ecedf5`,
	"default-extra-light": `#f8f8f8`,
	"warning-dark": `#d96800`,
	"warning": `#fbab00`,
	"warning-light": `#ffeba3`,
	"warning-extra-light": `#fff5d1`,
	"info-dark": `#0a82c6`,
	"info": `#2eb6ea`,
	"info-light": `#beebfc`,
	"info-extra-light": `#dff5fe`,
	"success-dark": `#069941`,
	"success": `#3aaa35`,
	"success-light": `#c6f1c3`,
	"success-extra-light": `#e3f9e1`,
	"danger-dark": `#cd0101`,
	"danger": `#ff4141`,
	"danger-light": `#ffcece`,
	"danger-extra-light": `#ffe7e7`,
	"text-primary": `#131a4f`,
	"text-secondary": `#8292b1`,
	"shadow-25": `rgba(184,186,202,.25)`,
	"shadow-50": `rgba(184,186,202,.5)`,
	"root": `YIS2hdOZmqtmeii7XR6A`,
	"icon": `vLtMk3FSWJikoMexhbwN`,
	"nr": `RAMoxP15FQvlIzNLN3tA`,
	"child": `p22OJXNxttRqgZaWmHJX`,
	"themeEmpty": `bHHV0lG47oh2OxxWNvaL`,
	"themeGreen": `qJHGPeouhDw7k2lPLB2R`,
	"themeNavy": `jb9KbViUa3inNcJk3T38`,
	"themeBlue": `DU_LTPqCc7DVW_3DJTCw`,
	"themeRed": `Fa5sKM4Rop0zJ7FxNsAg`,
	"themeYellow": `V3iTz5Fwe2RK5BvyFaBw`,
	"themeOrange": `qz7KokOkneFXY4kQgTg2`,
	"themeGrey": `EXWGCS7ZK5bWSmVoxt_x`
};
export default ___CSS_LOADER_EXPORT___;
