import React from "react";

import styles from "./WaterParams.module.scss";

export interface WaterParamsProps {
  type: "moth" | "drop" | "pill";
  steps?: number;
  filledSteps: number;
  title: string;
}

import pillLeft from "@src/assets/images/icons/pil-left.svg";
import pillRight from "@src/assets/images/icons/pil-right.svg";
import pillCenter from "@src/assets/images/icons/pil-center.svg";

import moth from "@src/assets/images/icons/moth.svg";
import drop from "@src/assets/images/icons/drop.svg";

export const WaterParams: React.FC<WaterParamsProps> = ({ filledSteps, steps = 5, type, title }) => {
  let imgParams: Array<{
    component: React.FunctionComponent<{ className?: string; alt?: string }>;
    className?: string;
  }> = [];

  if (type === "moth") {
    imgParams = Array(steps)
      .fill(null)
      .map(() => {
        return {
          component: moth,
        };
      });
  } else if (type === "drop") {
    imgParams = Array(steps)
      .fill(null)
      .map(() => {
        return {
          component: drop,
        };
      });
  } else {
    imgParams = Array(steps - 2)
      .fill(null)
      .map(() => {
        return {
          component: pillCenter,
        };
      });

    imgParams = [
      {
        component: pillLeft,
      },
      ...imgParams,
      {
        component: pillRight,
      },
    ];
  }

  for (let i = 0; i < filledSteps; i++) {
    imgParams[i].className = styles.active;
  }

  return (
    <div className={`${styles.root} ${styles[type]}`}>
      <div className={styles.diagram}>
        {imgParams.map((params, index) => (
          <params.component key={index} className={params.className ?? ""} alt="item" />
        ))}
      </div>
      <div>{title}</div>
    </div>
  );
};
