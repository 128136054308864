import React from "react";

import styles from "./Legend.module.scss";
import { messages } from "./Legend.messages";

import { useIntl } from "react-intl";

import o2 from "@src/assets/lms/lesson-01/o2.svg?url";
import co2 from "@src/assets/lms/lesson-01/co2.svg?url";

import water from "@src/assets/lms/lesson-01/water.svg?url";
import peat from "@src/assets/lms/lesson-01/peat.svg?url";
import vegetation from "@src/assets/lms/lesson-01/vegetation.svg?url";
import mineral from "@src/assets/lms/lesson-01/mineral.svg?url";
import rot from "@src/assets/lms/lesson-01/rot.svg?url";
import vegetationTop from "@src/assets/lms/lesson-01/vegetation-top.svg?url";
import emission from "@src/assets/lms/lesson-01/emission.svg?url";

interface LegendItem {
  name: string;
  iconUrl: string;
}
export interface LegendProps {
  items: LegendItem[];
}
export const Legend: React.FC<LegendProps> = ({ items }) => {
  return (
    <div className={styles.root}>
      {items.map((item, index) => (
        <div key={index} className={styles.item}>
          <img src={item.iconUrl} alt={item.name} />
          <span>{item.name}</span>
        </div>
      ))}
    </div>
  );
};

export const AirLegend: React.FC = () => {
  const { formatMessage } = useIntl();

  const items: LegendItem[] = [
    {
      name: formatMessage(messages.o2),
      iconUrl: o2,
    },
    {
      name: formatMessage(messages.co2),
      iconUrl: co2,
    },
  ];

  return <Legend items={items} />;
};

const SWAMP_ITEMS = {
  water,
  vegetation,
  vegetationTop,
  rot,
  peat,
  mineral,
  emission,
} as const;

type SwampItem = keyof typeof SWAMP_ITEMS;

interface SwampLegendProps {
  only?: SwampItem[];
}

export const SwampLegend: React.FC<SwampLegendProps> = ({ only }) => {
  const { formatMessage } = useIntl();

  const items: LegendItem[] = Object.entries(SWAMP_ITEMS)
    .filter(([id]) => (only as string[]).includes(id))
    .map(([id, iconUrl]) => ({
      name: formatMessage(messages[id as keyof typeof messages]),
      iconUrl,
    }));

  return <Legend items={items} />;
};
