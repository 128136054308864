import React from "react";
import { CarouselLayoutOptions, useLayouts } from "@jelly/lesson";
import { UIOverride } from "@src/components/extensions/layouts/ui-overrde/UIOverride";
import { CarouselButton } from "@src/components/extensions/ui";
export const Carousel: React.FC<CarouselLayoutOptions & { children: never }> = (props) => {
  const { carousel: CoreCarousel } = useLayouts();

  return (
    <UIOverride
      overrides={{
        ui: {
          Button: CarouselButton,
        },
      }}
    >
      <CoreCarousel {...props} />
    </UIOverride>
  );
};
