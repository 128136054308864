// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ilh3OY2pekTzzMihS7dc{display:flex;flex-direction:column;height:100%;flex-flow:column;font-size:14px;line-height:116.5%;font-weight:700}.ilh3OY2pekTzzMihS7dc .bVC22teexrmYBmsc1ZnQ{flex:1 0 0;position:relative}.ilh3OY2pekTzzMihS7dc .bVC22teexrmYBmsc1ZnQ img{max-width:100%;max-height:100%;position:absolute;top:0;left:0;right:0;bottom:0;margin:auto}.ilh3OY2pekTzzMihS7dc .aIj33IIgM7RprHoG4lIw{display:flex;align-items:center;justify-content:center;flex:0 0 auto;padding:20px 0;border-bottom:1px solid var(--color-515-zimnyszary);flex-wrap:wrap}.ilh3OY2pekTzzMihS7dc .hGA7Z7ytXqRJZqb9CTF8{color:var(--color-515-blekitny);margin:0 30px}.ilh3OY2pekTzzMihS7dc .b16Ptu_IYBdS50VqKsIA{font-size:10px;line-height:125%;margin-right:15px;font-weight:400}.ilh3OY2pekTzzMihS7dc .PzknzN3F8ueShg8LfKyx{flex:0 0 auto;display:flex;justify-items:center;justify-content:center;padding:20px 0;flex-wrap:wrap}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary-dark": `#e73b05`,
	"primary": `#ff6040`,
	"primary-light": `#ffe4df`,
	"primary-extra-light": `#fff2f0`,
	"secondary-dark": `#8292b1`,
	"secondary": `#99a8c6`,
	"secondary-light": `#d0dae9`,
	"secondary-extra-light": `#eef4fc`,
	"default-dark": `#898ca7`,
	"default": `#b8baca`,
	"default-light": `#ecedf5`,
	"default-extra-light": `#f8f8f8`,
	"warning-dark": `#d96800`,
	"warning": `#fbab00`,
	"warning-light": `#ffeba3`,
	"warning-extra-light": `#fff5d1`,
	"info-dark": `#0a82c6`,
	"info": `#2eb6ea`,
	"info-light": `#beebfc`,
	"info-extra-light": `#dff5fe`,
	"success-dark": `#069941`,
	"success": `#3aaa35`,
	"success-light": `#c6f1c3`,
	"success-extra-light": `#e3f9e1`,
	"danger-dark": `#cd0101`,
	"danger": `#ff4141`,
	"danger-light": `#ffcece`,
	"danger-extra-light": `#ffe7e7`,
	"text-primary": `#131a4f`,
	"text-secondary": `#8292b1`,
	"shadow-25": `rgba(184,186,202,.25)`,
	"shadow-50": `rgba(184,186,202,.5)`,
	"root": `ilh3OY2pekTzzMihS7dc`,
	"image": `bVC22teexrmYBmsc1ZnQ`,
	"options": `aIj33IIgM7RprHoG4lIw`,
	"plus": `hGA7Z7ytXqRJZqb9CTF8`,
	"label": `b16Ptu_IYBdS50VqKsIA`,
	"controls": `PzknzN3F8ueShg8LfKyx`
};
export default ___CSS_LOADER_EXPORT___;
