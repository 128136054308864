import React from "react";
import { useUi } from "@jelly/lesson";
import { useIntl } from "react-intl";
import styles from "./Author.module.scss";

import { messages } from "./Author.messages";
import * as appConfig from "appConfig";

export interface AuthorProps {}

export const Author: React.FC<AuthorProps> = () => {
  const ui = useUi();
  const { formatMessage } = useIntl();

  return (
    <div className={styles.root}>
      <div className={styles.leftBlock}>
        <h1 className={styles.title}>{formatMessage(messages.bonusTitle)}</h1>
        <p className={styles.description}>
          <ui.Scrollbars>
            {formatMessage(messages.bonusDescription)
              .split("\n")
              .map((line) => (
                <>
                  {line}
                  <br />
                </>
              ))}
          </ui.Scrollbars>
        </p>
        <div>
          <ui.Button
            theme={"primary"}
            shape={"round"}
            size={"standard"}
            onClick={() => window.open("http://agnieszkabar.pl")}
          >
            Portfolio
          </ui.Button>
          <ui.Button
            theme={"primary"}
            shape={"round"}
            size={"standard"}
            onClick={() => window.open("https://www.facebook.com/agnieszkabarglass")}
          >
            Facebook
          </ui.Button>
          <ui.Button
            theme={"primary"}
            shape={"round"}
            size={"standard"}
            onClick={() => window.open("https://www.instagram.com/agnieszka_bar")}
          >
            Instagram
          </ui.Button>
        </div>
      </div>
      <div className={styles.rightBlock}>
        <div className={styles.imgWrapper}>
          <img
            src={`${appConfig.STATIC_ASSETS_URL}/lms/lesson-05/bonus/author.jpg`}
            alt={formatMessage(messages.bonusTitle)}
          />
        </div>
      </div>
    </div>
  );
};
