import React, { useEffect } from "react";
import { Sample } from "../samples";
import { useDrag } from "react-dnd";
import {
  DND_TYPE_SAMPLE,
  usePostcardModel,
} from "@src/components/extensions/layouts/lesson-04/iceland-postcard/PostcardModel";
import { SampleTile } from "@src/components/extensions/layouts/lesson-04/components/SampleTile";
import { useIntl } from "react-intl";

import styles from "./DraggableTitle.module.scss";
import classnames from "classnames";
import { Position } from "@src/components/extensions/layouts/lesson-04/iceland-postcard/types";
import { useUi } from "@jelly/lesson";

interface DraggableTileProps {
  sample: Sample;
  currentPosition?: Position;
}
export const DraggableTile: React.FC<DraggableTileProps> = ({ sample, currentPosition }) => {
  const { removeSampleFromXY } = usePostcardModel();
  const ui = useUi();

  const { formatMessage } = useIntl();
  const [collected, drag] = useDrag(
    {
      type: DND_TYPE_SAMPLE,
      item: sample,
      options: {
        dropEffect: "copy",
      },
      collect: (monitor) => {
        return {
          isDragging: monitor.isDragging(),
          isDraggingSomething: !!monitor.getItem(),
        };
      },
    },
    []
  );

  // on drag start remove from current position
  useEffect(() => {
    if (collected.isDragging && currentPosition) {
      removeSampleFromXY(currentPosition);
    }
  }, [collected.isDragging, removeSampleFromXY, currentPosition]);

  return (
    <div
      ref={drag}
      className={classnames(styles.root, {
        [styles.dragging]: collected.isDragging,
        [styles.draggingOther]: !collected.isDragging && collected.isDraggingSomething,
      })}
    >
      <SampleTile theme={sample.color} nr={sample.nr}>
        <span className={styles.content}>
          {formatMessage(sample.nameMessage)}
          {currentPosition && (
            <span className={styles.closeButton} onClick={() => currentPosition && removeSampleFromXY(currentPosition)}>
              <ui.Icon className={"cil-x"} />
            </span>
          )}
        </span>
      </SampleTile>
    </div>
  );
};
