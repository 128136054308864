import React from "react";

import { Option, Options } from "../options/Options";

import dish1 from "@src/assets/lms/lesson-05/theme/dish/dish1.png";
import dish2 from "@src/assets/lms/lesson-05/theme/dish/dish2.png";
import dish3 from "@src/assets/lms/lesson-05/theme/dish/dish3.png";
import dish4 from "@src/assets/lms/lesson-05/theme/dish/dish4.png";

import architecture1 from "@src/assets/lms/lesson-05/theme/architecture/architecture1.png";
import architecture2 from "@src/assets/lms/lesson-05/theme/architecture/architecture2.png";
import architecture3 from "@src/assets/lms/lesson-05/theme/architecture/architecture3.png";
import architecture4 from "@src/assets/lms/lesson-05/theme/architecture/architecture4.png";

import fabric1 from "@src/assets/lms/lesson-05/theme/fabric/fabric1.png";
import fabric2 from "@src/assets/lms/lesson-05/theme/fabric/fabric2.png";
import fabric3 from "@src/assets/lms/lesson-05/theme/fabric/fabric3.png";
import fabric4 from "@src/assets/lms/lesson-05/theme/fabric/fabric4.png";

import package1 from "@src/assets/lms/lesson-05/theme/package/package1.png";
import package2 from "@src/assets/lms/lesson-05/theme/package/package2.png";
import package3 from "@src/assets/lms/lesson-05/theme/package/package3.png";
import package4 from "@src/assets/lms/lesson-05/theme/package/package4.png";
import { getThemeImgSrc } from "@src/components/extensions/layouts/lesson-05/helper";
import { ObjectType, ThemeType } from "@src/components/extensions/layouts/lesson-05/types";

interface SelectThemeProps {
  type: ObjectType;
  onSelect: (id: ThemeType) => void;
}

const IMAGES: Record<ObjectType, string[]> = {
  dish: [dish1, dish2, dish3, dish4],
  fabric: [fabric1, fabric2, fabric3, fabric4],
  package: [package1, package2, package3, package4],
  architecture: [architecture1, architecture2, architecture3, architecture4],
};

const OPTIONS_PER_OBJECT: Record<ObjectType, Option[]> = Object.fromEntries(
  ["dish", "fabric", "architecture", "package"].map((type) => {
    return [
      type,
      Array(4)
        .fill(null)
        .map((_, index) => {
          const id = index + "";
          const colorBar = getThemeImgSrc(id);
          const component = (
            <>
              <div>
                <img src={IMAGES[type as ObjectType][index]} alt={`sample-${index}`} />
              </div>
              <img
                src={colorBar}
                alt={`theme-${index}`}
                style={{ width: "100%", maxWidth: "402px", paddingTop: "10px", margin: "0 auto" }}
              />
            </>
          );

          const option: Option = {
            component,
            id,
          };

          return option;
        }),
    ];
  })
) as Record<ObjectType, Option[]>;

export const SelectTheme: React.FC<SelectThemeProps> = ({ type, onSelect }) => {
  const options = OPTIONS_PER_OBJECT[type];

  return <Options options={options} itemWidth={"35%"} onSelect={onSelect as (id: string) => void} />;
};
