// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QZ65bKNKZLnUDUeV47Sm{width:100%;height:100%;display:flex;gap:20px;font-size:14px;border-bottom:1px solid var(--color-515-zimnyszary);padding-bottom:15px}.QZ65bKNKZLnUDUeV47Sm .oGT0NwExRrpZzMMTtImy{flex:1 0 0;overflow:hidden;position:relative}.QZ65bKNKZLnUDUeV47Sm .oGT0NwExRrpZzMMTtImy .QEMItkHxKML8AZwnMSz_{position:absolute;top:0;left:0;right:0;bottom:0}.QZ65bKNKZLnUDUeV47Sm .Z9TsKsrZYZoLZAmHunyH{padding:0 10px;width:277px;flex:0 0 auto;display:flex;flex-direction:column;gap:20px;overflow:hidden;align-items:center}.QZ65bKNKZLnUDUeV47Sm .Z9TsKsrZYZoLZAmHunyH a{color:inherit;font-weight:bold}.QZ65bKNKZLnUDUeV47Sm .Z9TsKsrZYZoLZAmHunyH a:hover{color:var(--color-515-blekitny)}.QZ65bKNKZLnUDUeV47Sm .Z9TsKsrZYZoLZAmHunyH h3{text-align:center;font-size:14px;font-style:normal;font-weight:700;line-height:116.5%;margin:0;flex:0 0 auto}.QZ65bKNKZLnUDUeV47Sm .Z9TsKsrZYZoLZAmHunyH .G4s3foni4YtSbVj55fku{flex:1 1 auto;overflow:hidden;max-height:175px}.QZ65bKNKZLnUDUeV47Sm .Z9TsKsrZYZoLZAmHunyH .G4s3foni4YtSbVj55fku img{width:100%;height:100%;object-fit:contain}.QZ65bKNKZLnUDUeV47Sm .Z9TsKsrZYZoLZAmHunyH .sxdXjR9MPPt__9shgueQ{flex:0 0 auto;text-align:center;font-size:12px}.QZ65bKNKZLnUDUeV47Sm .Z9TsKsrZYZoLZAmHunyH .csByR8TOBUCAf2M69Vva{flex:1 0 auto;display:flex;flex-direction:column;align-items:center;justify-content:center}.QZ65bKNKZLnUDUeV47Sm .Z9TsKsrZYZoLZAmHunyH .R1eQf88aebJBvV6ZzJKQ{flex:0 0 auto;display:flex;gap:10px;flex-direction:row;font-size:12px}.QZ65bKNKZLnUDUeV47Sm .Z9TsKsrZYZoLZAmHunyH .R1eQf88aebJBvV6ZzJKQ i{color:var(--color-515-granat)}.QZ65bKNKZLnUDUeV47Sm .Z9TsKsrZYZoLZAmHunyH .R1eQf88aebJBvV6ZzJKQ>*:first-of-type{font-size:16px;flex:0 0 auto}.QZ65bKNKZLnUDUeV47Sm .Z9TsKsrZYZoLZAmHunyH .R1eQf88aebJBvV6ZzJKQ>*:nth-of-type(2){flex:1 1 auto}.QZ65bKNKZLnUDUeV47Sm .Z9TsKsrZYZoLZAmHunyH .R1eQf88aebJBvV6ZzJKQ>*:nth-of-type(2) b{font-weight:700}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary-dark": `#e73b05`,
	"primary": `#ff6040`,
	"primary-light": `#ffe4df`,
	"primary-extra-light": `#fff2f0`,
	"secondary-dark": `#8292b1`,
	"secondary": `#99a8c6`,
	"secondary-light": `#d0dae9`,
	"secondary-extra-light": `#eef4fc`,
	"default-dark": `#898ca7`,
	"default": `#b8baca`,
	"default-light": `#ecedf5`,
	"default-extra-light": `#f8f8f8`,
	"warning-dark": `#d96800`,
	"warning": `#fbab00`,
	"warning-light": `#ffeba3`,
	"warning-extra-light": `#fff5d1`,
	"info-dark": `#0a82c6`,
	"info": `#2eb6ea`,
	"info-light": `#beebfc`,
	"info-extra-light": `#dff5fe`,
	"success-dark": `#069941`,
	"success": `#3aaa35`,
	"success-light": `#c6f1c3`,
	"success-extra-light": `#e3f9e1`,
	"danger-dark": `#cd0101`,
	"danger": `#ff4141`,
	"danger-light": `#ffcece`,
	"danger-extra-light": `#ffe7e7`,
	"text-primary": `#131a4f`,
	"text-secondary": `#8292b1`,
	"shadow-25": `rgba(184,186,202,.25)`,
	"shadow-50": `rgba(184,186,202,.5)`,
	"root": `QZ65bKNKZLnUDUeV47Sm`,
	"imageWrapper": `oGT0NwExRrpZzMMTtImy`,
	"image": `QEMItkHxKML8AZwnMSz_`,
	"infoWrapper": `Z9TsKsrZYZoLZAmHunyH`,
	"map": `G4s3foni4YtSbVj55fku`,
	"description": `sxdXjR9MPPt__9shgueQ`,
	"buttonsWrapper": `csByR8TOBUCAf2M69Vva`,
	"explanation": `R1eQf88aebJBvV6ZzJKQ`
};
export default ___CSS_LOADER_EXPORT___;
