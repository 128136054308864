import { defineMessages } from "react-intl";

export const messages = defineMessages({
  mainPageButton: {
    id: "lms.lesson-item-navigation.button-main-page",
  },

  bonusButton: {
    id: "lms.lesson-item-navigation.button-bonus",
  },

  experimentButton: {
    id: "lms.lesson-item-navigation.experiment-bonus",
  },
});
