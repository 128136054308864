import React from "react";

import classnames from "classnames";
import { useUi } from "@jelly/lesson";
import { OptionalKeys } from "@src/helpers/typescript";

import styles from "./SampleTile.module.scss";

export interface SampleTileProps {
  theme?: "yellow" | "red" | "blue" | "navy" | "green" | "orange" | "grey" | "empty";
  children?: React.ReactNode;
  nr?: number;
}

const defaultProps = {
  theme: "empty",
} as const satisfies Pick<SampleTileProps, OptionalKeys<SampleTileProps>>;
export const SampleTile: React.FC<SampleTileProps> = (props: SampleTileProps) => {
  const { children, theme, nr } = {
    ...defaultProps,
    ...props,
  };

  const ui = useUi();

  return (
    <span className={classnames(styles.root, styles["theme" + firstLetterUp(theme)])}>
      <ui.Icon className={classnames("cil-music-note-alt", styles.icon)} />
      {nr && <span className={styles.nr}>{nr}</span>}
      <span className={styles.child}>{children}</span>
    </span>
  );
};

function firstLetterUp(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
