import { defineMessages } from "react-intl";

export const messages = defineMessages({
  stepHeader: {
    id: "lms.lesson05.step.header",
  },
  step1Description: {
    id: "lms.lesson05.step1.description",
  },
  step2Description: {
    id: "lms.lesson05.step2.description",
  },
  step3Description: {
    id: "lms.lesson05.step3.description",
  },
  selectSloganHeader: {
    id: "lms.lesson05.select-slogan.header",
  },
  selectThemeHeader: {
    id: "lms.lesson05.select-theme.header",
  },
  summaryHeader: {
    id: "lms.lesson05.summary.header",
  },
  summaryDescription: {
    id: "lms.lesson05.summary.description",
  },
  entityFabric: {
    id: "lms.lesson05.entity.fabric",
  },
  entityArchitecture: {
    id: "lms.lesson05.entity.architecture",
  },
  entityDish: {
    id: "lms.lesson05.entity.dish",
  },
  entityPackage: {
    id: "lms.lesson05.entity.package",
  },
  sloganFrozen: {
    id: "lms.lesson05.slogan.frozen",
  },
  sloganLifegiving: {
    id: "lms.lesson05.slogan.lifegiving",
  },
  sloganWavy: {
    id: "lms.lesson05.slogan.wavy",
  },
  sloganLazy: {
    id: "lms.lesson05.slogan.lazy",
  },
  sloganMeandering: {
    id: "lms.lesson05.slogan.meandering",
  },
  sloganDynamic: {
    id: "lms.lesson05.slogan.dynamic",
  },
  sloganCalm: {
    id: "lms.lesson05.slogan.calm",
  },
  sloganAlive: {
    id: "lms.lesson05.slogan.alive",
  },
  sloganFastpaced: {
    id: "lms.lesson05.slogan.fastpaced",
  },
  sloganFlowing: {
    id: "lms.lesson05.slogan.flowing",
  },
  sloganRefreshing: {
    id: "lms.lesson05.slogan.refreshing",
  },
  sloganRushing: {
    id: "lms.lesson05.slogan.rushing",
  },
  objectDish: {
    id: "lms.lesson05.object.dish",
  },
  objectFabric: {
    id: "lms.lesson05.object.fabric",
  },
  objectArchitecture: {
    id: "lms.lesson05.object.architecture",
  },
  objectPackage: {
    id: "lms.lesson05.object.package",
  },
  object: {
    id: "lms.lesson05.setup.object",
  },
  slogan: {
    id: "lms.lesson05.setup.slogan",
  },
  theme: {
    id: "lms.lesson05.setup.theme",
  },
  chooseObject: {
    id: "lms.lesson05.setup.choose-object",
  },
  chooseSlogan: {
    id: "lms.lesson05.setup.choose-slogan",
  },
  chooseTheme: {
    id: "lms.lesson05.setup.choose-theme",
  },
  setupButtonBack: {
    id: "lms.lesson05.setup.button-back",
  },
  setupButtonReset: {
    id: "lms.lesson05.setup.button-reset",
  },
});
