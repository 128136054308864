export const plMessages = {
  "menu.about": "O projekcie",
  "menu.team": "Zespół",
  "menu.experts": "Eksperci",
  "menu.multibook": "Multibook",

  "mobile-placeholder.title": "To urządzenie ma za mały ekran",
  "mobile-placeholder.subtitle": "Otwórz PodPrąd na komputerze, aby otrzymać najlepsze doświadczenie",
  "mobile-placeholder.description":
    "Jeśli korzystasz z tabletu, spróbuj przekręcić urządzenie, aby trzymać je poziomo.",

  "landing.experiment1.title": "Bagno",
  "landing.experiment1.description":
    "Potrafi zarówno pochłonąć, jak i emitować ogromne ilości CO2. Sprawdź, jak działa bagno.",
  "landing.experiment2.title": "Dno rzeki",
  "landing.experiment2.description": "Najważniejsze, jest niewidoczne dla oczu. Zobacz rzekę z innej perspektywy.",
  "landing.experiment3.title": "Pocztówka dźwiękowa",
  "landing.experiment3.description":
    "A gdyby tak opisać świat rzek inaczej? Przygotowaliśmy dla Ciebie  różne dźwięki. Wykorzystaj je by stworzyć własny utwór.",
  "landing.experiment4.title": "Spotkanie z rzeką",
  "landing.experiment4.description":
    "Zejdź z mostu, zbliż się do rzeki aby ją poznać z innej strony, przeżyj z nią przygodę!",
  "landing.experiment5.title": "Projektowanie inspirowane naturą",
  "landing.experiment5.description":
    "Natura ma miliardy lat doświadczenia w projektowaniu. Zainspiruj się przyrodą i stwórz własny obiekt.",
  "landing.open-experiment": "Otwórz eksperyment",
  "footer.contact-banner.headline": "Jesteś edukatorem? Reprezentujesz szkołę?",
  "footer.contact-banner.sub-headline": "Mamy dla Ciebie dodatkowe materiały!",
  "footer.contact-banner.contact-us": "Skontaktuj się z nami",
  "footer.financed-by":
    "Sfinansowano przez Islandię, Liechtenstein i Norwegię z Funduszy EOG w ramach Program Aktywni Obywatele – Fundusz Regionalny",

  //ABOUT PROJECT SUBPAGE
  //!!!!
  "aboutProject.title": "O projekcie",
  "aboutProject.description1": `<p>Pod prąd - projekt edukacyjny realizowany w 2023r. przez Stowarzyszenie 515 - organizację pozarządową działającą od 2019 roku na 515 kilometrze rzeki Odry. Projekt został sfinansowany przez Islandię, Liechtenstein i Norwegię z Funduszy EOG w ramach <a>Program Aktywni Obywatele - Fundusz Regionalny</a></p>
  <p>Pod Prąd to rozwinięcie i kontynuacja zapoczątkowanego w 2022 r przez Stowarzyszenie 515 cyklu edukacji ekologicznej pod nazwą #515niebieskichgodzinwszkole będących próbą wprowadzenia żywej edukacji o rzekach i wodzie do szkół. Cykl zajęć odbywających się nad rzeką, przerwała katastrofa na Odrze w sierpniu 2022r.</p>
  <p>Myślą przewodnią projektu Pod prąd jest dostarczanie innowacyjnych, interaktywnych narzędzi edukacyjnych dla uczniów, oraz zainspirowanie nauczycieli i opiekunów do twórczej pracy przy podejmowaniu tematyki ochrony rzek, wód. Źródłem inspiracji nieszablonowego podejścia do procesu nauczania stały się kreatywne rozwiązania płynące z Islandii. Narzędzia oddane w projekcie mogą służyć nie tylko uczniom, ale również dorosłym, a sama platforma dostępna jest w 2 wersjach językowych - polskim oraz angielskim.</p>`,

  "aboutProject.description2": `Przygoda z eksperymentami będącymi głównym produktem projektu, ma przenosić użytkowników do świata kreatywności, przyrody, designu, nauki o dźwięku, zachęcając jednocześnie do samodzielnych poszukiwań i myślenia „pod prąd”.

    Materiały edukacyjne, w tym głównie nagrania terenowe, fotografie i filmy zostały zebrane zarówno w Polsce, jak i Islandii. Tematyka merytoryczna eksperymentów osadzona została w lokalnym kontekście ekosystemu rzeki Odry, porusza natomiast istotne zagadnienia (w tym ochrony środowiska, klimatu) w skali globalnej. Eksperymenty mogą być wykorzystywane na całym świecie. Nad poprawnością merytoryczną eksperymentów czuwali wysokiej klasy eksperci.`,

  "aboutProject.descriptionListTitle": "W ramach projektu powstały między innymi:",
  "aboutProject.descriptionListItems": `eksperymenty edukacyjne
    narzędzie multibook z bankiem inspirujących rozwiązań dla uczniów i nauczycieli
    2 warsztaty kreatywne`,

  "aboutProject.partnersHeader":
    "Projekt został zrealizowany przy współpracy z partnerami z Polski oraz z Islandii, honorowy patronat nad projektem objął Ambasador Islandii w Polsce.",

  //TEAM
  "aboutProject.teamHeader": "Zespół",
  "aboutProject.teamDescription":
    "Pod prąd powstał w interdyscyplinarnym zespole. Tworzyliśmy eksperymenty w zrozumieniu, wzajemnej inspiracji i z czułością do rzek, o których należy rozmawiać częściej.",

  "person.m.bobryk.position": "Założycielka Stowarzyszenia 515",
  "person.m.bobryk.description": `Mieszka na 515 kilometrze rzeki. Założycielka Stowarzyszenia 515 kilometr Odry, przewodnik po Islandii, niezależny doradca branży turystycznej w  obszarze kreacji innowacji usługowych i produktowych. Współpracuje z  samorządami, prywatnymi przedsiębiorstwami. Absolwentka Design Management, przyszła studentka Master of Disaster Management. Jako  społecznik skleja potencjały i agreguje zasoby na rzecz zredukowania kryzysu wiedzy wokół rzek - często traktowanych jako niczyje, niewidzialne, zapomniane, nieważne. Laureatka nagrody Polcul w 2023 za ochronę rzeki, promocję edukacji ekologicznej oraz budowę koalicji obywatelskiej w obliczu katastrofy Odry.`,

  "person.a.bobryk.position": "Współzałożycielka Stowarzyszenia 515",
  "person.a.bobryk.description": `Absolwentka pedagogiki Uniwersytetu Zielonogórskiego. Współzałożycielka Stowarzyszenia 515 kilometr Odry. Aktywna Mama trójki dzieci współtworząca projekty dedykowane najmłodszym odbiorcom, tester rozwiązań i sprawny koordynator, promotorka edukacji pozaformalnej. Pasjonuje się hodowlą domowych roślin.`,

  "person.a.grebieniow.position": "Członkini Stowarzyszenia 515",
  "person.a.grebieniow.description": `Mgr inż. hodowli i biologii zwierząt, specjalista w zakresie ochrony przyrody i edukacji przyrodniczej, podróżnik, fotograf oraz licencjonowany pilot drona. Pracowała w projektach ochrony bociana białego, ptaków podmokłych łąk, płomykówki, susła moręgowanego, popielic. Prowadziła kampanie informacyjno-edukacyjne z zakresu Konwencji Waszyngtońskiej (CITES) oraz obcych gatunków inwazyjnych w Polsce. Pracowała przez dwa sezony letnie w bazie terenowej Polskiej Stacji Antarktycznej „Arctowski – ASPA 151, przylądek Lions Rump, Wyspa Króla Jerzego, Antarktyka. Pracowała jako edukator przyrodniczy z zakresu CITES oraz dla WWF.`,

  "person.k.jaruszewska.position": "Project manager",
  "person.k.jaruszewska.description": `Absolwentka Wydziału Elektroniki Politechniki Wrocławskiej oraz studiów podyplomowych Master Class of Management na Uniwersytecie SWPS. Specjalistka w dziedzinie Akustyki. Od 2014 roku zarządza międzynarodowymi projektami edukacyjnymi realizowanymi w KFB Acoustics. Koordynatorka platformy edukacyjnej Acoucou.org. Współautorka ilustrowanej książki o dźwięku "Halo! Tu dźwięk!" wyróżnionej znakiem Must Have 2021. Przewodnicząca Komisji Technicznej do spraw edukacji przy Europejskim Stowarzyszeniu Akustycznym. Jej zainteresowania koncentrują się wokół obszarów interdyscyplinarnych, łączących sferę nauki, inżynierii i sztuki.`,

  "person.m.blazniak.position": "Grafik, ilustrator, założyciel studia graficznego Ładne Halo",
  "person.m.blazniak.description": `Grafik, ilustrator, autor książek dla dzieci. Ukończył z wyróżnieniem wzornictwo w Wyższej Szkole Sztuki i Projektowania w Łodzi, specjalność: projektowanie ilustracji i książki artystycznej. W 2011 roku założył wydawnictwo i studio graficzne Ładne Halo. Ilustrował dla wydawnictw: Ładne Halo, Wytwórnia, Egmont, Polarny Lis, Adamada i magazynów: Znak, Wysokie Obcasy. Wspólnie z inicjatywą Mamy Projekt realizuje cykl ilustrowanych przewodników po Polsce NIEMAPA. W 2014 Laureat międzynarodowej nagrody Red Dot Design Award w kategorii Communication Design za projekt graficzny aplikacji z interaktywnymi książkami dla dzieci. W 2015 uhonorowany przez NCK i Dziennik Łódzki tytułem Wielki Kreator za łączenie biznesu z kulturą.`,

  //EXPERTS
  "aboutProject.expertsHeader": "Eksperci",

  "person.a.bar.position": "Projektantka i artystka wizualna",
  "person.a.bar.description": `Wrażliwa, empatyczna istota. Projektantka, wizjonerka, eksperymentatorka. Tworzy przedmioty wzbogacające codzienne doświadczenia. Drażni zmysły i umysły serwując w naczyniach pustkę dla refleksji. Uważna i czuła. Patrzy głęboko w oczy i słucha.
Urodziła się w Sudetach, w regionie o bogatych tradycjach szklarskich. Absolwentka Wzornictwa i Sztuki Szkła na Akademii Sztuk Pięknych we  Wrocławiu. Przez 12 lat współtworzyła program edukacyjny w Katedrze Szkła na Wydziale Ceramiki i Szkła na wrocławskiej ASP i prowadziła projekty badawcze. Tworzy markę osobistą, w której koncentruje się na projektowaniu przedmiotów funkcjonalnych zapraszających do doświadczania materii oraz na realizacjach unikatowych, w których zawiera metafory i ważne przekazy. Zajmuje się projektowaniem wizerunkowym dla wyjątkowych brandów i inicjatyw. Realizuje projekty w oparciu o autorskie metody w połączeniu z tradycyjnymi technikami szklarskimi. Przeprowadza wykłady, warsztaty projektowe ukierunkowane na zmysły, prowadzi indywidualne spotkania mentoringowe w zakresie projektowania. Stypendystka Ministerstwa Nauki i Szkolnictwa Wyższego dla młodego wybitnego naukowca. Laureatka konkursu wzorniczego Dobry Wzór, wielokrotna laureatka plebiscytu Must Have na najlepiej zaprojektowane produkty w Polsce, oraz laureatka Mazda Design
Award. Jej prace stanowią część kolekcji sztuki i wzornictwa m.in. Muzeum Narodowego w Krakowie, PURO Hotels w Łodzi i Krakowie, Muzeum Narodowego we Wrocławiu, Muzeum - Kaszubskiego Parku Etnograficznego. Uczestniczka prestiżowych wystaw i festiwali projektowych od 2008 roku, m.in.: Salone del Mobile Milano,Paris Design Week, London Design Festival, Vienna Design Week, Gdynia Design Days, Łódź Design Festiwal.
`,
  "person.i.biedron.position": "Ekspertka ds. gospodarki wodnej",
  "person.i.biedron.description":
    "Doktor nauk inżynieryjno - technicznych w dyscyplinie inżynieria\n" +
    "środowiska, specjalizująca się w zarządzaniu zasobami wodnymi\n" +
    "obejmującym problematykę powodzi, suszy oraz ochronę i\n" +
    "odtwarzanie ekosystemów rzecznych (renaturyzacja rzek).\n" +
    "Koordynatorka projektu WaterLANDS w Stowarzyszeniu Centrum\n" +
    "Ochrony Mokradeł. Niezależna ekspertka posiadająca niemal 20\n" +
    "letnie doświadczenie w realizacji projektów z zakresu gospodarki\n" +
    "wodnej w zakresie m.in. ochrony wód, zarządzania suszą i powodzią,\n" +
    "czy utrzymania rzek. W ostatnich latach kierowniczka projektów\n" +
    "realizowanych na zlecenie Wód Polskich tj. Aktualizacji map\n" +
    "zagrożenia powodziowego i map ryzyka powodziowego czy\n" +
    "Krajowego programu renaturyzacji wód powierzchniowych.\n" +
    "Kierowniczka i opiekunka merytoryczna projektu podcastowego\n" +
    "Zdrowa rzeka. Certyfikowana Project Manager PRINCE2®. Założycielka\n" +
    "Rzecznej Szkoły.",

  "person.e.jablonska.position": "Biolożka",
  "person.e.jablonska.description": `Doktor nauk biologicznych.Pracuje na Wydziale Biologii Uniwersytetu Warszawskiego. Zajmuje się ekologią roślinności bagiennej. Od początku istnienia Stowarzyszenia Centrum Ochrony Mokradeł jest odpowiedzialna za ochronę przyrody torfowisk, angażowała się w projekty edukacyjne i strażnicze.`,

  "person.r.konieczny.position": "Inżynier budownictwa wodnego",
  "person.r.konieczny.description": `Specjalista ds. ograniczania skutków powodzi z wykorzystaniem tzw. metod nietechnicznych, projektowania lokalnych systemów ostrzeżeń, budowy lokalnych planów ograniczania skutków powodzi. Wieloletni pracownik Instytutu Meteorologii i Gospodarki Wodnej, niezależny ekspert Koalicji Ratujmy Rzeki. Uczestniczył w wielu projektach
międzynarodowych finansowanych przez Unię Europejską, światową Organizację Meteorologiczną i Bank Światowy. Ukończył kilka kursów organizowanych przez NATO w zakresie łagodzenia skutków powodzi. Przez wiele lat był redaktorem naczelnym kwartalnika „Poradnik Ekologiczny dla Samorządów”.`,

  "person.m.grygoruk.position": "Hydrolog",
  "person.m.grygoruk.description": `Profesor w Katedrze Hydrologii, Meteorologii i Gospodarki Wodnej SGGW w Warszawie. Zajmuje się ilościową analizą procesów przepływu wody w krajobrazie oraz rolą rzek i mokradeł w środowisku oraz otoczeniu społecznym i gospodarczym. Badał rzeki i mokradła od północnej Norwegii po południową Polskę i od Syberii po Kanadę. Działacz organizacji pozarządowych, członek Państwowej Rady Ochrony Środowiska. W wolnym czasie wędkarz i muzyk. Większość wolnego czasu spędza na bagnach lub nad rzeką.`,

  "person.t.dot.position": "Projekt muzyczny",
  "person.t.dot.description": `Projekt powstały w Stavanger w 2014 roku. Eksperymentuje z gatunkami takimi jak IDM, ambient, folk i jazz. Wśród gości na jego albumach można znaleźć m.in.: Jexusa, Susan Jarę, Jakuba Żyteckiego, Simen Kiil Halvorsen'a, Julie Hasfjord, maga, Cinara Timura, Szymona Mikę, Koshiro Hino, Shohei Kudo, Demsky i wielu innych. Do końca 2022 roku ukazało się 11 płyt, 1 EP i kilkanaście singli nakładami wydawnictw: ONIONWAVE, RUSKA BURSA oraz ADDICTED TO MUSIC. Każda z płyt to tzw. "concept album”, w którym oprócz głównego tematu albumu pojawia się wiele rozwiązań instrumentalnych, założeń w technologii nagrania oraz twórczych ograniczeń, dzięki czemu każdy album ma swoje oryginalne brzmienie i wyróżnia się zarówno pod katem kompozycji, orkiestracji, jak i spektrum. W twórczości t dot est można wyróżnić kilka nitek m. in. podróże - album "Nordsteps", czyli wyprawa do Norwegii , "DOT" - podróż po mapie Japonii, "B in", wyprawa do Berlina. Drugą nitką są opowieści, w skład których wchodzą 4 albumy z cyklu ANTON, następnie dystopijny nurt, który zaczął się podczas pandemii w 2020, zawierający albumy
BLMRVRSD, DSTPNPLS oraz FRMTHDSTNT. Trzecia nitka to powiązania i inspiracje folkiem, cykl proekologicznych singli z wokalistami z Zimbabwe i Kongo, oraz albumy HULATY i EP Kurpiowskie - obie pozycje wspólnie z Susanną Jara oraz projektem Sestry Boczniewicz. Czwarta nitka to pojedyncze wydawnictwa, single oscylujące wokół muzyki tanecznej, setme300 oraz summer420 z wokalistką Anią Dudek, remixy, płyta Re ANTON, oraz pojedyncze produkcje od P.A.F.F, Unitra_Audio oraz Maro Music.`,

  "aboutProject.multibook.header": "Inspiracje z Islandii!",
  "aboutProject.multibook.content":
    "Zanurz się w krajobrazy pełne gejzerów, aurory borealis i majestatycznych gór. Pozwól, by islandzkie opowieści przeniosły Cię w magiczny świat niezwykłych przygód.",
  "aboutProject.carousel.1": "Realizacja projektu",
  "aboutProject.carousel.2": "Partner projektu(Polska)",
  "aboutProject.carousel.3": "Partnerzy projektu (Islandia)",
  "aboutProject.carousel.4": "Wsparcie eksperckie",
  "aboutProject.carousel.5": "Wsparcie techniczne",
  "aboutProject.carousel.6": "Honorowy patronat",

  // LMS
  "lms.listen-to-expert": "Posłuchaj experta",
  "lms.listen-to-river": "Posłuchaj rzeki",

  "lms.lesson02.sidebar-header": "Widok z lotu ptaka",
  "lms.lesson02.step1.header": "Uprawy przy przegłębionej rzece",
  "lms.lesson02.step2.header": "Uprawy przy uregulowanej rzece",
  "lms.lesson02.step3.header": "Uprawy odsunięte od rzeki",
  "lms.lesson02.step4.header": "Uprawy przy zdrowej rzece",
  "lms.lesson02.step1.description":
    "Uregulowana rzeka wyglądająca jak prosty kanał przepływa przez tereny rolnicze, które przylegają do brzegów rzeki. Brak drzew przy brzegach powoduje zarastanie koryta i potrzebę cyklicznego czyszczenia rzeki. To sprawia, że rzeka jeszcze sprawniej odprowadza wodę z krajobrazu.",
  "lms.lesson02.step2.description":
    "Uregulowana rzeka przepływa przez tereny rolnicze, które przylegają do brzegów rzeki. Nie ma przy brzegach drzew i strefy bagiennej roślinności. Rzeka sprawnie odprowadza wodę z krajobrazu.\n",
  "lms.lesson02.step3.description":
    "Rzeka uregulowana płynąca szerszym korytem przez tereny rolnicze. Meandrująca w obrębie koryta, z zaroślami przy brzegu i strefie koryta wody powodziowej, która oddziela pola uprawne i łąki od rzeki.\n",
  "lms.lesson02.step4.description":
    "Rzeka naturalna, meandrująca w dolinie, która przepływa przez lasy i trzcinowiska. Tradycyjne rolnictwo jest w znacznej odległości od rzeki.",
  "lms.lesson02.parameter-purity": "Czystość",
  "lms.lesson02.parameter-bio": "Bioróżnorodność",
  "lms.lesson02.parameter-retention": "Retencja",
  "lms.lesson02.welcome.title": "Dno rzeki",
  "lms.lesson02.welcome.description":
    "Kiedy rzeka będzie zdrowa? Dowiedz się od Ilony Biedroń, założycielki Rzecznej Szkoły i opiekunki merytorycznej tego eksperymentu o tym, co się wydarzy, gdy zaingerujesz w dno rzeki.",
  "lms.lesson02.bonus.title": "Bagienne strefy buforowe",
  "lms.lesson05.step.header": "Krok {current} z {total}",
  "lms.lesson05.step1.description": "Wybierz przedmiot, który będziesz projektować.",
  "lms.lesson05.step2.description":
    "Czy lubisz obserwować rzekę? Jaka Cię inspiruje? Jaka jest rzeka?\n" +
    "Wybierz haslo, które pozwoli Ci zmodyfikować Twój przedmiot.",
  "lms.lesson05.step3.description":
    "Czy zwracasz uwagę na wielobarwność rzecznych krajobrazów?\n" +
    "Zainspiruj się kolorami w przyrodzie i wybierz paletę barw dla Twojego przedmiotu.",
  "lms.lesson05.select-slogan.header": "Wybierz hasło",
  "lms.lesson05.select-theme.header": "Wybierz paletę kolorów",
  "lms.lesson05.summary.header": "Podsumowanie - zaprojektowany przedmiot",
  "lms.lesson05.summary.description": `Brawo! Etap projektowania inspirowanego naturą zakończony. Zastanów się jak ten proces możesz zaadoptować do swoich pomysłów.\nKliknij "Zacznij od nowa" i spróbuj jeszcze raz. `,
  "lms.lesson05.slogan.frozen": "Zamarznięta",
  "lms.lesson05.slogan.lifegiving": "Życiodajna",
  "lms.lesson05.slogan.wavy": "Falująca",
  "lms.lesson05.slogan.lazy": "Leniwa",
  "lms.lesson05.slogan.meandering": "Meandrująca",
  "lms.lesson05.slogan.dynamic": "Dynamiczna",
  "lms.lesson05.slogan.calm": "Spokojna",
  "lms.lesson05.slogan.alive": "Żywa",
  "lms.lesson05.slogan.fastpaced": "Wartka",
  "lms.lesson05.slogan.flowing": "Rozlana",
  "lms.lesson05.slogan.refreshing": "Orzeźwiająca",
  "lms.lesson05.slogan.rushing": "Rwąca",
  "lms.lesson05.object.dish": "Naczynie",
  "lms.lesson05.object.fabric": "Tkanina",
  "lms.lesson05.object.architecture": "Mała architektura",
  "lms.lesson05.object.package": "Opakowanie",
  "lms.lesson05.setup.object": "Forma",
  "lms.lesson05.setup.slogan": "Hasło",
  "lms.lesson05.setup.theme": "Kolory",
  "lms.lesson05.setup.choose-object": "Wybierz formę",
  "lms.lesson05.setup.choose-slogan": "Wybierz hasło",
  "lms.lesson05.setup.choose-theme": "Wybierz kolory",
  "lms.lesson05.setup.button-back": "Cofnij",
  "lms.lesson05.setup.button-reset": "Zacznij od nowa",
  "lms.lesson05.bonus.title": "Agnieszka Aleksandra Bar",
  "lms.lesson05.bonus.description":
    "Wrażliwa, empatyczna istota. Projektantka, wizjonerka, eksperymentatorka. Tworzy przedmioty wzbogacające codzienne doświadczenia. Drażni zmysły i umysły serwując w naczyniach pustkę dla refleksji. Uważna i czuła. Patrzy głęboko w oczy i słucha. \n" +
    "\n" +
    "Urodziła się w Sudetach, w regionie o bogatych tradycjach szklarskich. Absolwentka Wzornictwa i Sztuki Szkła na Akademii Sztuk Pięknych we Wrocławiu. Przez 12 lat współtworzyła program edukacyjny w Katedrze Szkła na Wydziale Ceramiki i Szkła na wrocławskiej ASP i prowadziła projekty badawcze. \n" +
    "\n" +
    "Tworzy markę osobistą, w której koncentruje się na projektowaniu przedmiotów funkcjonalnych zapraszających do doświadczania materii oraz na realizacjach unikatowych, w których zawiera metafory i ważne przekazy. Zajmuje się projektowaniem wizerunkowym dla wyjątkowych brandów i inicjatyw. Realizuje projekty w oparciu o autorskie metody w połączeniu z tradycyjnymi technikami szklarskimi. Przeprowadza wykłady, warsztaty projektowe ukierunkowane na zmysły, prowadzi indywidualne spotkania mentoringowe w zakresie projektowania. \n" +
    "\n" +
    "Stypendystka Ministerstwa Nauki i Szkolnictwa Wyższego dla młodego wybitnego naukowca. Laureatka konkursu wzorniczego Dobry Wzór, wielokrotna laureatka plebiscytu Must Have na najlepiej zaprojektowane produkty w Polsce, oraz laureatka Mazda Design Award. Jej prace stanowią część kolekcji sztuki i wzornictwa m.in. Muzeum Narodowego w Krakowie, PURO Hotels w Łodzi i Krakowie, Muzeum Narodowego we Wrocławiu, Muzeum - Kaszubskiego Parku Etnograficznego. Uczestniczka prestiżowych wystaw i festiwali projektowych od 2008 roku, m.in.: Salone del Mobile Milano, Paris Design Week, London Design Festival, Vienna Design Week, Gdynia Design Days, Łódź Design Festiwal.",
  "lms.lesson05.welcome.title": "Projektowanie inspirowane naturą",
  "lms.lesson05.welcome.description":
    "Natura jest źródłem niewyczerpanych inspiracji oraz doskonałym nauczycielem w sztuce projektowania.  Uczy nas podejścia do tworzenia, które czerpie z naturalnych form, procesów i adaptacji w środowisku przyrodniczym. Obserwacja  przyrody oraz doświadczanie jej zmysłami wpływa na naszą kreatywność i zdolność rozwiązywania problemów.\n" +
    "\n" +
    "Przeżyj fascynującą podróż przez proces twórczy z opiekunką tego eksperymentu - Agnieszką Bar.",
  "lms.lesson05.bonus.option.idea": "Idea",
  "lms.lesson05.bonus.option.design": "Projektowanie",
  "lms.lesson05.bonus.option.implementation": "Realizacja",
  "lms.lesson05.bonus.option.product": "Produkt",
  "lms.lesson05.bonus.option.author": "O autorce",
  "lms.lesson05.bonus.design.photo1.description": "Czas nad rzeką - obserwacja, doświadczanie zmysłami.",
  "lms.lesson05.bonus.design.photo2.description": "Rysunek koncepcji/pomysłu.",
  "lms.lesson05.bonus.design.photo3.description": "Szkic struktury inspirowanej wodą i wiklinowymi splotami.",
  "lms.lesson05.bonus.design.photo4.description": "Planowanie struktury na powierzchni naczyń - misa.",
  "lms.lesson05.bonus.design.photo5.description": "Planowanie struktury na powierzchni naczyń - waza.",
  "lms.lesson05.bonus.implementation.photo1.description": "Wyplatanie osłon z wikliny.",
  "lms.lesson05.bonus.implementation.photo2.description":
    "Wdmuchiwanie i odciskanie gorącego szkła w wiklinowej osłonie.",
  "lms.lesson05.bonus.implementation.photo3.description": "Wiklinowa osłona po zetknięciu z gorącym szkłem.",
  "lms.lesson05.bonus.implementation.photo4.description": "Schłodzone szkło z odciśniętą strukturą wikliny.",
  "lms.lesson05.bonus.implementation.photo5.description": "Rzemieślnik i projektantka przy pracy w studio szklarskim.",
  "lms.lesson05.bonus.product.photo1.description": "Fotografia produktowa.",
  "lms.lesson05.bonus.product.photo2.description": "Misa FLOW.",
  "lms.lesson05.bonus.product.photo3.description": "Widok na strukturę w dnie naczynia.",
  "lms.lesson05.bonus.product.photo4.description": "Falujące obrzeże naczynia.",
  "lms.lesson05.bonus.product.photo5.description": "Fotografia opowiadająca historię projektu - waza płynąca po Odrze.",
  "lms.lesson05.bonus.intro.title": "FLOW - seria obiektów naczyniowych",
  "lms.lesson05.bonus.intro.description":
    "FLOW - seria obiektów użytkowych inspirowanych rzekami, będąca częścią większej Kolekcji THE INNER POWER.\n" +
    "\n" +
    " Jako projektantka i wrażliwa na otaczający mnie świat istota, mam misję, by za pomocą swoich projektów/prac komunikować się z odbiorcami i użytkownikami. Korzystam z tej okazji, by mówić o sprawach ważnych, bliskich memu sercu. Jedną z nich jest dbałość o naturę, szacunek do jej ograniczonych zasobów, oraz traktowanie jej jak siostrę.\n" +
    "\n" +
    "Mieszkam we Wrocławiu, nad rzeką Odrą. Często spaceruję wzdłuż wałów przy pobliskiej rzece Widawie. W ciągłym ruchu, zmienna, życiodajna, stała się dla mnie wytchnieniem i natchnieniem w czasie pandemii. Dlatego postanowiłam stworzyć przedmioty, które są kwintesencją meandrującej odżywczej wody.\n" +
    "\n" +
    "Posłużyłam się autorską techniką wdmuchiwania i odciskania szkła do elastycznych wiklinowych plecionek. Traktuję tradycyjne techniki z szacunkiem ale i z przymrużeniem oka, bo najbardziej oryginalne projekty powstają wtedy, gdy oddaję się w wir eksperymentów. Moją metodę rozwinęłam w oparciu o starą tradycję kultywowaną na Kaszubach, gdzie wykorzystywano wiklinę do gacenia (zabezpieczania) brzegów jezior oraz zabezpieczania domostw przed wodą i wilgocią. Wykorzystałam naturalne cechy wierzbowych gałązek, aby w wyniku wysokiej temperatury szkła, uzyskać fakturę falującej wody na powierzchni naczyń. Moją intencją jest by przedmioty FLOW towarzyszyły nam w domach i przypominały o tym, czego czego możemy nauczyć się od rzek?, jak mogą nas wspierać w codzienności?, jak my możemy zadbać o to, aby je chronić?",
  "lms.lesson04.intro.title": "Pocztówka dźwiękowa",
  "lms.lesson04.intro.title-left": "Proces twórczy inspirowany naturą",
  "lms.lesson04.intro.description-left":
    "Zatrzymaj się na chwilę. Zamknij oczy by sprawdzić co słyszysz. Krajobraz dźwiękowy to coś, o czym często nie myślimy, dopóki go nie zabraknie. Dla Ciebie to może być sama muzyka, a dla niektórych badaczy pejzaż dźwiękowy jest informacją o kondycji naszej planety.",
  "lms.lesson04.intro.title-right": "Praca nad utworem",
  "lms.lesson04.intro.description-right":
    "Udostępniamy Ci narzędzie do stworzenia pocztówki dźwiękowej oraz dźwięki nagrane i zainspirowane krajobrazem Polski i Islandii. Przez proces tworzenia muzyki przeprowadzi Cię opiekun tego tego eksperymentu t dot est.",
  "lms.lesson04.place.listen-to": "Posłuchaj",
  "lms.lesson04.place.watch": "Obejrzyj",
  "lms.lesson04.place.headphones-info1": "Chcesz usłyszeć wszystkie detale?",
  "lms.lesson04.place.headphones-info2": "Załóż słuchawki przed odsłuchaniem.",
  "lms.lesson04.iceland.1.title": "Little White & Little Grey \n" + "Białuchy arktyczne",
  "lms.lesson04.iceland.1.description":
    "Little White i Little Grey przebyły 9000 km ze swojego poprzedniego domu w parku wodnym w Szanghaju do <a href='https://www.facebook.com/SEALIFETrust/' target='_blank'>Sanktuarium</a>, położonego w dużej, naturalnej zatoce na  wyspie Vestmannaeyjar. \n" +
    "Little Grey jest niezwykle ciekawska i wokalna, wydaje różnorodne dźwięki zespołowi opiekującemu, gdy chce wyrazić siebie. Jest bardzo zainteresowana wszystkim, co nowe i często lubi być w centrum uwagi.",
  "lms.lesson04.iceland.2.title": "W lodowcowej szczelinie",
  "lms.lesson04.iceland.2.description": "Dźwięki Sólheimajökull (Lodowca Słońca).",
  "lms.lesson04.iceland.3.title": "Wodospad nad rzeką Skoga",
  "lms.lesson04.iceland.3.description": "Dźwiękowa okolica wodospadu Skogafoss.",
  "lms.lesson04.iceland.4.title": "Lodowcowa laguna",
  "lms.lesson04.iceland.4.description":
    "Jökulsárlón Glacier Lagoon to oszałamiające jezioro lodowcowe utworzone przez topnienie lodowca Breiðamerkurjökull. Laguna jest znana ze swojego surrealistycznego piękna, gdzie dryfujące góry lodowe różnych kształtów i rozmiarów unoszą się łagodnie po spokojnych, lodowatych wodach wydając przy tym ciekawe odgłosy.",
  "lms.lesson04.iceland.5.title": "Siedlisko ptaków",
  "lms.lesson04.iceland.5.description":
    "Dyrhólaey jest jednym z najatrakcyjniejszych na Islandii miejsc do obserwowania ptaków morskich. Skały cypla i otaczających go wysepek roją się od nurzyków i mew, nietrudno spotkać tam również maskonury.",
  "lms.lesson04.odra.1.title": "Graniczne Meandry Odry",
  "lms.lesson04.odra.1.description":
    "Unikalny pomnik przyrody naturalnie meandrującego toku rzeki ze ślepymi ramionami i rozlewiskami. Klejnot przyrodniczy Europy Środkowej. \n" +
    "Fot. Piotr Nieznański.",
  "lms.lesson04.odra.2.title": "Rezerwat przyrody Łacha Jelcz",
  "lms.lesson04.odra.2.description":
    "Wodny rezerwat obejmujący starorzecze Odry obejmujący cenne siedliska chronionych gatunków roślin, ptaków wodno - błotnych, płazów i owadów. Najlepsza pora do zwiedzania tego miejsca to kwiecień i lipiec.\n" +
    "Fot. Anna Grebieniow",
  "lms.lesson04.odra.3.title": "Ścinawa",
  "lms.lesson04.odra.3.description":
    "Wrota do Krainy Łęgów Odrzańskich w których znajduje się Dźwiękowy Szlak Odry - w specjalnych ławkach możecie posłuchać rzeki i jej odgłosów zarówno naturalnych, jak i tych, które tworzy na niej człowiek.",
  "lms.lesson04.odra.4.title": "Gostchorze",
  "lms.lesson04.odra.4.description":
    "Miejsce potocznie zwane „stromą ścianą” - wysoki na prawie 50m klif otulony ciepłolubnym grądem jest najlepszym w okolicy punktem obserwacji pradoliny Odry w jej środkowym biegu.",
  "lms.lesson04.odra.5.title": "Krosno Odrzańskie",
  "lms.lesson04.odra.5.description":
    "Miejsce powstania Stowarzyszenia 515. Między ostrogami leży zatopiona łódź płaskodenna, kilometr dalej rzeka Bóbr wpada do Odry, a na dolinę patrzy nadodrzańska Winnica Marcinowice.",
  "lms.lesson04.odra.6.title": "Park Narodowy Ujście Warty",
  "lms.lesson04.odra.6.description":
    "Położony w historycznej delcie ujścia Warty do Odry jest unikalną na skalę europejską ostoją ptaków wodnych i błotnych. Ptasi tłok 279 gatunków czyni to miejsce idealnym do obserwacji, badań oraz edukacji.",
  "lms.lesson04.odra.7.title": "Międzyodrze",
  "lms.lesson04.odra.7.description":
    "Europejska Amazonia. <br/> Wielka różnorodność fauny i flory, dzika kraina torfowisk, podmokłych łąk sprawia, że miejsce to objęte jest inicjatywą utworzenia Parku Narodowego Doliny Dolnej Odry.\n" +
    "Fot. Piotr Piznal",

  "lms.lesson04.gallery.1.title": "Na lodowcu Sólheimajökull",
  "lms.lesson04.gallery.1.description":
    "Lodowiec Sólheimajökull (Lodowiec Słońca) to najbardziej wysunięty na południe lodowiec Islandii. Dźwiękowe poszukiwania brzmienia rzek rodzących się na lodowcu\n" +
    " nie byłby możliwe bez profesjonalnego wsparcia naszego partnera z Islandii - <a href='https://www.facebook.com/IceWalkers.Tours' target='_blank'>Ice Walkers Tours</a>, który bezpiecznie przeprowadził nas pomiędzy meandrami szczelin.",
  "lms.lesson04.gallery.2.title": "Nagrywanie dźwięków w szczelinie lodowca",
  "lms.lesson04.gallery.2.description":
    "Lodowiec Słońca topnieje dość szybko. Uważa się, że średnie topnienie w ciągu roku wynosi około 60 metrów długości oraz około 10 do 20 metrów grubości. \n" +
    "Oznacza to, że wiele szczelin po których wspinała się Karolina Jaruszewska, by nagrać dźwięki ambisonicznym rejestratorem, za rok już może nie istnieć.",
  "lms.lesson04.gallery.3.title": "Doświadczeni specjaliści",
  "lms.lesson04.gallery.3.description":
    "Ania Grebieniow - operatorka drona i fotografka z dużym doświadczeniem pracy w trudnym terenie.  Dwukrotnie pracowała jako obserwator ekologiczny w bazie terenowej\n" +
    "Polskiej Stacji Antarktycznej na Wyspie Króla Jerzego. Zawsze ma ze sobą najwięcej elektroniki i rozpoznaje niemal każdy gatunek ptaka.",
  "lms.lesson04.gallery.4.title": "W bezwietrzny wieczór na lagunie Fjallsárlón",
  "lms.lesson04.gallery.4.description":
    "O Islandii mówi się, że jest wietrzną i często nieprzyjazną wyspą. Kiedy przyjechać na Islandię? Nam termin wybrała Magda Bobryk, założycielka Stowarzyszenia 515, która jest również\n" +
    "przewodnikiem po Islandii. Ponad 17 razy odwiedziła wyspę i nie tylko wie kiedy, ale też gdzie się udać, żeby w sposób spokojny, bez tłumów, obserwować przyrodę.",
  "lms.lesson04.gallery.5.title": "W rezerwacie przyrody Dyrhólaey ",
  "lms.lesson04.gallery.5.description":
    "Dyrhólaey jest rezerwatem przyrody - wizyty w tym miejscu są ograniczone w okresie między 1 maja, a 25 czerwca ze względu na ochronę ptactwa. \n" +
    "Nagrywaliśmy odgłosy edredonów zwyczajnych, fotografowaliśmy maskonury oraz zapierające dech w piersiach widoki na 120-metrowej promenadzie Półwyspu Dyrhólaey. ",
  "lms.lesson04.gallery.6.title": "Na wulkanicznej wyspie Heimaey",
  "lms.lesson04.gallery.6.description":
    "Na jedynej zamieszkałej wyspie Archipelagu Vestmannaeyjar, do której można dostać się jedynie promem obserwowaliśmy i dokumentowaliśmy świat przyrody. \n" +
    "Strome i piękne klify to kamienice krzyku zamieszkałe przez mewy, nurzyki, rybitwy oraz maskonury.  W tych okolicach krwawodzioby usilnie starały się uziemić naszego drona.",
  "lms.lesson04.odra.extra-headphones-info":
    "Próbka dźwiękowa powstała na podstawie nagrań terenowych Michała Zygmunta.",
  "lms.lesson04.iceland-postcard.header": "Pocztówka dźwiękowa z Islandii",
  "lms.lesson04.iceland-postcard.instruction":
    "Przeciągnij kolorowe kafelki na puste pola i skomponuj swój utwór z <a href='https://tdotest.com/' target='_blank'>t dot est</a>.",
  "lms.lesson04.iceland-postcard.more-button": "Więcej",
  "lms.lesson04.iceland-postcard.play-button": "Play",
  "lms.lesson04.iceland-postcard.stop-button": "Stop",
  "lms.lesson04.iceland-postcard.reset-button": "Reset",
  "lms.lesson04.iceland-postcard.download-button": "Download",
  "lms.lesson04.iceland-postcard.sample.sea": "Morze i prom",
  "lms.lesson04.iceland-postcard.sample.ice": "Trzaskający lód",
  "lms.lesson04.iceland-postcard.sample.synth-bass": "Syntezator basowy",
  "lms.lesson04.iceland-postcard.sample.synth": "Syntezator",
  "lms.lesson04.iceland-postcard.sample.piano-right-hand": "Pianino prawa ręka",
  "lms.lesson04.iceland-postcard.sample.piano-left-hand": "Pianino lewa ręka",
  "lms.lesson04.iceland-postcard.sample.drums-low": "Perkusja niskobrzmiąca",
  "lms.lesson04.iceland-postcard.sample.drums-high": "Perkusja wysokobrzmiąca",
  "lms.lesson04.iceland-postcard.sample.sing1": "Śpiew 1",
  "lms.lesson04.iceland-postcard.sample.sing2": "Śpiew 2",
  "lms.lesson04.iceland-postcard.info-modal.title": "Islandia w pętlach",
  "lms.lesson04.iceland-postcard.info-modal.subtitle":
    "Instrukcja do stworzenia krótkiego utworu w gatunku ambient na podstawie przygotowanych wcześniej pętli audio",
  "lms.lesson04.iceland-postcard.info-modal.content":
    "Wybierz pętle audio, takie jak nagrania pianina, głosy ludzkie i syntezatory. Upewnij się, że każda pętla ma odpowiednią długość, aby harmonijnie łączyć się z innymi dźwiękami.\n" +
    "Wprowadź elementy fields recording, takie jak śpiew ptaków, nurt wody, szum gorących źródeł lub odlegle płynące statki. Połącz te nagrania z syntezatorami, głosami ludzkimi i pianinem, aby stworzyć kontrastowe i nieoczekiwane połączenia dźwiękowe.\n" +
    "Kontynuuj eksperymentowanie i dostosowywanie kompozycji, dopóki nie osiągniesz oczekiwanego efektu ambientowego utworu. Pamiętaj, że nie ma granic dla wyobraźni i kreatywności w tworzeniu muzyki ambient.\n" +
    " Słuchaj samodzielnie lub zgraj utwór do pliku i podziel się swoim utworem z innymi słuchaczami, aby cieszyć się magią i spokojem, które niesie ze sobą muzyka inspirowana naturą.",
  "lms.lesson04.iceland-postcard.info-modal.info":
    "Pętle audio w tym eksperymencie zostały przygotowane przez t dot est na podstawie nagrań terenowych z Islandii udostepnionych przez Noise from Iceland. Jeśli chcesz przesłuchać oryginalne nagrania terenowe odwiedź stronę!",
  "lms.lesson04.iceland-postcard.info-modal.source-website-button": "Noise from Iceland",
  "lms.lesson-item-navigation.button-main-page": "Strona główna",
  "lms.lesson-item-navigation.button-bonus": "Bonus",
  "lms.lesson-item-navigation.experiment-bonus": "Eksperyment",
  "lms.lesson04.odra-postcard.title": "Pocztówka dźwiękowa znad Odry",
  "lms.lesson04.odra-postcard.instruction":
    "Włącz lub wyłącz poszczególne ścieżki przyciskami <br />" +
    "po lewej stronie i skomponuj swój utwór z <a href='https://tdotest.com/' target='_blank'>t dot est.</a>",
  "lms.lesson04.odra-postcard.sample.piano": "Pianino",
  "lms.lesson04.odra-postcard.sample.synth-bass": "Syntezator basowy",
  "lms.lesson04.odra-postcard.sample.swamp": "Odgłosy bagien",
  "lms.lesson04.odra-postcard.sample.cuckoo": "Kukułka, żuraw, kaczka",
  "lms.lesson04.odra-postcard.sample.drums": "Perkusja wysokobrzmiąca",
  "lms.lesson04.odra-postcard.sample.owl": "Puszczyk, dzięcioł, bielik",
  "lms.lesson04.odra-postcard.sample.water": "Rytm wody i lin stoczniowych",
  "lms.lesson04.odra-postcard.sample.instruments": "Instrumenty smyczkowe",
  "lms.lesson04.odra-postcard.sample.synth": "Syntezator",
  "lms.lesson04.odra-postcard.info-modal.title": "Odra progresja",
  "lms.lesson04.odra-postcard.info-modal.subtitle": "Instrukcja do stworzenia utworu inspirowanego dźwiękami rzeki",
  "lms.lesson04.odra-postcard.info-modal.content":
    "Na początku utworu, użyj ścieżki z nagranym pianinem o umiarkowanej głośności, aby wprowadzić melodyczną i spokojną atmosferę. Wprowadź ścieżki z nagranymi śpiewami ptaków, zwiększając ich głośność stopniowo, aby stworzyć wrażenie narastającej energii.\n" +
    "Następnie włącz perkusyjną ścieżkę z nagranym nurtem rzeki na tle innych dźwięków, utrzymując jej głośność na umiarkowanym poziomie tak, aby dodać pulsu do całego utworu.\n" +
    "Później, włącz ścieżkę z nagranymi syntezatorami, zwiększając jej głośność sukcesywnie, aby wzbogacić dźwiękową teksturę utworu. Kontynuuj manipulowanie automatyzacji głośności poszczególnych ścieżek w trakcie trwania utworu, aby wyeksponować różne elementy i stworzyć dynamiczną progresję.\n" +
    "Pamiętaj, że zmiana głośności w czasie powinna być płynna i precyzyjna, aby uniknąć nagłych skoków, a jednocześnie ożywić perspektywę i pogłębić dźwiękową przestrzeń.\n" +
    "Zakończ utwór, stopniowo obniżając głośność wszystkich ścieżek audio, aby powoli wygaszać dźwięki i stworzyć efekt rozwiązania.\n" +
    `Przejdź przez utwór, przesłuchaj go dokładnie i upewnij się, że zmiany głośności są płynne i pożądane, poprawiając je według potrzeb. "Odra progresja" jest teraz gotowa do odsłuchu, zgrania i dzielenia z przyjaciółmi!`,
  "lms.lesson04.odra-postcard.info-modal.info":
    "Poszczególne ścieżki audio w tym eksperymencie zostały przygotwane przez t dot est na podstawie nagrań terenowych znad Odry udostępnionych przez Michała Zygmunta. Jeśli chcesz przesłuchać oryginalne nagrania terenowe odwiedź stronę!",
  "lms.lesson04.odra-postcard.info-modal.source-website-button": "Dźwiękowy Szlak Odry",
  "lms.lesson04.odra.1.headphones-extra-info":
    "Próbka dźwiękowa skomponowana przez t dot est zainspirowana nadodrzańskim krajobrazem.",
  "lms.lesson04.odra.2.headphones-extra-info":
    "Próbka dźwiękowa powstała na podstawie nagrań terenowych Michała Zygmunta.",
  "lms.lesson04.odra.3.headphones-extra-info":
    "Próbka dźwiękowa powstała na podstawie nagrań terenowych Michała Zygmunta.",
  "lms.lesson04.odra.4.headphones-extra-info":
    "Próbka dźwiękowa powstała na podstawie nagrań terenowych Michała Zygmunta.",
  "lms.lesson04.odra.5.headphones-extra-info":
    "Próbka dźwiękowa skomponowana przez t dot est zainspirowana nadodrzańskim krajobrazem.",
  "lms.lesson04.odra.6.headphones-extra-info":
    "Próbka dźwiękowa powstała na podstawie nagrań terenowych Michała Zygmunta.",
  "lms.lesson04.odra.7.headphones-extra-info":
    "Próbka dźwiękowa powstała na podstawie nagrań terenowych Michała Zygmunta.",
  "lms.lesson03.welcome.title": "Spotkanie z rzeką",
  "lms.lesson03.welcome.description":
    "Pomyśl. Czy zdarzyło Ci się kiedyś wejść w kontakt z rzeką? Ale tak naprawdę. Zanurzyć się w wodzie, poczuć na twarzy chłód górskiej wody, zobaczyć tętniący życiem ekosystem wokół mokradła? Idź nad rzekę, podpowiemy Ci co możesz tam robić.",
  "lms.lesson03.bonus.1.title": "FLY FISHING na Bobrze",
  "lms.lesson03.bonus.2.title": "NURKOWANIE w Biebrzy",
  "lms.lesson03.bonus.3.title": "FOTOGRAFOWANIE w ujściu Warty",
  "lms.lesson03.bonus.4.title": "BRODŹING w Świdrze",
  "lms.lesson03.bonus.5.title": "PACRAFTEM po Makombe",
  "lms.lesson03.bonus.6.title": "PRZELOT nad czerwoną rzeką",
  "lms.lesson03.bonus.1.description":
    "© Hubert Sempoch <br /> Hubert Sempoch - pasjonat fotografii i wędkarstwa muchowego. Współzałożyciel Stowarzyszenia Przyjaciół Rzeki Kwisy. Materiał nagrany na rzece Bóbr.",
  "lms.lesson03.bonus.2.description":
    "© Małgorzata Owczarska & Agnieszka Zych <br /> Nurkowanie w jedynej rzece w Polsce - Biebrzy, na której wytyczony jest oficjalnie szlak nurkowy.<br />" +
    "Materiał wideo powstał w ramach Grantu NCN SONATA16, nr 2020/39/D/Hs3/00618, „Doświadczenie nadmiaru, braku i zrównoważonej obecności wody. Studium w nurcie błękitnej antropologii”.",
  "lms.lesson03.bonus.3.description":
    "© Piotr Chara <br /> Piotr Chara - fotograf dzikiej przyrody, przyrodnik, regionalista. Twórca Wysp Życia, Prezes Fundacji Zielonej Doliny Odry i Warty. <a href='www.fzdow.pl'>www.fzdow.pl</a>",
  "lms.lesson03.bonus.4.description":
    "© Pies Hiro z opiekunką Małgorzatą Owczarską <br /> Brodzing jest formą spacerowania po płytkiej i co bardzo ważne - nie górskiej rzece. Wybierz takie miejsce do aktywności, żeby nie niszczyć fauny i flory strumienia.<br />" +
    "Materiał wideo powstał w ramach Grantu NCN SONATA16, nr 2020/39/D/Hs3/00618, „Doświadczenie nadmiaru, braku i zrównoważonej obecności wody. Studium w nurcie błękitnej antropologii”.",
  "lms.lesson03.bonus.5.description":
    "© Dominik Szmajda <br /> Wybierz się na wyprawę poza szlakiem! Spłyń meandrami dzikiej rzeki z Dominikiem Szmajdą - fotografem, laureatem nagrody Kolosy. <a href='www.dominikszmajda.com'>www.dominikszmajda.com</a>",
  "lms.lesson03.bonus.6.description":
    "© Virtual Dream - Piotr Łój / Erupcja islandzkiego wulkanu Meradalir,  2022r.  <br /> Materiał stworzony dla osób, które wyłącznie za pomocą VR mogą zwiedzać świat zewnętrzny. Więcej na <a href='https://www.patronite.pl/VirtualDream'>www.patronite.pl/VirtualDream</a>",
  "lms.lesson01.legend.o2": "Tlen",
  "lms.lesson01.legend.co2": "Dwutlenek węgla",
  "lms.lesson01.legend.peat": "Torf",
  "lms.lesson01.legend.vegetation": "Roślinność bagienna",
  "lms.lesson01.legend.mineral": "Podłoże mineralne",
  "lms.lesson01.legend.water": "Woda",
  "lms.lesson01.legend.vegetation-top": "Roślinność niebagienna",
  "lms.lesson01.legend.rot": "Rozłożony torf (mursz)",
  "lms.lesson01.legend.emission": "Emisja CO2",
  "lms.lesson01.switch.m-4000": "-4000 lat",
  "lms.lesson01.switch.m-3000": "-3000 lat",
  "lms.lesson01.switch.m-2000": "-2000 lat",
  "lms.lesson01.switch.m-1000": "-1000 lat",
  "lms.lesson01.switch.m-100": "-100 lat",
  "lms.lesson01.switch.m-90": "-90 lat",
  "lms.lesson01.switch.today": "Dzisiaj",
  "lms.lesson01.switch.p-100": "+100 lat",
  "lms.lesson01.swamp-legend-title": "Przekrój bagna",
  "lms.lesson01.side-header": "Przekrój bagna",
  "lms.lesson01.decision.header": "A teraz musisz podjąć decyzję co zrobisz z bagnem.",
  "lms.lesson01.decision.subheader": "Dokonaj wyboru i sprawdź, jak będzie wyglądać Twoje miejsce za 100 lat.",
  "lms.lesson01.decision.a-description":
    "Będę dalej osuszał i wypasał krowę, bo mleko od krów pasących się na łące jest najsmaczniejszse.",
  "lms.lesson01.decision.a-button": "Osuszam",
  "lms.lesson01.decision.b-description":
    "Zasypię rów, nawet jeśli bym już nie mógł paść krów, ani kosić łąki, i będę hodował bagno dla przyrody i klimatu",
  "lms.lesson01.decision.b-button": "Zasypuję",
  "lms.lesson01.welcome.title": "Bagno",
  "lms.lesson01.welcome.description":
    "Wybierz się na bagna z Ewą Jabłońską. Ekspertka mokradeł zabierze Cię w podróż w czasie. Zobaczysz przeszłość i zadecydujesz o przyszłości.",
  "lms.lesson01.bonus.title": "Mokradła",
};
