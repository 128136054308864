export function isStorageAvailable(): boolean {
  try {
    window.localStorage.key(0); //dump action on local storage
  } catch (err) {
    return false;
  }

  return true;
}

export const STORAGE_AVAILABLE = isStorageAvailable();
