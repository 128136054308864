import React from "react";
import classnames from "classnames";

interface SliderProps {
  activeStep: number;
  onStepClick: (step: number) => void;
}

import styles from "./Slider.module.scss";

export const Slider: React.FC<SliderProps> = ({ activeStep, onStepClick }) => {
  const elements = Array(4)
    .fill(null)
    .map((_, index) => index + 1)
    .reverse()
    .flatMap((v) => [
      <div
        key={v}
        className={classnames(styles.button, {
          [styles.active]: activeStep === v,
        })}
        onClick={() => onStepClick(v)}
      >
        {v}
      </div>,
      <div key={v + "line"} className={styles.line} />,
    ]);

  //remove line at the end
  elements.pop();

  return <div className={styles.root}>{elements}</div>;
};
