import React, { useMemo } from "react";

import architectureImg from "@src/assets/lms/lesson-05/mala-architektura.png";
import packageImg from "@src/assets/lms/lesson-05/opakowanie.png";
import dishImg from "@src/assets/lms/lesson-05/naczynie.png";
import fabricImg from "@src/assets/lms/lesson-05/tkanina.png";

import styles from "./SelectObject.module.scss";

interface Option {
  img: string;
  id: string;
  text?: string;
}

import { Options } from "../options/Options";
import { useObjectName } from "@src/components/extensions/layouts/lesson-05/helper";
import { ObjectType } from "@src/components/extensions/layouts/lesson-05/types";

const OPTIONS: Option[] = [
  {
    img: dishImg,
    id: "dish",
  },
  {
    img: fabricImg,
    id: "fabric",
  },
  {
    img: architectureImg,
    id: "architecture",
  },
  {
    img: packageImg,
    id: "package",
  },
];

interface SelectObjectProps {
  onSelect: (id: ObjectType) => void;
}

export const SelectObject: React.FC<SelectObjectProps> = ({ onSelect }) => {
  const objectName = useObjectName();

  const options = useMemo(
    () =>
      OPTIONS.map((o) => ({
        ...o,
        text: objectName(o.id),
      })),
    [objectName]
  );

  return (
    <Options
      additionalClassName={styles.root}
      options={options}
      itemWidth={"35%"}
      onSelect={onSelect as (id: string) => void}
    />
  );
};
