import React from "react";
import { Button as CoreButton } from "@jelly/ui";

import defaultStyle from "./Button.module.scss";

export const Button: React.FC<React.ComponentProps<typeof CoreButton>> = (props) => {
  return <CoreButton {...props} classNames={defaultStyle} />;
};

import carouselButtonStyle from "./CarouselButton.module.scss";

export const CarouselButton: React.FC<React.ComponentProps<typeof CoreButton>> = (props) => {
  return <CoreButton {...props} classNames={carouselButtonStyle} />;
};
