import React from "react";
import { LessonContextProvider, useUi, useLayouts, useComponents, useBlocks } from "@jelly/lesson";

interface UIOverrideProps {
  overrides: Partial<React.ComponentProps<typeof LessonContextProvider>>;
  children: React.ReactNode;
}
export const UIOverride: React.FC<UIOverrideProps> = ({ overrides, children }) => {
  const blocks = useBlocks();
  const components = useComponents();
  const layouts = useLayouts();
  const ui = useUi();

  return (
    <LessonContextProvider
      ui={{ ...ui, ...overrides.ui }}
      layouts={{ ...layouts, ...overrides.layouts }}
      components={{ ...components, ...overrides.components }}
      blocks={{ ...blocks, ...overrides.blocks }}
    >
      {children}
    </LessonContextProvider>
  );
};
