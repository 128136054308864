import React from "react";

import styles from "./Annotation.module.scss";

export interface AnnotationProps {
  text: string;
}
export const Annotation: React.FC<AnnotationProps> = ({ text }) => {
  return <span className={styles.root}>{text}</span>;
};
