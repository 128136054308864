import React, { useCallback, useState } from "react";

import styles from "./OdraPostcard.module.scss";
import { useUi } from "@jelly/lesson";
import { SampleTile } from "@src/components/extensions/layouts/lesson-04/components/SampleTile";
interface OdraPostcardProps {}

import { availableSamples, Sample } from "./samples";
import { useIntl } from "react-intl";
import classnames from "classnames";
import { Switch } from "@src/components/extensions/ui/switch/Switch";

import { messages } from "./OdraPostcard.messages";
import { PlayerControls } from "@src/components/extensions/layouts/lesson-04/components/PlayerControls";
import { InfoModal } from "@src/components/extensions/layouts/lesson-04/odra-postcard/components/InfoModal";
import {
  usePostcardPlayer,
  VOLUME_LEVELS,
} from "@src/components/extensions/layouts/lesson-04/odra-postcard/OdraPostcardPlayer";
import { Slider } from "@src/components/extensions/ui/slider/SliderAudio";

// workaround - Switch is using "primary"  / "secondary" convention, while the SampleTile uses "Yellow" / "green".
// here is a map to translate between them

const THEME_MAP = {
  yellow: "warning",
  green: "success",
  blue: "primary",
  red: "secondary",
  orange: "danger",
  navy: "default",
} as const satisfies Partial<
  Record<
    Exclude<React.ComponentProps<typeof SampleTile>["theme"], undefined>,
    React.ComponentProps<typeof Switch>["theme"]
  >
>;

const DEFAULT_VOLUME_STATE = Object.fromEntries(availableSamples.map((s) => [s.src, VOLUME_LEVELS])); //max

export const OdraPostcard: React.FC<OdraPostcardProps> = () => {
  const [trackVolume, setTrackVolume] = useState(DEFAULT_VOLUME_STATE);
  const ui = useUi();
  const { formatMessage } = useIntl();
  const [infoOpen, setInfoOpen] = useState(false);
  const handleInfoOpen = useCallback(() => setInfoOpen(true), []);
  const handleInfoClose = useCallback(() => setInfoOpen(false), []);
  const handleReset = useCallback(() => {
    setTrackVolume(DEFAULT_VOLUME_STATE);
    setLastResetAt(Date.now());
  }, []);
  const [lastResetAt, setLastResetAt] = useState(Date.now());
  const updateTrackVolume = (sample: Sample, val: number): void => {
    setTrackVolume((state) => ({ ...state, [sample.src]: val }));
  };

  const toggleMute = (sample: Sample): void => {
    updateTrackVolume(sample, trackVolume[sample.src] > 0 ? 0 : VOLUME_LEVELS);
  };

  const player = usePostcardPlayer(trackVolume);

  return (
    <div className={styles.root}>
      <InfoModal visible={infoOpen} onClose={handleInfoClose} />
      <h3 className={styles.title}>{formatMessage(messages.title)}</h3>
      <div className={styles.samples}>
        {availableSamples.map((sample) => {
          const volumeLevel = trackVolume[sample.src];
          const isOn = volumeLevel > 0;

          return (
            <React.Fragment key={sample.src}>
              <div
                className={classnames(styles.switchWrapper, {
                  [styles.active]: isOn,
                })}
              >
                <span className={styles.muteButton} onClick={() => toggleMute(sample)}>
                  <ui.Icon
                    className={classnames({
                      ["cil-volume-high"]: isOn,
                      ["cil-volume-off"]: !isOn,
                    })}
                  />
                </span>
                <div className={styles.sliderWrapper}>
                  <Slider
                    key={lastResetAt + " " + isOn}
                    theme={THEME_MAP[sample.color]}
                    min={0}
                    max={VOLUME_LEVELS}
                    step={1}
                    defaultValue={volumeLevel}
                    onChange={(value) => updateTrackVolume(sample, value)}
                  />
                </div>
              </div>
              <div className={styles.sampleWrapper}>
                <SampleTile theme={isOn ? sample.color : "grey"}>{formatMessage(sample.nameMessage)}</SampleTile>
              </div>
            </React.Fragment>
          );
        })}
      </div>
      <div className={styles.controls}>
        <PlayerControls
          instruction={formatMessage(messages.instruction)}
          player={player}
          onReset={handleReset}
          onInfoOpen={handleInfoOpen}
        />
      </div>
    </div>
  );
};
