import { Position, SampleOnTrack } from "./types";

export const TRACK_WIDTH = 6;
export const TRACKS_NR = 6;

export const TACT_LENGTH = 6.86; //s

export function sampleOverlappingWith(toCompare: { pos: Position; sample: { length: number } }) {
  return (source: SampleOnTrack) => {
    return (
      Math.max(source.pos.x, toCompare.pos.x) <
      Math.min(source.pos.x + source.sample.length, toCompare.pos.x + toCompare.sample.length)
    );
  };
}
