export const enMessages = {
  "menu.about": "About",
  "menu.team": "Team",
  "menu.experts": "Experts",
  "menu.multibook": "Multibook",

  "mobile-placeholder.title": "Your screen’s device is too narrow",
  "mobile-placeholder.subtitle": "Please use desktop browser to attend courses.",
  "mobile-placeholder.description":
    " If you are using tablet device, try to rotate device into horizontal orientation.",

  "landing.experiment1.title": "Mire",
  "landing.experiment1.description": "It can both absorb and emit large amounts of CO2. Check how the mire works.",

  "landing.experiment2.title": "River bottom",
  "landing.experiment2.description":
    "The essence resides in what remains unseen. Look at the river from a different perspective.",

  "landing.experiment3.title": "Sound postcard",
  "landing.experiment3.description":
    "Explore a new way of describing rivers with our diverse collection of sounds. Discover a unique river soundscape and make your song.",

  "landing.experiment4.title": "Meet the river",
  "landing.experiment4.description":
    "See the river from a different perspective. Step off the bridge and get closer. The river adventure awaits you!",

  "landing.experiment5.title": "Design inspired by nature",
  "landing.experiment5.description":
    "Nature has billions of years of design experience. Get inspired by it and create your own object.",

  "landing.open-experiment": "Go to experiment",
  "footer.contact-banner.headline": "Are you an educator? Do you represent a school?",
  "footer.contact-banner.sub-headline": "We have additional materials for you!",
  "footer.contact-banner.contact-us": "Contact us",
  "footer.financed-by":
    "Financed by Iceland, Liechtenstein, and Norway through the EEA Funds as part of the Active Citizens Program – Regional Fund",

  // ABOUT PROJECT SUBPAGE
  // !!!!
  "aboutProject.title": "About the project",
  "aboutProject.description1": `<p>Upstream - an educational project implemented in 2023 by the 515 Association - a non-governmental organization operating since 2019 on the 515th kilometer of the Odra River. The project was funded by Iceland, Liechtenstein, and Norway through the EEA Funds as part of the <a>Active Citizens Program - Regional Fund.</a></p>
    <p>UPSTREAM is an extension and continuation of the ecological education cycle initiated in 2022 by the 515 Association under the name #515niebieskichgodzinwszkole (515 blue hours at school), which is an attempt to introduce on-site education about rivers and water to schools. The series of classes taking place on the river was interrupted by the catastrophe on the Odra River in August 2022.</p>
    <p>The main idea of the UPSTREAM project is to provide innovative, interactive educational tools for students and to inspire teachers and guardians to work creatively, when dealing with the issue of river and water protection. Creative solutions from Iceland became the source of inspiration for an unconventional approach to the teaching process. The tools provided in the projects are not limited to the students but also to adults. The platform itself is available in two language versions — Polish and English.
</p>`,

  "aboutProject.description2": `The adventure with experiments is the key outcome of the project. The experience is designed to take users to the world of creativity, nature, design and sound science while encouraging independent exploration and thinking against the current - thinking "UPSTREAM"

Educational materials, mainly field recordings, photographs and films, were collected in Poland and Iceland.

The substantive topics for the experiments are set in the local context of the Odra River ecosystem. However, they raise important, universal issues on a global scale, including such aspects as environmental and climate change along with its protection.

High-class experts supervised the substantive correctness of the experiments - they can be conducted anywhere, worldwide.`,

  "aboutProject.descriptionListTitle": "The project included, among others:",
  "aboutProject.descriptionListItems": `educational experiments
    a multibook tool with a bank of inspiring solutions for students and teachers`,

  "aboutProject.partnersHeader":
    "The project was carried out in partnership with Polish and Icelandic organizations under the honorary patronage of the Icelandic Ambassador to Poland.",

  // TEAM
  "aboutProject.teamHeader": "Out Team",

  "aboutProject.teamDescription":
    "Upstream was created by an interdisciplinary team. We created experiments with understanding, mutual inspiration, and affection for rivers, which should be talked about more often.",

  "person.m.bobryk.position": "Founder of the 515 Association",
  "person.m.bobryk.description":
    "<p>She lives on the 515th kilometre of the river.</p><p>As a tour guide, she organizes experience tours in Iceland and advises the tourism industry on service and product innovation. She collaborates with local governments and private businesses.</p><p>She is a graduate in Design Management currently pursuing a Master's degree in Disaster Management at Copenhagen University. She is also a passionate social activist who strives to combine resources and potential to tackle the knowledge crisis relating to rivers, which are often neglected and treated as unimportant.</p><p>The Winner of the Polcul Award in 2023 for protecting the river, promoting ecological education and building a civic coalition in the face of the Oder environmental disaster.</p>",

  "person.a.bobryk.position": "Co-Founder of the 515 Association",
  "person.a.bobryk.description":
    "A graduate of pedagogy from the University of Zielona Góra, she is an active mother of three. She co-creates projects for young recipients, tests solutions, is an efficient coordinator, and promotes non-formal education. She is passionate about growing indoor plants.",

  "person.a.grebieniow.position": "Member of the 515 Association",
  "person.a.grebieniow.description": `<p>Master of animal breeding and biology, a specialist in nature conservation and nature education, traveller, photographer and licensed drone pilot.</p><p>She worked on projects to protect white storks, wet meadow birds, barn owls, brindled ground squirrels, and dormouse. She conducted information and educational campaigns on the Washington Convention (CITES) and invasive alien species in Poland.</p><p>She worked for two summer seasons at the field base of the Polish Antarctic Station "Arctowski - ASPA 151, Cape Lions Rump, King George Island, Antarctica. She worked as a nature educator in the field of CITES and for WWF.</p>`,

  "person.k.jaruszewska.position": "Project Manager",
  "person.k.jaruszewska.description": `<p>A graduate of the Faculty of Electronics at the Wrocław University of Science and Technology and postgraduate Master Class of Management studies at SWPS University. Specialist in the field of Acoustics. </p><p>Since 2014, she is running the international educational projects implemented at KFB Acoustics. Coordinator of the educational platform Acoucou.org. Co-author of the illustrated book about sound "Hello! This is sound!" awarded the Must Have 2021 label. </p><p>Chairwoman of the Technical Committee for Education at the European Acoustic Association. Her interests concentrate on interdisciplinary areas combining science, engineering and art.</p>
`,

  "person.m.blazniak.position": "Graphic Designer, Illustrator, Founder of the Ładne Halo Graphic Studio",
  "person.m.blazniak.description":
    "<p>Graphic designer, illustrator, and founder of the Ładne Halo graphic design studio.</p><p>He is a graphic designer, illustrator and author of children's books. He graduated with honours in design from the University of Art and Design in Łódź, where he specialized in illustration and art book design.</p><p>In 2011, he founded the Ładne Halo publishing house and graphic studio. He has worked as an illustrator for several publishing houses, including Ładne Halo, Wytwórnia, Egmont, Polarny Lis, Adamada, and magazines such as Znak and Wysokie Obcasy. He also collaborates with Mama Projekt to produce a series of illustrated guides to Poland known as NIEMAPA.</p><p>In 2014, he won the international Red Dot Design Award in the Communication Design category for the graphic design of an application with interactive books for children. In 2015, he was honoured by NCK and Dziennik Łódzki with the title of Great Creator for combining business with culture.</p>",

  // EXPERTS
  "aboutProject.expertsHeader": "Experts",

  "person.a.bar.position": "Designer and Visual Artist",
  "person.a.bar.description":
    "<p>A sensitive, empathetic being. Designer, visionary, experimenter. Creates objects that enrich everyday experiences that irritate the senses and minds, providing space for reflection in the vessels. Attentive and sensitive. She looks deep into your eyes and listens. She was born in the Sudetes, a region with rich glass-making traditions. </p><p>She is a graduate of Design and Glass Art from the Academy of Fine Arts in Wroclaw. For 12 years, she played a key role in creating the educational program at the Glass Department in the Faculty of Ceramics and Glass at the Academy of Fine Arts in Wroclaw. Additionally, she conducted multiple research projects.</p><p>She has created a personal brand that focuses on designing functional objects that invite people to experience matter. She also works on unique projects that contain metaphors and important messages and deals with image design for unique brands and initiatives. She implements projects using her methods combined with traditional glass techniques. Additionally, she conducts lectures, design workshops that are focused on the senses, and individual mentoring meetings in the field of design.</p><p>She is a recipient of the Ministry of Science and Higher Education's scholarship for young outstanding scientists. She has won the Dobry Wzór design competition, multiple Must Have awards for the best-designed products in Poland, and the Mazda Design Award. Her works are part of the art and design collections, among others. National Museum in Krakow, PURO Hotels in Łódź and Krakow, National Museum in Wrocław, Museum - Kashubian Ethnographic Park. A participant in prestigious exhibitions and design festivals since 2008, including Salone del Mobile Milano, Paris Design Week, London Design Festival, Vienna Design Week, Gdynia Design Days, Łódź Design Festival.</p>",

  "person.i.biedron.position": "Water Management Expert",
  "person.i.biedron.description":
    "<p>She is a Doctor of Engineering and Technical Sciences, specialising in environmental engineering, particularly in the management of water resources. She has extensive experience in dealing with issues related to floods, droughts, and the protection and restoration of river ecosystems. Currently, she is the coordinator of the WaterLANDS project at the Wetland Protection Center Association.</p><p>As an independent expert, she has worked for almost 20 years on various water management projects, including water protection, drought and flood management, and river maintenance. In recent years, she has managed projects commissioned by Polish Waters, such as updating flood hazard and flood risk maps, and the National Surface Water Restoration Program.</p><p>Moreover, she is the manager and content supervisor of the zdrowarzeka.pl podcast project and a certified PRINCE2® Project Manager.</p>",

  "person.e.jablonska.position": "Biologist",
  "person.e.jablonska.description":
    "She is a Doctor of Biological Sciences working at the Faculty of Biology of the University of Warsaw, deals with the ecology of mire vegetation, and acting in the association Wetlands Conservation Centre.",

  "person.r.konieczny.position": "Water Engineering Engineer",
  "person.r.konieczny.description": `<p>A flood specialist who uses non-technical solutions designs local warning systems and creates plans to limit flood damage.</p><p>A long-time employee of the Institute of Meteorology and Water Management, an independent expert of the Let's Save the Rivers Coalition. He has participated in various international projects funded by organizations such as the European Union, World Meteorological Organization, and World Bank. He has also finished several courses offered by NATO to learn how to reduce the impact of flooding. For many years, he served as the editor-in-chief of the quarterly publication called "Ecological Guide for Local Governments".</p>`,

  "person.m.grygoruk.position": "Hydrologist",
  "person.m.grygoruk.description":
    "<p>He is a professor at the Department of Hydrology, Meteorology and Water Management at the Warsaw University of Life Sciences (SGGW).</p><p>His area of expertise is the quantitative analysis of water flow processes in the landscape and the role of rivers and wetlands in the environment, in social and economic surroundings. He has conducted extensive research on rivers and wetlands, covering wide range of locations from northern Norway to southern Poland and from Siberia to Canada. He is an activist in non-governmental organizations and member of the State Council for Environmental Protection. During his free time, he enjoys fishing and playing music. He spends most of his leisure time in the swamps or by the river.</p>",

  "person.t.dot.position": "Music Producer",
  "person.t.dot.description": `<p>The project started in Stavanger in 2014. It experiments with genres such as IDM (intelligent dance music), ambient, folk and jazz. Guests in albums include Jexus, Susan Jara, Jakub Żytecki, Simen Kiil Halvorsen, Julie Hasfjord, magician, Cinar Timur, Szymon Mika, Koshiro Hino, Shohei Kudo, Demsky and many others.</p><p>By the end of 2022, there were 11 albums, 1 EP and several singles released by the following publishing houses: ONIONWAVE, RUSKA BURSA and ADDICTED TO MUSIC. </p><p>Each of these discs is considered a concept album, where the main theme is explored through various instrumental solutions, recording techniques, and creative limitations. This results in each album having a unique sound and standing out both in composition, orchestration, and overall sonic spectrum.</p><p>In the work of T. Est, several distinct threads can be identified, including: 1. Travel with the album "Nordsteps", a trip to Norway, "DOT" a trip around the map of Japan, "B in", a trip to Berlin. 2. Stories, which include four albums from the ANTON series and the dystopian trend that began during the pandemic in 2020, including the albums BLMRVRSD, DSTPNPLS and FRMTHDSTNT. 3. Folk-inspired series of pro-ecological singles with vocalists from Zimbabwe and Congo, and the albums HULATY and EP Kurpiowskie - both items together with Susanna Jara and the Sestra Boczniewicz project. 4. Singles revolving around dance music, like setme300 and summer420 with vocalist Ania Dudek, remixes, the Re ANTON album, and single productions from P.A.F.F, Unitra_Audio and Maro Music.</p>`,

  "aboutProject.multibook.header": "Inspiration from Iceland!",
  "aboutProject.multibook.content":
    "Immerse yourself in landscapes full of gushing geysers, dancing northern lights and towering peaks. Let Icelandic tales transport you to a realm of the magical world of extraordinary adventures.",
  "aboutProject.carousel.1": "Implementation of the project",
  "aboutProject.carousel.2": "Project partners (Poland)",
  "aboutProject.carousel.3": "Project partners (Iceland)",
  "aboutProject.carousel.4": "Expert support",
  "aboutProject.carousel.5": "Technical support",
  "aboutProject.carousel.6": "Honorary patronage",

  //LMS
  "lms.listen-to-expert": "Listen to the Expert",
  "lms.listen-to-river": "Listen to the River",

  "lms.lesson02.sidebar-header": "Bird's-eye view",
  "lms.lesson02.step1.header": "Crops next to the dredged river",
  "lms.lesson02.step2.header": "Crops next to the regulated river",
  "lms.lesson02.step3.header": "Crops moved away from the river",
  "lms.lesson02.step4.header": "Crops next to a healthy river",
  "lms.lesson02.step1.description":
    "A regulated river that looks like a simple canal flows through agricultural areas that adjoin the banks of the river. The lack of trees near the banks causes overgrowing of the riverbed and the need for cyclical cleaning of the river. That makes the river drain water from the landscape even more efficiently.",
  "lms.lesson02.step2.description":
    "A regulated river flows through farmland adjoining the riverbanks. There are no trees along the banks and no wetland vegetation zone. The river efficiently drains water from the landscape.",
  "lms.lesson02.step3.description":
    "A river regulated to flow through agricultural areas, meandering through a wider bed with bushes at the shore and a floodwater bed zone separating farmland and meadows from the river.",
  "lms.lesson02.step4.description":
    "A natural river, meandering in the valley, flowing through forests and reed beds. Traditional agriculture is a considerable distance from the river.",
  "lms.lesson02.parameter-purity": "Purity",
  "lms.lesson02.parameter-bio": "Biodiversity",
  "lms.lesson02.parameter-retention": "Retention",
  "lms.lesson02.welcome.title": "Riverbed",
  "lms.lesson02.welcome.description":
    "When will the river be healthy? Learn from Ilona Biedroń, founder of the River School and caretaker of this experiment, what will happen when you intervene in the riverbed.",
  "lms.lesson02.bonus.title": "Wetland buffer zones",
  "lms.lesson05.step.header": "Step {current} of {total}",
  "lms.lesson05.step1.description": "Choose the item you will design.",
  "lms.lesson05.step2.description":
    "Do you like watching the river? What inspires you? What is the river like?\n" +
    "Choose a term that will allow you to modify your item.",
  "lms.lesson05.step3.description":
    "Do you pay attention to the multicolored river landscapes?\n" +
    "Get inspired by the colors in nature and choose a color palette for your item",
  "lms.lesson05.select-slogan.header": "Select a Slogan",
  "lms.lesson05.select-theme.header": "Select a Color Palette",
  "lms.lesson05.summary.header": "Summary - Designed Item",
  "lms.lesson05.summary.description":
    "Way to go! The design stage inspired by nature has been completed. Consider how you can adapt this process to your ideas.\n" +
    `Click "Start over" and try again.`,
  "lms.lesson05.slogan.frozen": "Frozen",
  "lms.lesson05.slogan.lifegiving": "Life-Giving",
  "lms.lesson05.slogan.wavy": "Wavy",
  "lms.lesson05.slogan.lazy": "Lazy",
  "lms.lesson05.slogan.meandering": "Meandering",
  "lms.lesson05.slogan.dynamic": "Dynamic",
  "lms.lesson05.slogan.calm": "Calm",
  "lms.lesson05.slogan.alive": "Alive",
  "lms.lesson05.slogan.fastpaced": "Fast-Paced",
  "lms.lesson05.slogan.flowing": "Spilled",
  "lms.lesson05.slogan.refreshing": "Refreshing",
  "lms.lesson05.slogan.rushing": "Rapid",
  "lms.lesson05.object.dish": "Dish",
  "lms.lesson05.object.fabric": "Fabric",
  "lms.lesson05.object.architecture": "Small Architecture",
  "lms.lesson05.object.package": "Packaging",
  "lms.lesson05.setup.object": "Form",
  "lms.lesson05.setup.slogan": "Slogan",
  "lms.lesson05.setup.theme": "Colors",
  "lms.lesson05.setup.choose-object": "Choose a Form",
  "lms.lesson05.setup.choose-slogan": "Choose a Term",
  "lms.lesson05.setup.choose-theme": "Choose Colors",
  "lms.lesson05.setup.button-back": "Back",
  "lms.lesson05.setup.button-reset": "Start Over",
  "lms.lesson05.bonus.title": "Agnieszka Aleksandra Bar",
  "lms.lesson05.bonus.description":
    "A sensitive, empathetic being. Designer, visionary, experimenter. Creates objects that enrich everyday experiences. It irritates the senses and minds, providing space for reflection in the vessels. Attentive and sensitive. She looks deep into your eyes and listens.\n" +
    "\n" +
    `She was born in the Sudetes, a region with rich glass-making traditions.
 Design and Glass Art at the Academy of Fine Arts in Wrocław graduate. For 12 years, she co-created the educational program at the Glass Department at the Faculty of Ceramics and Glass at the Academy of Fine Arts in Wrocław and conducted research projects.\n` +
    "\n" +
    "She creates a personal brand in which she focuses on designing functional objects that invite you to experience matter and on unique projects that contain metaphors and meaningful messages. She deals with image design for unique brands and initiatives. She implements projects based on his own methods combined with traditional glass techniques. She conducts lectures, design workshops focused on the senses, and conducts individual mentoring meetings in the field of design.\n" +
    "\n" +
    "A holder of the Scholarship of the Ministry of Science and Higher Education for a young outstanding scientist. Winner of the Dobry Wzór design competition, multiple winner of the Must Have plebiscite for the best designed products in Poland, and the winner of the Mazda Design Award. Her works are part of the art and design collections, among others. National Museum in Krakow, PURO Hotels in Łódź and Krakow, National Museum in Wrocław, Museum - Kashubian Ethnographic Park. Participant of prestigious exhibitions and design festivals since 2008, including: Salone del Mobile Milano, Paris Design Week, London Design Festival, Vienna Design Week, Gdynia Design Days, Łódź Design Festival.",
  "lms.lesson05.welcome.title": "Nature-Inspired Design",
  "lms.lesson05.welcome.description":
    "Design from nature.\n\n" +
    "Nature is a source of inexhaustible inspiration and an excellent teacher in the art of design. It teaches us an approach to creation that draws from natural forms, processes and adaptations in the natural environment. Observing nature and experiencing it with our senses influences our creativity and an ability to solve problems.\n" +
    "Experience a fascinating journey through the creative process with the supervisor of this experiment - Agnieszka Bar.",
  "lms.lesson05.bonus.option.idea": "Idea",
  "lms.lesson05.bonus.option.design": "Design",
  "lms.lesson05.bonus.option.implementation": "Implementation",
  "lms.lesson05.bonus.option.product": "Product phase",
  "lms.lesson05.bonus.option.author": "About the Author",
  "lms.lesson05.bonus.design.photo1.description": "Time by the river - observation, experiencing with the senses.",
  "lms.lesson05.bonus.design.photo2.description": "Concept/idea sketch.",
  "lms.lesson05.bonus.design.photo3.description": "Sketch of a structure inspired by water and wicker weaves.",
  "lms.lesson05.bonus.design.photo4.description": "The structure on the surface of the dishes - bowl.",
  "lms.lesson05.bonus.design.photo5.description": "The structure on the surface of vessels - a vase.",
  "lms.lesson05.bonus.implementation.photo1.description": "Weaving wicker covers.",
  "lms.lesson05.bonus.implementation.photo2.description": "Blowing and pressing hot glass into a wicker cover.",
  "lms.lesson05.bonus.implementation.photo3.description": "Wicker shield after contact with hot glass.",
  "lms.lesson05.bonus.implementation.photo4.description": "Chilled glass with an imprinted wicker structure.",
  "lms.lesson05.bonus.implementation.photo5.description": "A craftsman and designer at work in a glass studio.",
  "lms.lesson05.bonus.product.photo1.description": "Product photography.",
  "lms.lesson05.bonus.product.photo2.description": "FLOW bowl.",
  "lms.lesson05.bonus.product.photo3.description": "View of the structure at the bottom of the vessel.",
  "lms.lesson05.bonus.product.photo4.description": "The undulating edge of the vessel.",
  "lms.lesson05.bonus.product.photo5.description":
    "A photograph that tells the story of the project - a vase floating on the Oder.",
  "lms.lesson05.bonus.intro.title": "FLOW - a series of vascular objects",
  "lms.lesson05.bonus.intro.description":
    "FLOW - a series of utility objects inspired by rivers. FLOW series is a part of broader collection - THE INNER POWER.\n" +
    "\n" +
    "As a designer and a human being sensitive to the world around me, I have a mission to communicate with recipients and users through my projects/works. I use this opportunity to talk about important issues close to my heart. One of them is caring for nature, respecting its limited resources, and treating it like a sister.\n" +
    "\n" +
    "I live in Wrocław, on the Odra River. I often walk along the embankments by the nearby Widawa River. Constantly moving, changeable, life-giving, she became a respite and inspiration for me during the pandemic. That's why I decided to create objects that are the quintessence of meandering, nourishing water.\n" +
    "\n" +
    `I used my own technique of blowing and pressing glass into flexible wicker braids. I treat traditional methods with respect, however I "add a pinch of salt" because I create the most original projects when I engage in experiments.\n` +
    `I developed my method based on an old tradition cultivated in Kashubia, where people used wicker to protect lake shores and protect houses against water and moisture. I used the natural features of willow twigs to create the texture of rippling water on the surface of the dishes as a result of the high temperature of the glass. My intention for FLOW objects is to accompany us at home and remind us of what we can learn from rivers, how they can support us in everyday life, how we can  protect them.`,
  "lms.lesson04.intro.title": "Sound Postcard",
  "lms.lesson04.intro.title-left": "A creative process inspired by nature",
  "lms.lesson04.intro.description-left":
    "Take a moment to pause and close your eyes. Focus on the sounds around you. We often don't pay attention to the soundscape until it's absent. For some of us, it may be the music itself, but for researchers, the soundscape is a valuable source of information about the state of our planet.",
  "lms.lesson04.intro.title-right": "Working on a song",
  "lms.lesson04.intro.description-right":
    "We provide you with a tool to create an audio postcard and sounds recorded and inspired by the landscape of Poland and Iceland. The supervisor of this t dot est experiment will guide you through process of creating music.",
  "lms.lesson04.place.listen-to": "Listen",
  "lms.lesson04.place.watch": "Watch",
  "lms.lesson04.place.headphones-info1": "Want to hear all the details?",
  "lms.lesson04.place.headphones-info2": "Put headphones on before listening.",
  "lms.lesson04.iceland.1.title": "Little White & Little Grey\n" + "Belugas whales",
  "lms.lesson04.iceland.1.description":
    "Little White and Little Gray traveled 9,000 km from their previous home in a water park in Shanghai to the Sanctuary in a large natural bay on the island of Vestmannaeyjar.Little Gray is extremely curious and vocal, making all sort of sounds to the care team when she wants to express herself. She is sincerely interested in everything new and often likes to be the center of attention.",
  "lms.lesson04.iceland.2.title": "In a glacial crevasse",
  "lms.lesson04.iceland.2.description": "Sounds of Sólheimajökull (Sun Glacier).",
  "lms.lesson04.iceland.3.title": "Waterfall on the Skoga River",
  "lms.lesson04.iceland.3.description": "The sound surroundings of the Skogafoss waterfall.",
  "lms.lesson04.iceland.4.title": "Glacier lagoon",
  "lms.lesson04.iceland.4.description":
    "Jökulsárlón Glacier Lagoon is a stunning glacial lake formed by the melting of the Breiðamerkurjökull glacier. The lagoon is known for its surreal beauty, where floating icebergs of all shapes and sizes float gently on the calm, icy waters, making intriguing sounds.",
  "lms.lesson04.iceland.5.title": "Bird habitat",
  "lms.lesson04.iceland.5.description":
    "Dyrhólaey is one of the most attractive places in Iceland for observing seabirds. The rocks of the headland and the surrounding islands are full of guillemots and gulls, and it is easy to spot puffins there.",

  "lms.lesson04.odra.1.title": "Border Meanders of the Oder River",
  "lms.lesson04.odra.1.description":
    "The unique natural monument of a naturally meandering river with blind arms and backwaters. A natural jewel of Central Europe.\n" +
    "Photo by Piotr Nieznański.",
  "lms.lesson04.odra.2.title": "Łacha Jelcz nature reserve",
  "lms.lesson04.odra.2.description":
    "A water reserve covering the Odra oxbow lake, including valuable habitats of protected plant species, water and marsh birds, amphibians and insects. The best time to visit this place is April and July.\n" +
    "Photo by Anna Grebieniow.",
  "lms.lesson04.odra.3.title": "Ścinawa",
  "lms.lesson04.odra.3.description":
    "The entrance to the Odra River Marshes, which leads to the Odra Sound Trail, offers specially designed benches for visitors to sit on. From here, they can listen to the natural sounds of the river as well as those produced by humans.",
  "lms.lesson04.odra.4.title": "Gostchorze",
  "lms.lesson04.odra.4.description": `The place commonly known as the "steep wall" - an almost 50m high cliff covered with thermophilic oak-hornbeam forest, is the best observation point in the area of the Oder ice-marginal valley in its middle course.`,
  "lms.lesson04.odra.5.title": "Krosno Odrzańskie",
  "lms.lesson04.odra.5.description": `Place of creation of the Association 515. Between the spurs lies a sunken flatboat; one kilometer away, the river Bobr flows into the Oder, and the valley "looks at" the Odra Vineyard Marcinowice.`,
  "lms.lesson04.odra.6.title": "Warta River Mouth National Park",
  "lms.lesson04.odra.6.description":
    "Located in the historic delta of the mouth of the Warta River and the Oder River, it is a unique refuge for water and marsh birds in Europe. A crowd of 279 species of birds makes this place ideal for observation, research and education.",
  "lms.lesson04.odra.7.title": "Międzyodrze area",
  "lms.lesson04.odra.7.description":
    "The European Amazon. The great diversity of fauna and flora, the wild land of peat bogs, and wet meadows make this place part of the initiative to create the Lower Oder Valley National Park.\n" +
    "Photo by Piotr Piznal.",
  "lms.lesson04.gallery.1.title": "On the Sólheimajökull glacier",
  "lms.lesson04.gallery.1.description":
    "Sólheimajökull Glacier (Sun Glacier) is the southernmost glacier in Iceland. Sound exploration of the sound of rivers born on a glacier would not have been possible without the professional support of our partner from Iceland - <a href='https://www.facebook.com/IceWalkers.Tours' target='_blank'>Ice Walkers Tours</a>, who safely guided us between the meanders of the crevasses.",
  "lms.lesson04.gallery.2.title": "Recording sounds in a glacier fissure",
  "lms.lesson04.gallery.2.description":
    "Sólheimajökull has been melting quite fast since around the1993, and on average, around 60 meters of ice melts each year. The glacier is losing about 10 to 20 meters of thickness every year.",
  "lms.lesson04.gallery.3.title": "Experienced specialists",
  "lms.lesson04.gallery.3.description":
    "Ania Grebieniow - drone operator and photographer with extensive experience working in difficult, wilderness terrain. She worked twice as an ecological observer at the field base of the Polish Antarctic Station on King George Island. Always loaded with a huge amount of electronics ready to  recognize every species of bird.",
  "lms.lesson04.gallery.4.title": "On a windless evening on the Fjallsárlón lagoon",
  "lms.lesson04.gallery.4.description":
    "Iceland is known for its windy and sometimes harsh weather conditions. If you're wondering when to visit Iceland, Magda, the founder of the 515 Association and a guide to Iceland, has picked a date for us. Having visited the island over 17 times, she not only knows the best time to visit, but also where to go to experience nature in a serene and crowd-free environment.",
  "lms.lesson04.gallery.5.title": "Dyrhólaey Nature Reserve",
  "lms.lesson04.gallery.5.description":
    "Dyrhólaey is a nature reserve - visits to this place are limited between May 1 and June 25 for bird life protection. We recorded the sounds of Eider Ducks, photographed puffins and took breathtaking views of the 120-meter-long promenade of the Dyrhólaey Peninsula.",
  "lms.lesson04.gallery.6.title": "On the volcanic island of Heimaey",
  "lms.lesson04.gallery.6.description":
    "We observed and documented the natural world on Vestmannaeyjar Island. The island is the only inhabited island of the Vestmannaeyjar Archipelago, and is only accessible by ferry.The steep and beautiful cliffs are screaming houses inhabited by seagulls, guillemots, terns and puffins. In this area, redshanks were trying hard to ground our drone.",
  "lms.lesson04.odra.extra-headphones-info": "Sound sample created based on field recordings by Michał Zygmunt.",
  "lms.lesson04.iceland-postcard.header": "Sound postcard from Iceland",
  "lms.lesson04.iceland-postcard.instruction":
    "Drag the colored tiles to the empty boxes and compose your song with <a href='https://tdotest.com/' target='_blank'>t dot est</a>.",
  "lms.lesson04.iceland-postcard.more-button": "More",
  "lms.lesson04.iceland-postcard.play-button": "Play",
  "lms.lesson04.iceland-postcard.stop-button": "Stop",
  "lms.lesson04.iceland-postcard.reset-button": "Reset",
  "lms.lesson04.iceland-postcard.download-button": "Download",
  "lms.lesson04.iceland-postcard.sample.sea": "Sea and ferry",
  "lms.lesson04.iceland-postcard.sample.ice": "Cracking ice",
  "lms.lesson04.iceland-postcard.sample.synth-bass": "Bass synthesizer",
  "lms.lesson04.iceland-postcard.sample.synth": "Synthesizer",
  "lms.lesson04.iceland-postcard.sample.piano-right-hand": "Piano right hand",
  "lms.lesson04.iceland-postcard.sample.piano-left-hand": "Piano left hand",
  "lms.lesson04.iceland-postcard.sample.drums-low": "Low-sounding drum",
  "lms.lesson04.iceland-postcard.sample.drums-high": "High-pitched drums",
  "lms.lesson04.iceland-postcard.sample.sing1": "Vocals 1",
  "lms.lesson04.iceland-postcard.sample.sing2": "Vocals 2",
  "lms.lesson04.iceland-postcard.info-modal.title": "Iceland in loops",
  "lms.lesson04.iceland-postcard.info-modal.subtitle":
    "Instructions for creating a short ambient piece based on previously prepared audio loops",
  "lms.lesson04.iceland-postcard.info-modal.content":
    "Select audio loops that include piano recordings, human voices, and synthesizers. Make sure that each loop has the appropriate length to blend with other sounds in a harmonious manner.\n" +
    "Introduce field recording elements, such as birds singing, water currents, the sound of hot springs or distant ships. Combine these recordings with synthesizers, human voices and piano to create contrasting and unexpected sonic combinations.\n" +
    "Continue experimenting and adjusting the composition until you achieve the ambient effect of the song you want. Remember that there are no limits to your imagination and creativity in creating ambient music.\n" +
    "Listen on your own or download the song to a file and share your song with other listeners to enjoy the magic and peace that nature-inspired music brings.\n",
  "lms.lesson04.iceland-postcard.info-modal.info":
    "The audio loops in this experiment was prepared by t dot est based on field recordings from Iceland provided by Noise from Iceland.If you want to listen to original field recordings, visit the website!",
  "lms.lesson04.iceland-postcard.info-modal.source-website-button": "Noise from Iceland",
  "lms.lesson-item-navigation.button-main-page": "Main Page",
  "lms.lesson-item-navigation.button-bonus": "Bonus",
  "lms.lesson-item-navigation.experiment-bonus": "Experiment",
  "lms.lesson04.odra-postcard.title": "Sound postcard from the Oder",
  "lms.lesson04.odra-postcard.instruction":
    "Turn individual tracks on or off and change the volume of selected tracks using the buttons on the left side. <br />" +
    "Compose your song with <a href='https://tdotest.com/' target='_blank'>t dot est.</a>",
  "lms.lesson04.odra-postcard.sample.piano": "Piano",
  "lms.lesson04.odra-postcard.sample.synth-bass": "Bass Synthesizer",
  "lms.lesson04.odra-postcard.sample.swamp": "Sound of swamps",
  "lms.lesson04.odra-postcard.sample.cuckoo": "Cuckoo, crane, duck",
  "lms.lesson04.odra-postcard.sample.drums": "High-pitched drums",
  "lms.lesson04.odra-postcard.sample.owl": "Tawny owl, woodpecker, white-tailed eagle",
  "lms.lesson04.odra-postcard.sample.water": "The rhythm of water and shipyard ropes",
  "lms.lesson04.odra-postcard.sample.instruments": "String instruments",
  "lms.lesson04.odra-postcard.sample.synth": "Synthesizer",
  "lms.lesson04.odra-postcard.info-modal.title": "Oder progression",
  "lms.lesson04.odra-postcard.info-modal.subtitle": "Instructions for creating a river-inspired sound piece",
  "lms.lesson04.odra-postcard.info-modal.content":
    "At the beginning of the piece, use a track with recorded piano at moderate volume to introduce a melodic and calm atmosphere. Introduce tracks with recorded bird songs, gradually increasing their volume to create a sense of growing energy.\n" +
    "Next, activate the percussion track with the recorded flow of the river against the backdrop of other sounds, maintaining its volume at a moderate level to add a pulse to the entire piece.\n" +
    "Later, activate the track with recorded synthesizers, gradually increasing its volume to enrich the sonic texture of the piece. Continue manipulating the volume automation of individual tracks throughout the duration of the piece to highlight different elements and create dynamic progression.\n" +
    "Remember that volume changes over time should be smooth and precise to avoid sudden jumps, while enlivening the perspective and deepening the sound space.\n" +
    "Conclude the piece by gradually lowering the volume of all audio tracks to slowly fade out the sounds and create a resolution effect.\n" +
    `Go through the piece, listen carefully, and make sure volume changes are smooth and desirable, adjusting them as needed. "Oder progression" is now ready for listening, exporting, and sharing with friends!`,
  "lms.lesson04.odra-postcard.info-modal.info":
    "Individual audio tracks in this experiment were prepared by t dot est based on field recordings from the Oder provided by Michał Zygmunt. If you want to listen to the original field recordings, visit the website!",
  "lms.lesson04.odra-postcard.info-modal.source-website-button": "Oder Sound Trail",
  "lms.lesson04.odra.1.headphones-extra-info":
    "Sound sample composed by t dot est inspired by the Oder river landscape.",
  "lms.lesson04.odra.2.headphones-extra-info": "Sound sample created based on field recordings by Michał Zygmunt.",
  "lms.lesson04.odra.3.headphones-extra-info": "Sound sample created based on field recordings by Michał Zygmunt.",
  "lms.lesson04.odra.4.headphones-extra-info": "Sound sample created based on field recordings by Michał Zygmunt.",
  "lms.lesson04.odra.5.headphones-extra-info":
    "Sound sample composed by t dot est inspired by the Oder river landscape.",
  "lms.lesson04.odra.6.headphones-extra-info": "Sound sample created based on field recordings by Michał Zygmunt.",
  "lms.lesson04.odra.7.headphones-extra-info": "Sound sample created based on field recordings by Michał Zygmunt.",
  "lms.lesson03.welcome.title": "Meeting with the river",
  "lms.lesson03.welcome.description":
    "Now, think. Have you ever experienced being in a river? Have you ever immersed yourself in the water, felt the coolness of the mountain water on your face, and observed the bustling ecosystem around the wetland? If not, we recommend that you go to the river and we'll tell you what you can do there.",
  "lms.lesson03.bonus.1.title": "FLY FISHING on the Bóbr River",
  "lms.lesson03.bonus.2.title": "DIVING in the Biebrza River",
  "lms.lesson03.bonus.3.title": "PHOTOGRAPHY at the mouth of the Warta River",
  "lms.lesson03.bonus.4.title": "BRODZING in Świder",
  "lms.lesson03.bonus.5.title": "PACRAFTING on the Makomba River",
  "lms.lesson03.bonus.6.title": "FLIGHT over the red river",
  "lms.lesson03.bonus.1.description":
    "© Hubert Sempoch <br /> Hubert Sempoch - passionate about photography and fly fishing. Co-founder of the Friends of the Kwisa River Association. Material recorded on the Bóbr River.",
  "lms.lesson03.bonus.2.description":
    "© Małgorzata Owczarska & Agnieszka Zych <br /> Diving in the only river in Poland - the Biebrza, where there is an official diving trail.<br />" +
    `The video material was created as part of the NCN SONATA16 Grant, No. 2020/39/D/Hs3/00618, "The experience of excess, lack and sustainable presence of water. A study in the trend of blue anthropology.".`,
  "lms.lesson03.bonus.3.description":
    "© Piotr Chara <br /> Piotr Chara - wildlife photographer, naturalist, regionalist. Creator of the Islands of Life, President of the Green Valley of the Odra and Warta Foundation.  <a href='www.fzdow.pl'>www.fzdow.pl</a>",
  "lms.lesson03.bonus.4.description":
    "© Hiro the dog with his guardian Małgorzata Owczarska<br /> Wading is a form of walking on a shallow and, very importantly, not a mountain river. Choose a place for activity so as not to damage the fauna and flora of the stream.<br />" +
    `The video material was created as part of the NCN SONATA16 Grant, No. 2020/39/D/Hs3/00618, "The experience of excess, lack and sustainable presence of water. A study in the trend of blue anthropology.`,
  "lms.lesson03.bonus.5.description":
    "© Dominik Szmajda <br /> Go on an off-trail adventure! Raft down the meanders of a wild river with Domonik Szmajda - photographer, winner of the Kolosy award. <a href='www.dominikszmajda.com'>www.dominikszmajda.com</a>",
  "lms.lesson03.bonus.6.description":
    "© Virtual Dream - Piotr Łój / Eruption of the Icelandic volcano Meradalir, 2022. <br /> Material created for people who can explore the outside world only using VR. More at  <a href='https://www.patronite.pl/VirtualDream'>www.patronite.pl/VirtualDream</a>",
  "lms.lesson01.legend.o2": "Oxygen",
  "lms.lesson01.legend.co2": "Carbon dioxide",
  "lms.lesson01.legend.peat": "Peat",
  "lms.lesson01.legend.vegetation": "Mire vegetation",
  "lms.lesson01.legend.mineral": "Mineral substrate",
  "lms.lesson01.legend.water": "Water",
  "lms.lesson01.legend.vegetation-top": "Non-mire vegetation",
  "lms.lesson01.legend.rot": "Decomposed peat (muck)",
  "lms.lesson01.switch.m-4000": "-4000 years",
  "lms.lesson01.switch.m-3000": "-3000 years",
  "lms.lesson01.switch.m-2000": "-2000 years",
  "lms.lesson01.switch.m-1000": "-1000 years",
  "lms.lesson01.switch.m-100": "-100 years",
  "lms.lesson01.switch.m-90": "-90 years",
  "lms.lesson01.switch.today": "Today",
  "lms.lesson01.switch.p-100": "+100 years",
  "lms.lesson01.swamp-legend-title": "Peatland cross-section",
  "lms.lesson01.side-header": "Peatland cross-section",
  "lms.lesson01.decision.header": "And now you have to decide what you will do with the peatland.",
  "lms.lesson01.decision.subheader": "Make your choice and see what your place will look like in 100 years.",
  "lms.lesson01.decision.a-description":
    "I will continue to dry and graze the cow because the milk from cows grazing in the meadow is the tastiest.",
  "lms.lesson01.decision.a-button": "I'm drying it",
  "lms.lesson01.decision.b-description":
    "I will fill in the ditch, even if I can no longer graze the cows or mow the meadows, and I will cultivate the mire for nature and the climate.",
  "lms.lesson01.decision.b-button": "I'm backfilling it",
  "lms.lesson01.welcome.title": "Peatland",
  "lms.lesson01.welcome.description":
    "Go to the swamps with Ewa Jabłońska. The wetland expert will take you on a journey through time. You will see the past and decide on the future.",
  "lms.lesson01.bonus.title": "Wetlands",
};
