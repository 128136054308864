import React, { createContext, useCallback, useContext, useState } from "react";
import { Sample } from "./samples";
import { sampleOverlappingWith, TRACK_WIDTH } from "./helper";
import { Position, SampleOnTrack } from "./types";

interface PostcardContextData {
  canAddToXY(sample: Sample, pos: Position): boolean;
  addToXY(sample: Sample, pos: Position): void;
  removeSampleFromXY(pos: Position): void;
  reset(): void;
  samples: SampleOnTrack[];
}

const PostcardContext = createContext<PostcardContextData>({
  canAddToXY: () => true,
  addToXY(): void {
    return;
  },
  removeSampleFromXY() {
    return;
  },
  reset() {
    return;
  },
  samples: [],
});

interface PostcardModelProviderProps {
  children: React.ReactElement;
}

export const PostcardModelProvider: React.FC<PostcardModelProviderProps> = ({ children }) => {
  const [samples, setSamples] = useState<SampleOnTrack[]>([]);

  const canAddToXY = useCallback(
    (sample: Sample, pos: Position) => {
      const size = sample.length;
      const overlapping = samples.filter((entry) => entry.pos.y === pos.y).some(sampleOverlappingWith({ sample, pos }));

      const expandsOverTrack = pos.x + size > TRACK_WIDTH;

      return !(overlapping || expandsOverTrack);
    },
    [samples]
  );

  const addToXY = useCallback(
    (sample: Sample, pos: Position) => {
      if (!canAddToXY(sample, pos)) {
        throw new Error("Cannot add sample to given position");
      }

      setSamples((samples) => [...samples, { sample, pos }]);
    },
    [canAddToXY]
  );

  const removeSampleFromXY = useCallback((pos: Position) => {
    setSamples((samples) => samples.filter((entry) => !(entry.pos.x === pos.x && entry.pos.y === pos.y)));
  }, []);

  const reset = useCallback(() => {
    setSamples([]);
  }, []);

  return (
    <PostcardContext.Provider
      value={{
        addToXY,
        canAddToXY,
        removeSampleFromXY,
        reset,
        samples,
      }}
    >
      {children}
    </PostcardContext.Provider>
  );
};

export const usePostcardModel = (): PostcardContextData => {
  return useContext(PostcardContext);
};

export const DND_TYPE_SAMPLE = "postcard-sample";
