import { ExperimentWelcome } from "./experiment-welcome/ExperimentWelcome";
import { PhotoView } from "@src/components/extensions/layouts/photo-view/PhotoView";
import { Carousel } from "@src/components/extensions/layouts/carousel/Carousel";
import { WrappedContent } from "@src/components/extensions/layouts/wrapped-content/WrappedContent";

import { Main as Lesson01Main } from "./lesson-01/Main";
import { Main as Lesson02Main } from "./lesson-02/Main";
import { Main as Lesson05Main } from "./lesson-05/Main";
import { Author as Lesson05Author } from "./lesson-05/Author";

import { Intro as Lesson04Intro } from "./lesson-04/intro/Intro";
import { Place as Lesson04Place } from "./lesson-04/place/Place";
import { IcelandPostcard as Lesson04IcelandPostcard } from "./lesson-04/iceland-postcard/IcelandPostcard";
import { OdraPostcard as Lesson04OdraPostcard } from "./lesson-04/odra-postcard/OdraPostcard";

import { UIOverride } from "@src/components/extensions/layouts/ui-overrde/UIOverride";

export const UpstreamExperimentWelcome = ExperimentWelcome;
export const UpstreamLesson01Main = Lesson01Main;
export const UpstreamLesson02Main = Lesson02Main;
export const UpstreamLesson04Intro = Lesson04Intro;
export const UpstreamLesson04Place = Lesson04Place;
export const UpstreamLesson04IcelandPostcard = Lesson04IcelandPostcard;
export const UpstreamLesson04OdraPostcard = Lesson04OdraPostcard;
export const UpstreamLesson05Main = Lesson05Main;
export const UpstreamLesson05Author = Lesson05Author;
export const UpstreamPhotoView = PhotoView;

export const UpstreamUIOverride = UIOverride;

export const UpstreamCarousel = Carousel;

export const UpstreamWrappedContent = WrappedContent;
