import * as Tone from "tone";
import toWav from "audiobuffer-to-wav";
import { useCallback, useEffect, useRef, useState } from "react";
import { availableSamples, Sample } from "./samples";
import { Player } from "@src/components/extensions/layouts/lesson-04/types";

function sampleId(sample: Sample): string {
  return sample.src;
}

export const VOLUME_LEVELS = 5;

export function usePostcardPlayer(samplesVolume: Record<string, number>): Player {
  const [loaded, setLoaded] = useState(false);
  const [playing, setPlaying] = useState(false);
  const players = useRef<Record<string, Tone.Player>>({});
  const buffers = useRef<Tone.ToneAudioBuffers | undefined>(undefined);

  const load = useCallback(() => {
    if (buffers.current) {
      return;
    }

    buffers.current = new Tone.ToneAudioBuffers({
      urls: Object.fromEntries(availableSamples.map((s) => [s.src, s.src])),
    });

    Tone.loaded().then(() => setLoaded(true));
  }, []);

  const playerForSample = (sample: Sample): Tone.Player => {
    const id = sampleId(sample);
    let player = players.current[sampleId(sample)];
    if (!player) {
      player = new Tone.Player().toDestination();
      player.buffer = buffers.current!.get(sample.src)!;
      players.current[id] = player;
    }

    return player;
  };

  const getSampleStatus = (sample: Sample): { sample: Sample; volume: number; muted: boolean } => {
    const volumeLevel = samplesVolume[sample.src] ?? 0;
    const volume = VOLUME_LEVELS - volumeLevel * -3; //db
    const muted = volumeLevel === 0;

    return {
      sample,
      volume,
      muted,
    };
  };

  // mute samples
  useEffect(() => {
    availableSamples.map(getSampleStatus).forEach((s) => {
      const player = playerForSample(s.sample);
      player.volume.value = s.volume;
      player.mute = s.muted;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [samplesVolume]);

  const play = useCallback(() => {
    Tone.loaded().then(() => {
      Tone.start();
      availableSamples.map((s) => playerForSample(s)).forEach((p) => p.start(0));
      Tone.Transport.start();
    });
    setPlaying(true);
  }, []);

  const stop = useCallback(() => {
    Tone.Transport.stop();
    availableSamples.map((s) => playerForSample(s)).forEach((p) => p.stop(0));
    setPlaying(false);
  }, []);

  const exportToWav = useCallback(async () => {
    const maxLengthAcrossSamples = Math.max(...availableSamples.map((s) => buffers.current!.get(s.src).duration));

    const render = Tone.Offline(({ transport }) => {
      availableSamples
        .map(getSampleStatus)
        .filter((status) => !status.muted)
        .forEach((status) => {
          const sample = status.sample;
          const player = new Tone.Player().toDestination();
          player.buffer = buffers.current!.get(sampleId(sample));
          player.volume.value = status.volume;
          player.start(0);
        });

      transport.start();
    }, maxLengthAcrossSamples);

    const audioBuffer = await render;
    const data = toWav(audioBuffer as unknown as AudioBuffer);
    const blob = new Blob([data], { type: "audio/wav" });

    // Fire file download
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "odra-postcard.wav");
    document.body.appendChild(link);
    link.click();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [samplesVolume]);

  return {
    play,
    loaded,
    empty: !availableSamples.map(getSampleStatus).some((s) => !s.muted),
    load,
    stop,
    playing,
    download: exportToWav,
  };
}
