import React from "react";
import { SwitchOption as CoreSwitchOption } from "@jelly/ui";

import darkStyle from "./SwitchOption.dark.module.scss";
import lightStyle from "./SwitchOption.light.module.scss";

export type SwitchOptionProps = React.ComponentProps<typeof CoreSwitchOption> & {
  theme?: "light" | "dark";
};

export const SwitchOption: React.FC<SwitchOptionProps> = (props) => {
  const style: Record<Required<SwitchOptionProps>["theme"], Record<string, string> | undefined> = {
    dark: darkStyle,
    light: lightStyle,
  };

  return <CoreSwitchOption {...props} style={style[props.theme ?? "light"]} />;
};

export const DarkSwitchOption: React.FC<SwitchOptionProps> = (props) => {
  return <SwitchOption {...{ ...props, theme: "dark" }} />;
};
